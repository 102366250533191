import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from '@scaleflex/i18n-it';
import { DEFAULT_APP_LANGUAGE } from '../global.constants';

const newInstance = i18n.createInstance();

newInstance
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: DEFAULT_APP_LANGUAGE,
    lng: localStorage.getItem('language') || DEFAULT_APP_LANGUAGE,
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    load: 'languageOnly',
    saveMissing: process.env.REACT_APP_SAVE_TRANSLATIONS === 'true',
    react: {
      useSuspense: true,
    },
    backend: {
      uuid: process.env.REACT_APP_I18N_GRID_UUID,
      cache: true,
      langPath: `${process.env.REACT_APP_I18N_IT_BASE_URL}/api/export/grid/{{uuid}}/langs`,
      loadPath: `${process.env.REACT_APP_I18N_IT_BASE_URL}/api/export/grid/f2/{{uuid}}`,
      savePath: `https://neo.wordplex.io/api/import/request-translations?grid_uuid={{uuid}}`,
    },
  });

export default newInstance;
