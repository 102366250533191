import { push } from "connected-react-router";
import { T } from "@shared-admin-kit/translation";
import { ROUTES } from "../../routes";
import { TAB_SLUGS } from "../../routes/project/settings/settings.constants";
import { activateGroupAction } from "../../actions/sidebar.actions";
import { SIDEBAR_LIST_GROUP_IDS } from "../../components/layout/components/sidebar/components/sidebar-list/sidebar-list.constants";
import Styled from './quickstart-tour.styles';
import { PERM_ID } from "../../permissions.constants";

export const TOURS_IDS = {
  WATCH_ONBOARDING_VIDEO: 'WATCH_ONBOARDING_VIDEO',
  REVIEW_IMPLEMENTATION: 'REVIEW_IMPLEMENTATION',
  DEFAULT_IMAGE_AND_WATERMARK: 'DEFAULT_IMAGE_AND_WATERMARK',
  SECURE_TOKEN: 'SECURE_TOKEN',
};

export const TOURS = [
  {
    id: TOURS_IDS.WATCH_ONBOARDING_VIDEO,
    finishedByDefault: false,
    label: 'Watch the onboarding video',
    i18nKey: 'TOUR.LABEL.WATCH_ONBOARDING_VIDEO',
    sessionDataPropName: 'isTourWatchOnboardingVideoFinished',
    steps: [
      {
        isModal: true,
        content: (
          <Styled.ModalBody>
            <iframe
              width="100%"
              height="100%"
              src={`https://player.vimeo.com/video/724912423?h=311d5870a1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              title="Cloudimage Onboarding - Get Optimized Images in Minutes, Deliver them in Milliseconds"
            />
          </Styled.ModalBody>
        )
      },
    ]
  },
  {
    id: TOURS_IDS.REVIEW_IMPLEMENTATION,
    finishedByDefault: false,
    label: 'Review implementation options',
    i18nKey: 'TOUR.LABEL.REVIEW_IMPLEMENTATION',
    sessionDataPropName: 'isTourReviewImplementationFinished',
    steps: [
      {
        selector: '#token-container',
        content: <T i18nKey="QUICKSTART_TOUR.TOKEN_CONTAINER" defaultValue="This is your Cloudimage token, it will be used to generate image transformation on-the-fly" />,
        action: ({ dispatch }) => {
          dispatch(push(`${ROUTES.HOME}?expand-option=both`));
        },
        popperProps: { placement: 'right' }
      },

      {
        selector: '#implementation-option1-api',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.IMPLEMENTATION.CLOUDIMAGE_API" defaultValue="Use the Cloudimage API" />,
        action: ({ dispatch }) => {
          dispatch(push(`${ROUTES.HOME}?expand-option=both`));
        },
        popperProps: { placement: 'top' }
      },

      {
        selector: '#implementation-option1-plugins',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.IMPLEMENTATION.PLUGINS" defaultValue="Use the Cloudimage Responsive Plugin" />,
        action: ({ dispatch }) => {
          dispatch(push(`${ROUTES.HOME}?expand-option=both`));
        },
        popperProps: { placement: 'top' }
      },

      {
        selector: '#implementation-option2-cms',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.IMPLEMENTATION.CMS" defaultValue="Implement Cloudimage directly in your CMS via a plugin or tutorial" />,
        action: ({ dispatch }) => {
          dispatch(push(`${ROUTES.HOME}?expand-option=both`));
        },
        popperProps: { placement: 'top' }
      },
    ]
  },
  {
    id: TOURS_IDS.DEFAULT_IMAGE_AND_WATERMARK,
    label: 'Set default image and watermark',
    i18nKey: 'TOUR.LABEL.DEFAULT_IMAGE_AND_WATERMARK',
    sessionDataPropName: 'isTourDefaultImageAndWatermarkFinished',
    permsIds: [PERM_ID.VIEW_PROJECT_SETTINGS],
    steps: [
      {
        selector: '.image-settings-sidebar-item',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.IMAGE_SETTINGS_SIDEBAR_ITEM" defaultValue="Go to Image Settings page" />,
        // showMask: false,
        action: ({ dispatch }) => {
          dispatch(push(ROUTES.PROJECT_SETTINGS('')));
        },
        popperProps: {
          placement: 'right',
          // disablePortal: false
        }
      },
      {
        selector: '#image-settings--tab',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.IMAGE_SETTINGS_BASIC_TAB" defaultValue="Go to Settings Basic tab" />,
        // showMask: false,
        action: ({ dispatch }) => {
          dispatch(push(ROUTES.PROJECT_SETTINGS('')));
        },
        popperProps: { placement: 'top' }
      },
      {
        selector: '#default-image-container',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.DEFAULT_IMAGE_CONTAINER" defaultValue="Here you can select default image for missing assets" />,
        action: ({ dispatch }) => {
          dispatch(push(ROUTES.PROJECT_SETTINGS('')));
        },
        popperProps: { placement: 'bottom' }
      },
      {
        selector: '#save-settings-button',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.SAVE_SETTINGS_BUTTON" defaultValue="Don't forget to click save" />,
        action: ({ dispatch }) => {
          dispatch(push(ROUTES.PROJECT_SETTINGS('')));
        },
      },
      {
        selector: '#image-settings-watermark-tab',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.IMAGE_SETTINGS_WATERMARK_TAB" defaultValue="Go to Watermark tab" />,
        action: ({ dispatch }) => {
          dispatch(push(ROUTES.PROJECT_SETTINGS(TAB_SLUGS.WATERMARK)));
        },
        popperProps: { placement: 'top' }
      },
      {
        selector: '#watermark-container',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.WATERMARK_CONTAINER" defaultValue="Here you can select watermark for your assets" />,
        action: ({ dispatch }) => {
          dispatch(push(ROUTES.PROJECT_SETTINGS(TAB_SLUGS.WATERMARK)));
        },
      },
      // { Important! For some tokens this block is missing
      //   selector: '#watermark-example-container',
      //   content: 'You can see example here.',
      //   action: ({ dispatch }) => {
      //     dispatch(push(ROUTES.PROJECT_SETTINGS(TAB_SLUGS.WATERMARK)));
      //   },
      // },
    ]
  },
  {
    id: TOURS_IDS.SECURE_TOKEN,
    label: 'Secure token',
    i18nKey: 'TOUR.LABEL.SECURE_TOKEN',
    sessionDataPropName: 'isTourSecureTokenFinished',
    steps: [
      {
        selector: '#image-settings-domains-tab',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.WHITELISTED_DOMAINS" defaultValue="Go to Domains tab" />,
        // showMask: false,
        action: ({ dispatch }) => {
          dispatch(push(ROUTES.PROJECT_SETTINGS(TAB_SLUGS.DOMAINS)));
          dispatch(activateGroupAction(SIDEBAR_LIST_GROUP_IDS.HELP_SUPPORT)); // Prepare to next step
        },
        popperProps: { placement: 'top' },
        permsIds: [PERM_ID.VIEW_PROJECT_SETTINGS],
      },
      {
        selector: '#whitelisted-domains',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.WHITELISTED_DOMAINS" defaultValue="Add as many domains as you want, leave as is if you're not sure, and read the documentation at docs.cloudimage.io for more info" />,
        action: ({ dispatch }) => {
          dispatch(push(ROUTES.PROJECT_SETTINGS(TAB_SLUGS.DOMAINS)));
          dispatch(activateGroupAction(SIDEBAR_LIST_GROUP_IDS.HELP_SUPPORT)); // Prepare to next step
        },
        permsIds: [PERM_ID.VIEW_PROJECT_SETTINGS],
      },
      {
        selector: '#documentation-sidebar-item',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.DOCUMENTATION" defaultValue="You can find our documentation here" />,
        action: ({ dispatch }) => {
          dispatch(activateGroupAction(SIDEBAR_LIST_GROUP_IDS.HELP_SUPPORT))
          setTimeout(() => {
            document.querySelector('#sidebar-help-support-section')?.scrollIntoView?.({ behavior: 'smooth' })
            document.querySelector('#documentation-sidebar-item')?.scrollIntoView?.({ behavior: 'smooth' })
            dispatch(push(ROUTES.CONTACT_US('support')));
          })
        },
        popperProps: { placement: 'right' }
      },
      {
        selector: '#contact-us-support-form',
        content: <T i18nKey="QUICKSTART_TOUR.STEP.CONTACT_US" defaultValue="Please contact us if you have any question" />,
        action: ({ dispatch }) => {
          dispatch(push(ROUTES.CONTACT_US('support')));
        },
      },
    ]
  },
];
