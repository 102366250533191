import React, { useEffect, useRef, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { useAuth, useCompany } from "@shared-admin-kit/core";
import { useBilling } from "@shared-admin-kit/billing";
import { PermissionsContext } from '@shared-admin-kit/permissions';
import { matchPathWithRoutes } from '@shared-admin-kit/utils';
import { T } from '@shared-admin-kit/translation';
import { checkOnboardingFinished } from './services/onboarding.service';
import Styled from './app.styled';
import Routes, { NOT_AUTHENTICATED_ROUTES, ROUTES } from './routes';
import { updateInstancesConfigs } from "./services/api/client-georeplicated";
import { ROLES } from "./permissions.constants";
import { QuickstartTourProvider } from "./providers/quickstart-tour/quickstart-tour.provider";
import { isQAMode, isSalesMode } from "./global.constants";

export default function App() {
  const dispatch = useDispatch();
  const { token, activeProjectUuid, activeProject } = useCompany();
  const { isAuthenticated,  isEmailValid: isEmailConfirmed, hasEmailToBeValidated, role } = useAuth();
  const isMobileOrTablet = useRef(isMobile).current;
  const { updateBilling, updatePaymentMethods, updatePlans } = useBilling()

  // const { pathname } = useSelector(routerSelectors.selectRouterLocation);
  // const projects = useSelector(companySelectors.selectProjects);
  // const hasToken = useSelector(companySelectors.selectHasToken);

  // const {
  //   REACT_APP_PUBLIC_URL_REGISTER,
  //   REACT_APP_AUTH_PUBLIC_URL,
  // } = process.env;

  // const blacklistedPathNames = [
  //   ROUTES.MISSING_TOKEN,
  //   ROUTES.LOGOUT,
  //   REACT_APP_PUBLIC_URL_REGISTER,
  //   REACT_APP_AUTH_PUBLIC_URL,
  // ];

  const permissionsContextValue = useMemo(() => ({
    roleId: role,
    ROLES,
    defaultRedirectPath: ROUTES.HOME,
  }), [role]);

  /**
   * onboarding check
   */
  useEffect(() => {
    if (isAuthenticated && !isEmailConfirmed) {
      return dispatch(replace(ROUTES.ONBOARDING));
    }
  }, [
    dispatch,
    isAuthenticated,
    isEmailConfirmed,
  ]);

  // /**
  //  * missing-token check
  //  */
  // useEffect(() => {
  //   if (hasToken !== false || !isAuthenticated || !isEmailConfirmed) {
  //     if (pathname === ROUTES.MISSING_TOKEN) {
  //       dispatch(replace(ROUTES.HOME));
  //     }
  //     return;
  //   }
  //
  //   if (!blacklistedPathNames.includes(pathname)) {
  //     dispatch(replace(ROUTES.MISSING_TOKEN));
  //   }
  // }, [
  //   dispatch,
  //   isAuthenticated,
  //   pathname,
  //   projects,
  //   blacklistedPathNames,
  //   isEmailConfirmed,
  //   hasToken,
  // ]);

  /**
   * For redirect we use "replace" instead of "push" to replace history state and avoid browser back click.
   * With "push" we have case:
   *  User register and go to homepage '/'. He has required redirected to '/onboarding' page.
   *  User click browser "back" btn and he see homepage.
   */
  useEffect(() => {
    function redirect() {
      function emailValidationRedirect() {
        if (hasEmailToBeValidated) {
          dispatch(replace(ROUTES.EMAIL_NOT_VALID));
        }
        else {
          // TODO: We can uncomment when backend and shared kit will be ready
          // When we uncomment this part user will be blocked untill terms will be not accepted
          // checkTermsOfUseAccepted()
          //   .then(isTermsAccepted => {
          //     if (!isTermsAccepted) {
          //       return dispatch(replace(ROUTES.TERMS_OF_USE));
          //     }
          //   });
        }
      }

      // For this project Ondoarding is required, so we need to check is onboarding finished and if not redirect to onboarding page
      checkOnboardingFinished()
        .then(isFinished => {
          if (hasEmailToBeValidated) {
            return dispatch(replace(ROUTES.EMAIL_NOT_VALID));
          }

          if (!isFinished) {
            return dispatch(replace(ROUTES.ONBOARDING));
          }

          // emailValidationRedirect();
        })
        .catch(() => emailValidationRedirect());
    }

    if (isAuthenticated) {
      const notAuthRoutes = Object.keys(NOT_AUTHENTICATED_ROUTES)
        .map(routeKey => NOT_AUTHENTICATED_ROUTES[routeKey]);
      const isCurrentUrlForAuthUser = matchPathWithRoutes(notAuthRoutes);

      /**
       * Avoid redirect for not_auth_routes.
       *
       * Problem case:
       *  New user login, he doesn't finish onboarding yet so he has redirect to onboarding page.
       *  Then he want to retrieve-password of old account (or open autoconnect|invite-register|contact-us|... page).
       *  Finally when we open URL https://..../retrieve-password/{token} he will be redirected to onboarding
       *
       * Solution:
       *  Use isCurrentUrlForAuthUser to avoid redirect for not auth pages.
       */
      if (!isCurrentUrlForAuthUser && !isMobileOrTablet) {
        redirect();
      }
    }
  }, [dispatch, isAuthenticated, hasEmailToBeValidated, isMobileOrTablet]);

  useEffect(() => { // Update API client configs on each token and sassKey change
    sessionStorage.setItem('token', token);
    updateInstancesConfigs();
  }, [token, activeProjectUuid]);

  useEffect(() => { // Load billing data to properly show <NoPaymentDetailsAlert />
    if (activeProjectUuid) {
      updateBilling();
      updatePaymentMethods();
      updatePlans();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProjectUuid]);

  if (isMobileOrTablet) {
    return (
      <Styled.DesktopOnlyAlert/>
    );
  }

  return (
    <PermissionsContext.Provider value={permissionsContextValue}>
      <QuickstartTourProvider>
        <Routes activeProject={activeProject} />
        {(isQAMode || isSalesMode) && (
          <Styled.QAModeLabel>
            {isQAMode ? <T i18nKey="QA_MODE" defaultValue="QA Mode" /> : <T i18nKey="SALES_MODE" defaultValue="Sales Mode" />}
          </Styled.QAModeLabel>
        )}
      </QuickstartTourProvider>
    </PermissionsContext.Provider>
  );
}
