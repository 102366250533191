import { T } from '@shared-admin-kit/translation';
import { Typography, Box } from '@material-ui/core';
//import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
//import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { ReactComponent as CheckCircleOutlineIcon } from '../../assets/check-circle-outline-icon.svg';
import { ReactComponent as RadioUncheckedIcon } from '../../assets/radio-icon.svg';
import OnbloardingButton from '../../components/sfx-button';
import { BorderLinearProgress } from './border-linear-progress';
import Styled from './quickstart-tour.styles';

function QuickstartBlock({ isQuickstartOpen, setIsQuickstartOpen, finishedProgress, tours, openTour, animation }) {
  return (
    <Styled.QuickstartBlockContainer>
      {isQuickstartOpen
        ? (
        <Styled.QuickstartBlockPaper className={animation ? 'animation' : ''}>
          <Styled.CloseButton onClick={() => setIsQuickstartOpen(false)} />

          <Typography style={{ fontWeight: 500, padding: '8px 24px 12px', fontSize: 22, lineHeight: '28px' }}>
            <T i18nKey="QUICKSTART_CHECKLIST.TITLE" defaultValue="Get started Cloudimage" />
          </Typography>

          <Box px={3} mt={1} mb={2}>
            <BorderLinearProgress variant="determinate" value={finishedProgress} />
          </Box>

          {tours.map(({ id, label, i18nKey, finished }) => (
            <Styled.MenuItem key={id} onClick={() => openTour(id)}>
              <Styled.ListItemIcon>
                {finished ? <CheckCircleOutlineIcon /> : <RadioUncheckedIcon style={{ width: 20, height: 20 }} />}
              </Styled.ListItemIcon>

              <Typography variant="inherit" color={finished ? 'textSecondary' : 'textPrimary'} style={{ textDecoration: finished ? 'line-through' : 'none' }}>
                <T i18nKey={i18nKey} defaultValue={label} />
              </Typography>
            </Styled.MenuItem>
          ))}
        </Styled.QuickstartBlockPaper>
        )
        : (
          <OnbloardingButton
            variant="contained"
            color="primary"
            onClick={() => setIsQuickstartOpen(true)}
            //startIcon="🚀"
          >
            <T i18nKey="QUICKSTART_CHECKLIST" defaultValue="Quickstart Checklist" />
          </OnbloardingButton>
        )}
    </Styled.QuickstartBlockContainer>
  );
}

export default QuickstartBlock;
