import {
  defineSettingConfig,
  INTERNAL_SETTINGS_CONFIG,
  SETTINGS,
} from "../routes/project/settings/settings.config";
import { getConfig } from "../routes/project/settings/settings.module";
import { ObjectHelper } from "./object-helper";

class ProjectSettingsMapper {
  /**
   * @typedef PROJECT_SETTING_CONFIG
   * @type  {Object}
   * @property {string[]} paths
   * @property {any} defaultValue
   * @property {string[]} root
   */

  /**
   * @param {string} settingId
   * @return {PROJECT_SETTING_CONFIG}
   */
  static generateSettingConfig = (settingId) => {
    const {
      selectorsConfig: externalConfig,
    } = getConfig();

    const _settingConfig = (externalConfig && externalConfig[settingId]) ||
      INTERNAL_SETTINGS_CONFIG[settingId];

    const _getRoot = (settingId) => {
      let root;

      if (externalConfig && externalConfig[settingId] &&
        externalConfig[settingId].root) {
        root = externalConfig[settingId].root;
      }
      else if (externalConfig && externalConfig[SETTINGS.ALL] &&
        externalConfig[SETTINGS.ALL].root) {
        root = externalConfig[SETTINGS.ALL].root;
      }
      else if (SETTINGS[settingId] && SETTINGS[settingId].root) {
        root = SETTINGS[settingId].root;
      }
      else {
        root = SETTINGS.ALL.root;
      }

      return root;
    };
    const _root = _getRoot(settingId);

    const _generatePaths = (root, paths) => {
      return Array.isArray(root)
        ? [...root, ...paths]
        : paths;
    };

    const _getDefaultValue = () => {
      let _defaultValue;
      if (externalConfig && externalConfig[settingId] &&
        externalConfig[settingId].defaultValue !== undefined) {
        _defaultValue = externalConfig[settingId].defaultValue;
      }
      else {
        _defaultValue = INTERNAL_SETTINGS_CONFIG[settingId].defaultValue;
      }
      return _defaultValue;
    };

    return defineSettingConfig({
      paths: _generatePaths(_root, _settingConfig.paths),
      defaultValue: _getDefaultValue(),
      root: _root,
    });
  };

  /**
   * @param {object} object
   * @param {string} settingId
   * @returns {*} requested value
   */
  static get(object, settingId) {
    const config = this.generateSettingConfig(settingId);
    getConfig();
    return ObjectHelper.get(object, config.paths, config.defaultValue);
  }

  /**
   * @param {object} object
   * @param {string} settingId
   * @param {any} value
   * @returns {*} modified root object
   */
  static set(object, settingId, value) {
    const config = this.generateSettingConfig(settingId);

    return ObjectHelper.set(object, config.paths, value);
  }
}

export {
  ProjectSettingsMapper,
};
