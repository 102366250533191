import { ProjectSettingsMapper } from "../../utils";
import { NAMESPACE as STATE_KEY } from './project.actions';
import { SETTINGS } from "./settings/settings.config";

export const selectProjectData = (state) =>
  state[STATE_KEY]
  &&
  state[STATE_KEY].data;

export const selectIsSavingProjectData = (state) =>
  state[STATE_KEY]
  &&
  state[STATE_KEY].isSavingProjectData;

export const selectProjectActiveTabSlug = (state) =>
  state[STATE_KEY]
  &&
  state[STATE_KEY].activeTabSlug;

export const selectIsLoadingProjectData = (state) =>
  state[STATE_KEY]
  &&
  state[STATE_KEY].isLoading;

export const selectProjectAliasesKeys = (state) =>
  ProjectSettingsMapper.get(selectProjectData(state), SETTINGS.ALIASES)
    .map(a => a.key);
