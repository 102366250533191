import moment from "moment";
import getFormattedDateTime from './get-formatted-date-time';

const { REACT_APP_DATE_TIME_FORMAT } = process.env;

class DateHelper {
  /**
   * @param {string | Moment} date
   * @returns {Moment}
   */
  static utcToLocal(date) {
    const time = moment.utc(date);
    return moment(time).local();
  }

  /**
   * @param {string | Moment} date
   * @param {string} format
   * @returns {string}
   */
  static formatDate(date, format) {
    return getFormattedDateTime(date, format);
  }

  /**
   * @returns {string}
   */
  static get APP_DATE_TIME_FORMAT() {
    return REACT_APP_DATE_TIME_FORMAT || 'DD-MM-YYYY HH:mm';
  }
}

export {
  DateHelper,
};
