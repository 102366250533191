class NumberHelper {
  /**
   * @param {any} value
   * @param {number} defaultValue
   * @returns {number} result can be any number or the default value if parsing fails
   */
  static parseNumber(value, defaultValue = 0) {
    let _value = value;

    if (typeof _value !== "number") {
      _value = parseFloat(_value);

      if (Number.isNaN(_value)) {
        _value = defaultValue;
      }
    }

    return _value;
  };

  /**
   * @param {any} value
   * @param {number} defaultValue
   * @returns {number} result can be integer or the default value if parsing fails
   */
  static parseInteger(value, defaultValue = 0) {
    let _value = this.parseNumber(value, defaultValue);

    if (!Number.isInteger(_value)) {
      _value = parseInt(_value, 10);
    }

    return _value;
  };
}

export {
  NumberHelper,
};
