import styled from 'styled-components';
import { ReactComponent as CopyIcon } from "../../assets/copy-outline-icon.svg";

export const StyledIcon = styled(CopyIcon)`
  opacity: 0.8;
  color: ${({ theme }) => theme.palette.primary.main};
  width: 18px;
  height: 18px;

  path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }

  &&:hover {
    opacity: 1;
  }
`;