import { T } from '@shared-admin-kit/translation';
import { DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import SfxButton from '../../components/sfx-button';
import Styled from './quickstart-tour.styles';

function CongratsModal(props) {
  return (
    <Styled.CongratsModalDialog {...props}>
      <Styled.CloseButton onClick={props.onClose} />
      <DialogTitle>
        <T i18nKey="QUICKSTART_TOUR.CONGRATS_MODAL.TITLE" defaultValue="Great Job" />
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          <T i18nKey="QUICKSTART_TOUR.CONGRATS_MODAL.DESC" defaultValue="You're all set to start using the power of Cloudimage to accelerate your media rocket fast" />
        </Typography>
      </DialogContent>

      <DialogActions>
        <SfxButton variant="contained" color="primary" size="small" onClick={props.onClose}><T>Okay</T></SfxButton>
      </DialogActions>
    </Styled.CongratsModalDialog>
  );
}

export default CongratsModal;
