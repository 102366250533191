import { selectAllAliasesKeys } from "../storage/storage.selectors";
import { SETTINGS } from './settings.config';

// let's do config typedefs in config file for convenience
/**
 * @typedef ProjectSettingsConfig
 * @type Object
 * @property {object} saveProjectDataRequestQueryParams
 * @property {Object<string, ProjectSettingsSelectorsConfigProp>} selectorsConfig
 * @property {function(state:object):any} aliasesKeysSelector
 * @property {boolean} [showSaveSettingsButton]
 * @property {function} [saveSettingsAction]
 * @property {boolean} forceEnableCNAMESEdit
 * @property {object} [tabContentContainerStyles]
 * @property {string[]} [enabledSettings]
 * @property {string[]} [disabledSettings]
 */

/**@type {ProjectSettingsConfig}*/
let config = {
  saveProjectDataRequestQueryParams: null,
  selectorsConfig: null,
  saveSettingsAction: null,
  forceEnableCNAMESEdit: false,
  tabContentContainerStyles: {},

  aliasesKeysSelector: selectAllAliasesKeys,
  showSaveSettingsButton: true,
  enabledSettings: [
    SETTINGS.ALL,
  ],
  disabledSettings: [
    SETTINGS.WEBP.CONVERT,
  ],
};

function setConfig(_config) {
  config = {
    ...config,
    ...(_config || {}),
  };

  return config;
}

function getConfig() {
  return config;
}

export default {
  setConfig,
};

export {
  SETTINGS,
  getConfig,
};
