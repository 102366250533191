import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const CopyRightsTypography = styled(
  props => <Typography component="div" {...props} />
)(({ theme }) => ({
  flexShrink: 0,
  textAlign: 'center',
  padding: theme.spacing(1),
  fontSize: 11.5,
}));
