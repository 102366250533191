import React, { useMemo } from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "@shared-admin-kit/translation";
import Loader from "../loader";
import StyledTextField from "../styled-text-field";
import Styled from "./language-dropdown.styled";
import { DEFAULT_APP_LANGUAGE } from '../../global.constants';

// Show only languages with most of translations T5231
const MIN_TRANSLATIONS_PERCENTAGE = 75;

function LanguageDropdown(props) {
  const {
    t,
    languages,
    activeLanguage,
    isLoading: isLanguagesLoading,
    changeLanguage,
  } = useTranslation();
  const filteredLanguages = useMemo(() => {
    const enLangData = (languages || []).find(lang => lang.code === DEFAULT_APP_LANGUAGE)
    const enTranslationsCount = enLangData?.count_translations || 0

    if (enLangData && enTranslationsCount > 0) {
      return languages.filter(lang => ((lang?.count_translations || 0) * 100 / enTranslationsCount) >= MIN_TRANSLATIONS_PERCENTAGE)
    }

    return languages
  }, [languages]);

  function handleLanguageChange({ target: { value: newLanguageCode } }) {
    if (activeLanguage && newLanguageCode === activeLanguage) return;
    changeLanguage(newLanguageCode)
  }

  if (isLanguagesLoading) {
    return <Loader/>;
  }

  if (!(filteredLanguages || []).length) {
    return null;
  }

  return (
    <Styled.StyledFormControl>
      <StyledTextField
        value={activeLanguage || ''}
        onChange={handleLanguageChange}
        placeholder={t('LANGUAGE_PLACEHOLDER', 'Language')}
        select
        {...props}
      >
        {(filteredLanguages || []).map(lang => (
          <MenuItem key={lang.code} value={lang.code}>{lang.label_short}</MenuItem>
        ))}
      </StyledTextField>
    </Styled.StyledFormControl>
  );
}

export default LanguageDropdown;
