import styled from 'styled-components';
import FormControl from "@material-ui/core/FormControl";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

const Styled = {
  StyledFormControl,
};

export default Styled;
