const textStyles = {
  label: {
    xxl: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 44,
      lineHeight: '58px',
    },

    xl: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 40,
      lineHeight: '52px',
    },

    xs: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '18px',
    },

    lgEmphasis: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '30px',
    },

    mdEmphasis: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '24px',
    },

    md: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '24px',
    },

    smUp: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },

    sm: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '18px',
    },

    lg: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '30px',
    },
  },

  text: {
    xl: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 24,
      lineHeight: '30px',
    },

    lgEmphasis: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
    },

    lg: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '24px',
    },

    mdEmphasis: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
    },

    md: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '20px',
    },

    sm: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
      lineHeight: '18px',
    },
  },

  btn: {
    md: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
    },

    sm: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
    },
  },
};

const palette = {
  text: {
    black: '#000028',
    primary: '#2E3B49', // linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #2E3B49;
    secondary: '#5E6872',
    placeholder: '#929292',
  },

  primary: {
    hover: '#25C792',
    stateless: '#2ED7A0',
  },

  secondary: {
    blue: '#072DC5',
    blueLight: '#ECF3FF',
  },

  link: {
    secondary: '#FFFFFF',
    stateless: '#7D7E83',
    primary: '#21A078',
  },

  br: {
    primary: '#C9D3DE',
  },

  ic: {
    primary: '#959DA8',
  },

  extra: {
    red: '#D92121',
    pink: '#DB5071',
    aqua: '#0FBABA',
    green: '#0AAB7B',
    denim: '#3C28B7',
    purple: '#5C2FDC',
    skyBlue: '#376AD6',
    orange: '#D45C20',
  },

  bg: {
    primary: '#F1F8FF',
    secondary: '#F8FAFB',
    white: '#FFFFFF',
    violet: '#A1B3FC',
    skyBlue: '#E5F1FF',
    orange: '#FFEFE5',
  },
};

export const sfx = {
  textStyles,
  palette,
};
