const NAMESPACE = 'coupons';
export const COUPONS_SET = `${NAMESPACE}/SET_COUPONS`;
export const COUPONS_SET_LOADER = `${NAMESPACE}/SET_LOADER`;
export const COUPONS_SET_REDEEMING = `${NAMESPACE}/SET_REDEEMER`;

const initialState = {
  coupons: [],
  isLoading: false,
  isRedeeming: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COUPONS_SET:
      return { ...state, coupons: action.coupons };
    case COUPONS_SET_LOADER:
      return { ...state, isLoading: action.isLoading };
    case COUPONS_SET_REDEEMING:
      return { ...state, isRedeeming: action.isRedeeming };
    default:
      return state;
  }
};
