import React from 'react';
import styled from 'styled-components';
import Drawer from "@material-ui/core/Drawer";

export const StyledLayoutSidebarContainer = styled(
  ({ width, background, ...rest }) => <Drawer variant="permanent" {...rest} />,
)
(({ width, background }) => ({
  position: 'relative',
  display: 'flex',
  flexShrink: 0,
  overflowY: 'auto',
  width: width,
  whiteSpace: 'nowrap',
  background: background || 'white',
}));
