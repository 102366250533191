import {
  getUserMetaRequest,
  setUserMetaRequest,
} from '@shared-admin-kit/core/lib/modules/auth/auth.service';


/**
 * This part copied from old shared-admin-kit
 * TODO: need to figure out how to store onboarding_finished, in user meta or in session data /session/${sessionUuid}/data? (in the past we used user meta)
 */

const ONBOARDING_FINISHED_PARAM_NAME = 'onboarding_finished';

/**
 * @returns {Promise<{data: object}>}
 */
export const checkOnboardingFinishedRequest = () =>
  getUserMetaRequest(ONBOARDING_FINISHED_PARAM_NAME);

/**
 * @param {boolean} [value=true]
 * @returns {Promise}
 */
export const setOnboardingFinishedRequest = (value = true) =>
  setUserMetaRequest(ONBOARDING_FINISHED_PARAM_NAME, value);

/**
 * @returns {Promise<boolean>}
 */
export const checkOnboardingFinished = () =>
new Promise(resolve => {
  checkOnboardingFinishedRequest()
    .then(response => resolve(((response || {}).data || {}).meta || false))
    .catch(() => resolve(false));
});
