import { matchPath } from 'react-router';

/**
 * @param {SidebarListGroups} groups
 * @param {string} [pathname=window.location.pathname]
 * @returns {(string|null)} - Group ID or null if not matched.
 */
function findActiveGroupIdByPath(groups, options = {}, pathname = window.location.pathname) {
  for (let key in groups) {
    const group = groups[key] || {};
    const routes = (group.items || []).map(item => item.route).filter(Boolean)

    if (routes.length === 0) {
      continue;
    }

    for (let route of routes) {
      const opts = {
        // default options
        exact: true,

        // custom method options override
        ...(options || {}),

        // custom route options override
        ...(typeof route === 'object' ? route : { path: route })
      };

      const isMatched = matchPath(pathname, opts);

      if (isMatched) {
        return group.id;
      }
    }
  }

  return null;
}

export {
  findActiveGroupIdByPath,
}