import React from "react";
import { useGlobalNotifications } from "@shared-admin-kit/core";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { ROUTES } from "../../../../routes";
import NoPaymentDetailsAlert from "../../../no-payment-details-alert";
import ResizeActiveAlert from "./components/resize-active-alert";

function Messages() {
  const { notifications, removeNotification } = useGlobalNotifications();

  return (
    <Box flexShrink={0}>
      {/* <NotMobileOptimisedAlert /> */}

      {(notifications || []).length > 0 && notifications.map(({ uuid, message }) => (
        <Alert key={uuid} severity="info" onClose={() => removeNotification(uuid)}>{message}</Alert>
      ))}

      <NoPaymentDetailsAlert redirectPayments={ROUTES.ACCOUNT_BILLING("")}/>

      <ResizeActiveAlert/>
    </Box>
  );
}

export default Messages;
