import React, { useState, useEffect, useRef } from 'react';
import { useCompany, useAuth } from "@shared-admin-kit/core";
import { useTranslation } from "@shared-admin-kit/translation";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Save from '@material-ui/icons/Save';
import Cancel from '@material-ui/icons/Cancel';
import Loader from "../../../../components/loader";
import StyledTextField from "../../../../components/styled-text-field";
import { StyledEditIconButton } from '../../company-autocomplete.styled';

function SingleMode({
  renderInputProps = {},
  enqueueSnackbar,
  isEditMode,
  changeEditMode,
}) {
  const { t } = useTranslation();
  const { activeCompany, refreshCurrentCompany, saveCompanySettings } = useCompany();
  const { updateSession } = useAuth();
  const activeCompanyName = activeCompany?.company?.name || '';
  const [companyName, setCompanyName] = useState(activeCompanyName);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const isNameChanged = companyName !== activeCompanyName;

  useEffect(() => {
    setCompanyName(activeCompanyName || '');
  }, [activeCompanyName]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  function handleChange({ target: { value } }) {
    setCompanyName(value);
  }

  function handleKeyDown({ keyCode }) {
    if (keyCode === 13) { // enter
      saveCompanyName();
    }
    else if (keyCode === 27) { // esc
      handleEditCancel();
    }
  }

  function handleEditCancel() {
    setCompanyName(activeCompanyName);
    changeEditMode(false);
  }

  function saveCompanyName() {
    if (!companyName || !isNameChanged) { // name is not changed
      return handleEditCancel();
    }

    setIsLoading(true);
    saveCompanySettings({ name: companyName })
      .then(() => {
        enqueueSnackbar(t('COMPANY_NAME_SAVED', 'Company name changed!'),
          { variant: 'success' });

        refreshCurrentCompany();
        updateSession(); // to update companies
        changeEditMode(false);
      })
      .catch(() => {
        enqueueSnackbar(t('SAVING_ERROR', `Saving error!`),
          { variant: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <StyledTextField
      color="#FFF"
      fullWidth
      {...renderInputProps}
      value={companyName}
      required
      readOnly={!isEditMode}
      variant="standard"
      placeholder={t("COMPANY_NAME", "Company name")}
      error={!companyName}
      onChange={handleChange}
      onKeyDown={isEditMode ? handleKeyDown : undefined}
      inputProps={{ readOnly: !isEditMode }}
      inputRef={inputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isEditMode
              ? (
                isLoading
                  ? <Loader/>
                  : (
                    <>
                      <IconButton
                        size="small"
                        disabled={!companyName || !isNameChanged}
                        onClick={() => saveCompanyName()}
                      >
                        <Save
                          fontSize="small" color={!companyName || !isNameChanged
                          ? 'disabled'
                          : 'inherit'}
                        />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={() => handleEditCancel()}
                      >
                        <Cancel fontSize="small"/>
                      </IconButton>
                    </>
                  )
              )

              : (
                <StyledEditIconButton
                  onClick={() => changeEditMode(true)}
                />
              )
            }
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SingleMode;
