import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import styled from 'styled-components';

const StyledCopyTokenBox = styled(Box)({
  '.MuiSvgIcon-root': {
    width: 16,
    height: 16,
    marginTop: -1,
  },
});

const StyledMenuItem = styled(Box)`
  display: flex;
  flex-direction: row;
  /*justify-content: flex-start;
  align-items: flex-start;*/
  align-items: center;

  .token {
    font-weight: bold;
    color: #5E5F62;
  }
`;

const StyledDropDownBox = styled(Box)`
  width: 100%;
  position: relative;
`;

const StyledGridCell = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTokenGridCell = styled(StyledGridCell)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .token,
  .desc{
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .token {
    font-weight: bold;
  }
`;

const StyledDropDownMenuPaper = styled(Paper)`
  position: fixed;
  z-index: 9;
  border-radius: 3px;

  .MuiAutocomplete-root {
    padding: 16px;
    border-bottom: 1px solid ${props => props.theme.sfx.palette.br.primary};
  }

  .MuiAutocomplete-listbox {
    padding: 0;
  }

  .MuiAutocomplete-popperDisablePortal {
    position: relative;
    min-width: 100%;

    .MuiPaper-root {
      box-shadow: none;
    }
  }
`;

export {
  StyledMenuItem,
  StyledCopyTokenBox,
  StyledDropDownBox,
  StyledDropDownMenuPaper,
  StyledGridCell,
  StyledTokenGridCell,
};
