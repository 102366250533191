import { useEffect, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useCompany } from '@shared-admin-kit/core';
import { useTranslation } from '@shared-admin-kit/translation';
import { useBilling } from '@shared-admin-kit/billing';
import { showProcessPendingPlanMsg } from '../utils';
import { PLANS } from '../routes/plans/plans.constants';


function usePlans({ redirectToBilling, redirectToCards, redirectToDebit } = {}) {
  const { t } = useTranslation();
  const { refreshCurrentProject } = useCompany();
  const {
    BILLING_CYCLE, plans: sourcePlans, savingPlanName, isPlansLoading, selectPlan, PENDING_PLAN_STATUS,
    selectedPlanName, selectedPlan, selectedPlanCurrency, CURRENCY, selectedPlanPrice, billing,
  } = useBilling({ fetchPlans: true  });
  const selectedBillingCycle = selectedPlan?.billing_cycle
  const { enqueueSnackbar } = useSnackbar();
  const showMessage = (message, opts = { variant: 'success' }) => enqueueSnackbar(message, opts);
  const [isIniting, setIsIniting] = useState(true);
  const plans = useMemo(() => (sourcePlans || []).map(plan => ({
    is_selectable: plan.title.toLowerCase() !== PLANS.ENTERPRISE.toLowerCase(),
    ...plan
  })), [sourcePlans]);
  const hasPlans = plans && plans.length > 0;
  const [billingCycle, setBillingCycle] = useState(selectedBillingCycle || BILLING_CYCLE.MONTHLY);
  const [currency, setCurrency] = useState(selectedPlanCurrency || billing?.currency || CURRENCY.EUR);

  useEffect(() => { // Hack! To avoid view jumping when isPlansLoading change false->true->false
    if (isIniting && isPlansLoading) { setIsIniting(false); }
  }, [isPlansLoading, isIniting]);

  useEffect(() => {
    setBillingCycle(selectedBillingCycle || BILLING_CYCLE.MONTHLY);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBillingCycle]);

  function handleSelectPlan(planName) {
    if (!planName) {
      // eslint-disable-next-line no-console
      console.warn('You want to select plan but planName is not defined!');
      return;
    }

    const isActive = selectedPlanName === planName;
    if (isActive) {
      showMessage(t('PLAN_ALREADY_SELECTED', 'Plan "{planName}" already selected!').replace('{planName}', planName));
      return;
    }

    const isSaving = savingPlanName && planName === savingPlanName;
    if (isSaving) {
      showMessage(
        t('PLAN_ACTIVATION_IN_PROCESS', 'Plan "{planName}" activation in process!').replace('{planName}', planName),
      );
      return;
    }

    selectPlan(planName, billingCycle, currency)
      .then((response) => {
        refreshCurrentProject();
        showProcessPendingPlanMsg(response?.status, planName, showMessage, t);

        if (response?.status === PENDING_PLAN_STATUS.BILLING_REDIRECT && typeof redirectToBilling === 'function') {
          redirectToBilling();
        }
        if (response?.status === PENDING_PLAN_STATUS.NO_CREDIT_CARD_PAYMENT_REDIRECT && typeof redirectToCards === 'function') {
          redirectToCards();
        }
        if (response?.status === PENDING_PLAN_STATUS.NO_DEBIT_CARD_PAYMENT_REDIRECT && typeof redirectToDebit === 'function') {
          redirectToDebit();
        }
      })
      .catch(() => showMessage(t('SAVING_ERROR', 'Saving error!'), { variant: 'error' }));
  }

  return {
    plans,
    hasPlans,
    isPlansLoading: isPlansLoading || isIniting,
    billingCycle,
    setBillingCycle,
    currency,
    setCurrency,
    selectedPlanName,
    savingPlanName,
    selectPlan: handleSelectPlan,
    selectedPlanPrice,
  };
}

export {
  usePlans,
};

// import { useMemo } from 'react';
// import { useBilling } from '@shared-admin-kit/billing';
// import { PLANS } from '../routes/plans/plans.constants';

// export function usePlans(opts = { fetch: true }) {
//   const { plans: sourcePlans, ...rest } = useBilling({ fetchPlans: opts?.fetch });
//   const plans = useMemo(() => (sourcePlans || []).map(plan => ({
//     is_selectable: plan.title.toLowerCase() !== PLANS.ENTERPRISE.toLowerCase(),
//     ...plan
//   })), [sourcePlans]);

//   return {
//     ...rest,
//     plans,
//   }
// }
