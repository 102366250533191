class StringHelper {
  /**
   * @param {string} text
   * @returns {string}
   */
  static capitalize(text) {
    if (typeof text !== "string" || !text) {return text;}

    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  /**
   * @param {string?} text
   * @returns {boolean}
   */
  static isValidJSON(text) {
    try {
      JSON.parse(text);
    }
    catch (error) {
      return false;
    }
    return true;
  };

  /**
   * @description creates string in the fallowing format: test-name-2 (without special chars)
   * @param {string} label
   * @param {string} replaceChar
   * @returns {string}
   */
  static createSlug(label, replaceChar = "_") {
    return label
      .replace(/[^A-z\s\d][\\^]?/g, " ")
      .split(" ")
      .filter(s => !!s)
      .join(replaceChar)
      .toLowerCase();
  }

  /**
   * @param {string} text
   * @param {string} [replacerText]
   * @returns {string}
   */
  static replaceAllWhitespaces(text, replacerText = "") {
    return text.replace(/\s/g, replacerText);
  }
}

export {
  StringHelper,
};