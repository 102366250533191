import moment from 'moment';
import getDateTimeFormat from './get-date-time-format';

export default (date, format) => {

  try {
    if (!date) throw new Error('Date is not defined');

    return moment(date).format(format || getDateTimeFormat());
  }
  catch (error) {
    console.warn(error.message);
  }

  return '';
};
