import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectActiveProjectResponseDefaultDomain, selectActiveProjectResponseDomains, selectTokenHasV7InCdnPrefix
} from "../selectors/company.selectors";


function useCloudimgUrl() {
  const hasV7InCdnPrefix = useSelector(selectTokenHasV7InCdnPrefix);
  const activeProjectResponseDomains = useSelector(selectActiveProjectResponseDomains);
  const activeProjectResponseDefaultDomain = useSelector(selectActiveProjectResponseDefaultDomain);
  const domain = useMemo(() => {
    const newDomain = activeProjectResponseDomains?.[0] || activeProjectResponseDefaultDomain;
    const regexp = /\/v7$/;

    if (hasV7InCdnPrefix) {
      return newDomain.replace(regexp, '');
    }

    if (!regexp.test(newDomain)) {
      return `${newDomain.replace(/\/$/, '')}/v7`
    }

    return newDomain;
  }, [activeProjectResponseDomains, activeProjectResponseDefaultDomain, hasV7InCdnPrefix]);
  const ciBaseUrl = `https://${domain}`;

  /**
   * Get URL with Cloudimage prefix
   *
   * Format:
   *    https://token.cloudimg.io/original_image_url?operations&filters
   *
   * Example:
   *    getCdnizedUrl('http://sample.li/car.jpg', { params: { width: 400, grey: 1 } })
   *    ->
   *    https://token.cloudimg.io/original_image_url?width=400&grey=1
   *
   * @param {string} url - Any image URL (with or without get params)
   * @param {object} [opts.params={}] - Key => value object with Cloudimage operations&filters properties
   * @param {boolean} [opts.removeUrlProtocol=false] - TRUE when we need to remove protocol from url. When TRUE https://token.cloudimg.io/http://sample.li/car.jpg -> https://token.cloudimg.io/sample.li/car.jpg.
   * @param {boolean} [opts.infoJsonResponse=false] - TRUE to get URL that could be used in XHR request to get image info (format, widht, height, filesize)
   * @returns {string} Return URL with Cloudimage prefix
   */
  const getCdnizedUrl = useCallback((url, opts = {}) => {
    const options = {
      params: opts?.params || {},
      removeUrlProtocol: false,
      infoJsonResponse: false,
      ...opts,
    };

    let adaptedUrl = options.removeUrlProtocol ? url.replace(new RegExp('^(http[s]?:)?//'), '') : url;

    if (options.infoJsonResponse) {
      options.params.ci_info = 'json,image_info';
    }

    // Url includes params, need encode and ci_url_encoded=1
    if (adaptedUrl.includes('?')) {
      adaptedUrl = encodeURIComponent(adaptedUrl)
      options.params.ci_url_encoded = 1;
    }

    const paramsStr = Object.keys(options.params)
      .map((paramName) => `${paramName}=${options.params[paramName]}`)
      .join('&');

    return `${ciBaseUrl}/${adaptedUrl}${paramsStr ? `?${paramsStr}` : ''}`;
  }, [ciBaseUrl]);

  return {
    hasV7InCdnPrefix,
    domain,
    ciBaseUrl,
    getCdnizedUrl
  }
}

export default useCloudimgUrl

export {
  useCloudimgUrl
}
