/* eslint-disable import/no-mutable-exports */
import axios from 'axios';
import { defaultHeaders } from "./client";

const clientGeoreplicated = createClient({
  get baseURL() { return getBaseUrl(); },
  headers: getGeoreplicatedClientHeaders(),
});
const clientInvalidation = createClient({
  get baseURL() { return getBaseUrl(); },
  headers: getInvalidationClientHeaders(),
});

function createClient(config = {}) {
  return axios.create({ ...(config || {}) });
}

function updateInstancesConfigs() {
  clientGeoreplicated.defaults.baseURL = getBaseUrl();
  clientGeoreplicated.defaults.headers = getGeoreplicatedClientHeaders();

  clientInvalidation.defaults.baseURL = getBaseUrl();
  clientInvalidation.defaults.headers = getInvalidationClientHeaders();
}

function getBaseUrl() {
  return `${process.env.REACT_APP_BASE_URL_2}/`;
  // return `${process.env.REACT_APP_BASE_URL_2}/${sessionStorage.getItem('token') || 'token'}`;
}

function getGeoreplicatedClientHeaders() {
  return {
    ...defaultHeaders,
  };
}

function getInvalidationClientHeaders() {
  return {
    ...defaultHeaders,
    'Content-Type': 'application/json;charset=UTF-8',
    // get 'X-Filerobot-Session'() { return localStorage.getItem('session_uuid'); },
    get 'X-Client-Key'() { return sessionStorage.getItem('clientKey'); },
  };
}

function getCancelToken() {
  return axios.CancelToken;
}

export {
  clientGeoreplicated,
  clientInvalidation,
  getCancelToken,
  updateInstancesConfigs,
};
