import React from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const Container = styled(
  props => <Box py={2} {...props} />,
)(({ theme }) => ({
  //backgroundColor: theme.palette.background.tokenBlock ||
  //  theme.palette.background.default,
  borderBottom: '1px solid #E0E0E0'
}));
