import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { Logo } from "../layout/components/default";
import cloudimageLogoUrl from "../../assets/cloudimage-logo.svg";

function CompanyLogo(props) {
  const {
    logoUrl = cloudimageLogoUrl, // 'https://assets.jolipage.co/v3/projects/cloudimage/images/LOGO+WITH+SCALEFLEX+ON+BLACK+BG-01.png?vh=8f9434',
    backgroundPosition,
    backgroundImage,
    backgroundRepeat,
    width,
    height
  } = props;

  return (
    <Logo
      className="company-logo"
      component={Link}
      flexShrink={0}
      mr={2}
      to={ROUTES.HOME}
      //$imageUrl={`https://scaleflex.ultrafast.io/${logoUrl}`}
      $imageUrl={logoUrl}
      $backgroundPosition={backgroundPosition}
      $backgroundImage={backgroundImage}
      $backgroundRepeat={backgroundRepeat}
      $width={width}
      $height={height}
    />
  );
}

CompanyLogo.propTypes = {
  backgroundPosition: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundRepeat: PropTypes.string,
  logoUrl: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default CompanyLogo;
