import React, { lazy } from 'react';
import { Switch, Route as RouterRoute } from 'react-router-dom';
import Layout from '../components/layout';
import { Route, WrappedRoute } from "../components/layout/components/wrapped-route";
import { PERM_ID } from '../permissions.constants';

export const AUTHENTICATED_ROUTES = {
  HOME: '/',
  USER_ACCESS: '/user/access',
  COMPANY_SETTINGS: '/company/settings',
  ACCOUNT_BILLING: (tabSlug = ':tabSlug?') => `/account/billing/${tabSlug}`,
  USER_SETTINGS: '/user/settings',
  PLANS: '/plans',
  COUPONS: '/promotion-codes',
  ONBOARDING: '/onboarding',
  LOGS: '/logs',
  STATS_DASHBOARD: '/stats/dashboard',
  STATS_DASHBOARD_DAILY: '/stats/optimization',
  STATS_DASHBOARD_DELIVERY: '/stats/delivery',
  STATS_DASHBOARD_PROMINENTS: '/stats/prominents',
  STATS_DASHBOARD_ADVANCED_ANALYTICS: '/stats/advanced-analytics',
  STATS_DASHBOARD_RUM: '/stats/rum',
  STATS_MAP: '/stats/map',
  STATS_HTTP_CODE: '/stats/http-code',
  STATS_TOP: '/stats/top',
  STATS_PERFORMANCE: '/stats/performance',
  STATS_OVERVIEW: '/stats/overview',
  PROJECT_INVALIDATION: '/project/invalidation',
  PROJECT_SETTINGS: (tabSlug = ':tabSlug?') => `/project/settings/${tabSlug}`,
  PROJECT_STORAGE: '/project/storage',
  TERMS_OF_USE: '/terms',
  ACCOUNT_AFFILIATION:'/account/affiliation',
  MISSING_TOKEN:'/missing-token',
  CONTACT_US: (tabSlug = ':tabSlug?') => `/contact-us/${tabSlug}`,
  ANALYZE: '/analyze',
  RUM_ANALYTICS: '/rum-analytics',
};

export const NOT_AUTHENTICATED_ROUTES = {
  FORBIDDEN: '/forbidden',
  NOT_FOUND: '/not-found',
  //LOGOUT: '/logout',
  AUTOCONNECT: '/autoconnect/:uuid',
  INVITE_REGISTER: '/invite-register',
  SESSION_EXPIRED: '/session/expired',
  EMAIL_NOT_VALID: '/email-not-valid',
  RETRIEVE_PASSWORD: '/retrieve-password/:token',
};

export const ROUTES = {
  ...NOT_AUTHENTICATED_ROUTES,
  ...AUTHENTICATED_ROUTES,
};

//Modules
const UserAccess = lazy(() => import('./user-access'));
const Forbidden = lazy(() => import('./forbidden'));
const NotFound = lazy(() => import('./not-found'));
//const Logout = lazy(() => import('./logout'));
const ContactUs = lazy(() => import('./contact-us'));
const CompanySettings = lazy(() => import('./company-settings'));
const UserSettings = lazy(() => import('./user-settings'));
const Autoconnect = lazy(() => import('./autoconnect'));
const InviteRegister = lazy(() => import('./invite-register'));
const Onboarding = lazy(() => import('./onboarding'));
const TermsOfUse = lazy(() => import('./terms-of-use'));
const RetrievePassword = lazy(() => import('./retrieve-password'));
const AccountBilling = lazy(() => import('./account-billing'));
const AccountAffiliation = lazy(() => import("./account-affiliation"));
const ProjectSettings = lazy(() => import('./project/settings'));
const RumAnalytics = lazy(() => import('./rum-analytics'));

//Non-Modules
const SessionExpired = lazy(() => import('./session-expired/session-expired'));
const EmailNotValid = lazy(() => import('./email-not-valid/email-not-valid'));
const Home = lazy(() => import('./home'));
const MissingToken = lazy(() => import('./missing-token'));
const Logs = lazy(() => import('./logs'));
const StatsDashboard = lazy(() => import('./stats-dashboard'));
const StatsDashboardDaily = lazy(() => import('./stats-dashboard-daily'));
const StatsDashboardDelivery = lazy(() => import('./stats-dashboard-delivery'));
const StatsDashboardProminents = lazy(() => import('./stats-dashboard-prominents'));
const StatsDashboardAdvancedAnalytics = lazy(() => import('./stats-dashboard-advanced-analytics'));
const StatsDashboardRum = lazy(() => import('./stats-dashboard-rum'));
const StatsMap = lazy(() => import('./stats-map'));
const StatsHttpCode = lazy(() => import('./stats-http-code'));
const StatsTop = lazy(() => import('./stats-top'));
const StatsPerformance = lazy(() => import('./stats-performance'));
const StatsOverview = lazy(() => import('./stats-overview'));
const ProjectInvalidation = lazy(() => import('./project/invalidation'));
const ProjectStorage = lazy(() => import('./project/storage'));
const Affiliate = lazy(() => import('./affiliate'));
const Analyze = lazy(() => import("./analyze/analyze.module"));

// Datastudio dashboard layout container styles
const newDashboardLayoutContentStyle = {
  height: '100%',
  padding: 0,

  // For safari:
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  overflow: 'hidden',
}

export default ({ activeProject, ...props }) => {
  const isRumEnabled = Boolean(activeProject?.data?.rum?.enabled);
  const isAdvAnalyticsEnabled = Boolean(activeProject?.data?.adv_analytics?.enabled);

  return (
    <Switch>
      {/* Routes without layout */}
      <Route exact path={ROUTES.ONBOARDING} component={Onboarding} extraProps={props} />
      <Route exact path={ROUTES.SESSION_EXPIRED} component={SessionExpired} extraProps={props} />
      <Route exact path={ROUTES.EMAIL_NOT_VALID} component={EmailNotValid} extraProps={props} />
      {/*<Route exact path={ROUTES.LOGOUT} component={Logout} extraProps={props} />*/}
      <Route exact path={ROUTES.AUTOCONNECT} component={Autoconnect} extraProps={props} />
      <Route exact path={ROUTES.INVITE_REGISTER} component={InviteRegister} extraProps={props} />
      <Route exact path={ROUTES.TERMS_OF_USE} component={TermsOfUse} extraProps={{...props }} />
      <Route exact path={ROUTES.RETRIEVE_PASSWORD} component={RetrievePassword} extraProps={props} />

      <RouterRoute>
        <Layout>
          <Switch>
            <WrappedRoute exact path={ROUTES.HOME} component={Home} extraProps={props} />
            <WrappedRoute exact path={ROUTES.MISSING_TOKEN} component={MissingToken} extraProps={props} />
            <WrappedRoute exact path={ROUTES.ANALYZE} component={Analyze} extraProps={props} perms={PERM_ID.VIEW_ANALYZE} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.RUM_ANALYTICS} component={RumAnalytics} extraProps={props} perms={PERM_ID.VIEW_RUM_ANALYTICS} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.LOGS} component={Logs} extraProps={props} containerMaxWidth={1400} />
            <WrappedRoute exact path={ROUTES.STATS_DASHBOARD} component={StatsDashboard} extraProps={props} containerMaxWidth={1400} layoutContentStyle={process.env.REACT_APP_NEW_DASHBOARDS === 'true' ? newDashboardLayoutContentStyle : undefined} />
            <WrappedRoute exact path={ROUTES.STATS_DASHBOARD_DAILY} component={StatsDashboardDaily} extraProps={props} containerMaxWidth={1400} layoutContentStyle={newDashboardLayoutContentStyle} />
            <WrappedRoute exact path={ROUTES.STATS_DASHBOARD_DELIVERY} component={StatsDashboardDelivery} extraProps={props} containerMaxWidth={1400} layoutContentStyle={newDashboardLayoutContentStyle} />
            <WrappedRoute exact path={ROUTES.STATS_DASHBOARD_PROMINENTS} component={StatsDashboardProminents} extraProps={props} containerMaxWidth={1400} layoutContentStyle={newDashboardLayoutContentStyle} />
            <WrappedRoute exact path={ROUTES.STATS_OVERVIEW} component={StatsOverview} extraProps={props} containerMaxWidth={1400} layoutContentStyle={newDashboardLayoutContentStyle} />
            <WrappedRoute exact path={ROUTES.STATS_DASHBOARD_ADVANCED_ANALYTICS} component={StatsDashboardAdvancedAnalytics} extraProps={{ ...props, isAdvAnalyticsEnabled }} {...(isAdvAnalyticsEnabled ? { containerMaxWidth: 1400, layoutContentStyle: newDashboardLayoutContentStyle } : {})} />
            <WrappedRoute exact path={ROUTES.STATS_DASHBOARD_RUM} component={StatsDashboardRum} extraProps={{ ...props, isRumEnabled }} {...(isRumEnabled ? { containerMaxWidth: 1400, layoutContentStyle: newDashboardLayoutContentStyle } : {})} />
            <WrappedRoute exact path={ROUTES.STATS_MAP} component={StatsMap} extraProps={props} containerMaxWidth={1400} />
            <WrappedRoute exact path={ROUTES.STATS_HTTP_CODE} component={StatsHttpCode} extraProps={props} containerMaxWidth={1400} />
            <WrappedRoute exact path={ROUTES.STATS_TOP} component={StatsTop} extraProps={props} containerMaxWidth={1400} />
            <WrappedRoute exact path={ROUTES.STATS_PERFORMANCE} component={StatsPerformance} extraProps={props} containerMaxWidth={1400} />
            <WrappedRoute exact path={ROUTES.USER_ACCESS} component={UserAccess} extraProps={props} perms={PERM_ID.VIEW_USER_ACCESS} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.FORBIDDEN} component={Forbidden} extraProps={props} />
            <WrappedRoute exact path={ROUTES.CONTACT_US()} component={ContactUs} extraProps={{ ...props, createRoutePath: ROUTES.CONTACT_US, visibleTabs:true}} />
            <WrappedRoute exact path={ROUTES.COMPANY_SETTINGS} component={CompanySettings} extraProps={props} perms={PERM_ID.VIEW_COMPANY_SETTINGS} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.USER_SETTINGS} component={UserSettings} extraProps={props} />
            <WrappedRoute exact path={ROUTES.PLANS} component={Affiliate} extraProps={props} perms={PERM_ID.VIEW_PLANS} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.COUPONS} component={Affiliate} extraProps={props} perms={PERM_ID.VIEW_COUPONS} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.PROJECT_INVALIDATION} component={ProjectInvalidation} extraProps={props} perms={PERM_ID.VIEW_PROJECT_INVALIDATION} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.PROJECT_SETTINGS()} component={ProjectSettings} extraProps={props} perms={PERM_ID.VIEW_PROJECT_SETTINGS} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.PROJECT_STORAGE} component={ProjectStorage} extraProps={props} perms={PERM_ID.VIEW_PROJECT_STORAGE} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.ACCOUNT_BILLING()} component={AccountBilling} extraProps={{ ...props, createRoutePath: ROUTES.ACCOUNT_BILLING }} perms={PERM_ID.VIEW_ACCOUNT_BILLING} noPermsRedirect={ROUTES.HOME} />
            <WrappedRoute exact path={ROUTES.ACCOUNT_AFFILIATION} component={AccountAffiliation} extraProps={{ ...props, createRoutePath: ROUTES.ACCOUNT_AFFILIATION }} perms={PERM_ID.VIEW_ACCOUNT_BILLING} noPermsRedirect={ROUTES.HOME} />

            <WrappedRoute component={NotFound} />
          </Switch>
        </Layout>
      </RouterRoute>
    </Switch>
  )
}
