import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AUTHENTICATED_ROUTES } from '../../routes';
import { Button } from './redirect-link.styled';

const isAuthenticatedRoutePath = (path) => Object.keys(AUTHENTICATED_ROUTES)
  .map(key => AUTHENTICATED_ROUTES[key])
  .includes(path);

export default forwardRef(function RedirectLink(props, ref) {
  const { component: Component = Button, ...rest } = props;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  if (rest.to && isAuthenticatedRoutePath(rest.to) && !isAuthenticated) {
    return null;
  }

  return (
    <Component
      ref={ref}
      exact
      activeClassName="active"
      {...rest}
      component={NavLink}
    />
  )
})
