import { Translate } from "@shared-admin-kit/translation";
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

function Forbidden() {
  return (
    <Container component={Box} maxWidth="md" textAlign="center">
      <Typography component="h1" variant="h1">403</Typography>

      <Typography component="h2" variant="subtitle2">
        <Translate i18nKey="FORBIDDEN" defaultValue="Forbidden"/>
      </Typography>

      <Typography variant="body1">
        <Translate i18nKey="FORBIDDEN_TEXT" defaultValue="Access to this resource is denied!"/>
      </Typography>
    </Container>
  );
}

export default Forbidden;
