import { selectActiveProjectUuid } from "@shared-admin-kit/core/lib/modules/company/company.selectors";
import { ObjectHelper } from "../utils";

export const selectActiveProjectResponseDomains = (state) => selectActiveProjectResponse(
  state)?.extra?.domains;

export const selectTokenHasV7InCdnPrefix = (state) => selectActiveProjectResponse(
  state)?.extra?.token_has_v7_in_cdn_prefix === true;

export const selectActiveProjectResponseDefaultDomain = (state) => selectActiveProjectResponse(
  state)?.extra?.default_domain;

export const selectActiveProjectResponse = state => state.company?.activeProjectResponse;

export const selectActiveCompanyProjects = state => state.company?.activeCompany?.projects;

export const selectCloudimgUuid = state => {
  const activeProjectUuid = selectActiveProjectUuid(state);
  if (!activeProjectUuid) {return undefined;}

  const currentProject = (selectActiveCompanyProjects(state) || [])
    .find(p => p.uuid === activeProjectUuid) || {};

  return ObjectHelper.get(
    currentProject,
    ["keychain", "config", "cloudimg_uuid"],
    undefined,
  );
};

export const selectToken = state =>
  selectCloudimgUuid(state) || // using or in this case
  selectActiveProjectUuid(state);
