import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { StyledLayoutSidebarContainer } from './layout-sidebar-container.styled';

export const SIDEBAR_WIDTH = 80;

const useStyles = makeStyles(() => ({
  drawerPaper: {
    position: 'relative',
    width: props => props.width || SIDEBAR_WIDTH,
    background: props => props.background || 'inherit',
  }
}));

function LayoutSidebarContainer({ width = SIDEBAR_WIDTH, background, ...rest }) {
  const classes = useStyles({ width, background });

  return (
    <StyledLayoutSidebarContainer
      variant="permanent"
      width={width}
      {...rest}
      classes={{ paper: classes.drawerPaper }}
    />
  )
}

export default LayoutSidebarContainer;
