import { forwardRef } from 'react';
import Styled from './sfx-button.styles.js';

function SfxButton({ variant = 'text', color = 'default', ...rest }, ref) {
  return (
    <Styled.SfxButton {...rest} innerRef={ref} variant={variant} color={color} />
  );
}

export default forwardRef(SfxButton);
