import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import SidebarLabel from "../../../../../../../sidebar-label";

function SidebarListGroup({ title, children, titleProps, active, onActivate, id }) {
  return (
    <>
      {title && (
        <SidebarLabel
          {...(titleProps || {})}
          onClick={() => onActivate()}
          clickable={true}
          id={id}
        >
          {title}
        </SidebarLabel>
      )}

      <Collapse in={active}>
        {children}
      </Collapse>
    </>
  );
}

export default SidebarListGroup;