import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
import objectPath from 'object-path';


const COLORS = [
  'default', 'inherit', 'primary', 'secondary',
];

//const textVariantStyles = css`
//  /*Custom SFX colors (from Figma) mixin*/
//  ${props => {
//    const color = objectPath.get(props.theme.sfx.palette, props.color)
//    return `color: ${props.color && color ? color : props.theme.sfx.palette.primary.stateless};`
//  }}
//`;

//const containedVariantStyles = css`
//  /* Custom SFX colors (from Figma) mixin*/
//  ${props => {
//    const bgColor = objectPath.get(props.theme.sfx.palette, props.color)
//    return `background: ${props.color && bgColor ? bgColor : props.theme.sfx.palette.primary.stateless} !important;`
//  }}

//  &:disabled {
//    background: lightgrey !important;
//  }

//  color: #ffffff !important;
//`;

const variantMixins = {
  text: css`
    /*Custom SFX colors (from Figma) mixin*/
    ${props => {
      if (props.color && !COLORS.includes(props.color)) {
        const color = objectPath.get(props.theme.sfx.palette, props.color)
        return `color: ${props.color && color ? color : props.theme.sfx.palette.primary.stateless};`
      }

      return ''
    }}
  `,

  contained: css`
    /* Custom SFX colors (from Figma) mixin*/
    ${props => {
      if (props.color && !COLORS.includes(props.color)) {
        const bgColor = objectPath.get(props.theme.sfx.palette, props.color);
        return `background: ${props.color && bgColor ? bgColor : props.theme.sfx.palette.primary.stateless} !important;`
      }

      return '';
    }}

    &:disabled {
      background: lightgrey !important;
    }

    color: #ffffff !important;
  `,
};

const SfxButton = styled(({ color, ...rest }) =>
  <Button
    {...rest}
    color={color && COLORS.includes(color) ? color : undefined}
  />
)`
  /*${props => props.theme.sfx.textStyles.btn.md}*/

  ${props => variantMixins[props.variant] ?? ''}

  + .MuiButton-root {
    margin-left: 8px;
  }
`;


const Styled = {
  SfxButton,
};

export default Styled;
