import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useAuth } from "@shared-admin-kit/core";
import { Translate } from "@shared-admin-kit/translation";
import React, { useLayoutEffect } from "react";
import CompanyAutocomplete from "../../../../routes/company-autocomplete";
import CompanyLogo from "../../../company-logo";
import LanguageDropdown from "../../../language-dropdown";
import { LayoutHeaderContainer, LayoutHeaderToolbar } from "../default";
import UserDropdown from "./components/user-dropdown";

const {
  REACT_APP_AUTH_PUBLIC_URL,
  REACT_APP_RELEASE_NOTES_ENABLED,
} = process.env;

function Header() {
  const { user: currentUser, isAuthenticated } = useAuth();
  const userName = currentUser?.name ||
    (currentUser?.email || "").split("@")[0];

  useLayoutEffect(() => {
    if (REACT_APP_RELEASE_NOTES_ENABLED === 'true') {
      const config = {
        selector: "#release-notes-injection", // CSS selector where to inject the badge
        account: "7zAwkx",
      };

      if (window.Headway) window.Headway.init(config);
    }
  }, []);

  return (
    <LayoutHeaderContainer>
      <LayoutHeaderToolbar>
        <CompanyLogo/>

        <Box flexShrink={0} display="flex" alignItems="center">
          <span id="release-notes-injection"/>

          {isAuthenticated
            ? (
              <>
                <CompanyAutocomplete renderInputProps={{ color: "white" }}/>
                <LanguageDropdown color="white"/>
                <UserDropdown>{userName}</UserDropdown>
              </>
            )
            : (
              <>
                <Button
                  href={REACT_APP_AUTH_PUBLIC_URL}
                  color="inherit"
                  variant="outlined"
                >
                  <Translate i18nKey="LOGIN" defaultValue="Login"/>
                </Button>
              </>
            )
          }
        </Box>
      </LayoutHeaderToolbar>
    </LayoutHeaderContainer>
  );
}

export default Header;
