import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import TourTooltip from './tour-tooltip';
import Styled from './quickstart-tour.styles';
import SfxTypography from '../../components/sfx-typography';
import Modal from '@material-ui/core/Modal'
import { Box } from '@material-ui/core';


function TourStepPopper(props) {
  const dispatch = useDispatch();
  const {
    activeStep, isOpen, anchorEl, tmpStepContent, closeTour,
    steps, previousStep, nextStep, finishTour, currentStep, goToStep,
  } = props;
  const popperArrowRef = useRef(null);

  return (
    <>
      {!activeStep?.isModal ? (
        <Styled.Popper
          placement="bottom"
          // disablePortal={true}
          {...(activeStep?.popperProps || {})}
          modifiers={{
            // flip: {
            //   enabled: false,
            // },
            // preventOverflow: {
            //   enabled: true,
            //   boundariesElement: 'scrollParent',
            // },
            arrow: {
              enabled: Boolean(popperArrowRef.current),
              element: popperArrowRef.current,
            },
          ...(activeStep?.popperProps?.modifiers || {})
          }}
          open={isOpen && Boolean(anchorEl)}
          anchorEl={anchorEl}
          style={{ zIndex: 11 }}
        >
          <Styled.PopperArrow ref={popperArrowRef} />

          <Styled.StepPaper>
            <Styled.CloseButton onClick={() => closeTour()} />

            <SfxTypography variant="text.lg" color="text.primary">
              {tmpStepContent
                ? tmpStepContent
              : (
                typeof activeStep?.content === 'function'
                ? activeStep.content({ dispatch, nextStep, finishTour })
                  : activeStep?.content
              )}
              {}
            </SfxTypography>

            <TourTooltip
              activeStep={activeStep}
              previousStep={previousStep}
              steps={steps}
              currentStep={currentStep}
              goToStep={goToStep}
              nextStep={nextStep}
            />
          </Styled.StepPaper>
        </Styled.Popper>
      ) : (
        <Box
          component={Modal}
          open={isOpen}
          onClose={() => {
            closeTour();
          }}
          display="flex" alignItems="center" justifyContent="center"
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            {activeStep?.content}
            <TourTooltip
                activeStep={activeStep}
                previousStep={previousStep}
                steps={steps}
                currentStep={currentStep}
                goToStep={goToStep}
                nextStep={nextStep}
              />
          </Box>
        </Box>
      )}
    </>
  );
}

export default TourStepPopper;
