import { client } from "../services/api/client";

const { REACT_APP_BASE_URL } = process.env;

/**
 * Cloudimage system check watermarks and default images in one container for all tokens
 * Images stored in : /{dir}/{filename}
 *
 * dir - directory name, it can be "defaults" or "watermarks"
 * filename - {token}{suffix}
 * token - Cloudimage uuid connected with current token
 * suffix - Extra string at the end of filename. For now it's "--001"
 *
 * ##################################################################################
 *
 * After we upload image, backend side need some time to process it ~1min.
 * Only after processing we will see it on images which use watermark.
 * But sometimes we need to update directly.
 * For example:
 *  If we need to show to user direct result with just updated watermark.
 *
 * To solve it we use hack with force_watermark=... URL get param.
 * But we don't need to use force_watermark all the time to not load server,
 * we need to use it only after new watermark upload
 */
class ImageHelper {
  static  CLOUDIMAGE_DEFAULT_IMAGES_DIR = "defaults";

  static  CLOUDIMAGE_WATERMARKS_DIR = "watermarks";

  static  CLOUDIMAGE_ULTRAFAST_URL = ""; // "https://scaleflex.ultrafast.io/";

  static  SCALEFLEX_CLOUDIMG_IO_URL = "https://scaleflex.cloudimg.io/v7/";

  static  CLOUDIMAGE_OPEN_PIX_KEY = "a000000000000000000000000000000f";

  static IMAGE_HEADERS = {
    X_DEFAULT_IMAGE: "x-default-image",
    X_DEFAULT_WATERMARK: "x-default-watermark",
  };

  static IMAGE_STORAGE_VAR_NAMES = {
    WATERMARK_IMAGE: 'watermark_last_upload_timestamp',
    DEFAULT_IMAGE: 'default_image_last_upload_timestamp',
  };

  constructor(storageVarName, delay = 60000) {
    this.storageVarName = storageVarName;
    this.delay = delay; // "delay" to process uploaded watermark on backend
  }

  get timestamp() {
    return +sessionStorage.getItem(this.storageVarName);
  }

  // Store last image update timestamp
  stamp(timestamp) {
    sessionStorage.setItem(this.storageVarName, timestamp || Date.now());
  }

  isImageProcessed() {
    if (!this.timestamp) {
      return true;
    }

    if ((Date.now() - this.timestamp) > this.delay) {
      sessionStorage.removeItem(this.storageVarName);
      return true;
    }

    return false;
  }

  /**
   * @param {string} imageUrl
   * @return {Promise<boolean>}
   */
  static isImageExist(imageUrl) {
    return new Promise(resolve => {
      if (!imageUrl) {
        return resolve(false);
      }

      const image = new Image();
      image.onload = () => resolve(true);
      image.onerror = () => resolve(false);
      image.src = imageUrl;
    });
  };

  /**
   * @param {string} token
   * @param {string} [suffix="--001"]
   * @return {string} Return token with suffix
   */
  static getWatermarkFileName(token, suffix = "--001") {
    return `${token}${suffix}`;
  }

  /**
   * @param {string} token
   * @param {string} [suffix]
   * @return {string} Return token with suffix
   */
  static getDefaultImageFileName(token, suffix = "") {
    return `${token}${suffix}`;
  }

  static getDefaultImageURL(timestamp, token) {
    return token
      ? `${ImageHelper.CLOUDIMAGE_ULTRAFAST_URL}${REACT_APP_BASE_URL}/file/${token}/default?&hash=${timestamp}`
      : "";
  };

  static getWatermarkImageURL(timestamp, token) {
    return token
      ? `${ImageHelper.CLOUDIMAGE_ULTRAFAST_URL}${REACT_APP_BASE_URL}/file/${token}/watermark?&hash=${timestamp}`
      : "";
  };

  /**
   * @param {string} imageUrl
   * @param {string} headerName
   * @param {boolean || string} booleanBase
   * @description returns true if it's using the default image or throws error there's no image returned
   * @return {Promise<boolean>}
   */
  static isDefaultImage(imageUrl, headerName = "", booleanBase = 1) {
    if (!imageUrl || !headerName) {
      throw new Error("invalid params");
    }

    return client.get(imageUrl)
      .then(res => {
        const value = res.headers[headerName];
        return value === booleanBase.toString(); // header  value is string
      });
  };

  static deleteDefaultImageRequest(token) {
    return client
      .delete(`${REACT_APP_BASE_URL}/file/${token}/default`);
  }

  static deleteWatermarkRequest(token) {
    return client
      .delete(`${REACT_APP_BASE_URL}/file/${token}/watermark`);
  }
}

export {
  ImageHelper,
};
