import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

export const Button = styled(MuiButton)`
  margin: 0 7px;
  text-transform: none;
  letter-spacing: 0;

  &.active:after {
    content: '';
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 0;
    height: 3px;
    background: ${props => props.theme.palette.secondary.main};
  }

  &:hover{
    background: ${props => props.theme.palette.action.hover};
  }
`;
