import { useCompany } from "@shared-admin-kit/core";
import { Translate } from "@shared-admin-kit/translation";
import React from "react";
import { ROUTES } from "../../../../../../routes";
import { ObjectHelper } from "../../../../../../utils";
import RouterLink from "../../../../../router-link";
import StyledAlert from "../../../../../styled-alert";

function ResizeActiveAlert() {
  const { activeProject } = useCompany();

  const resize_active = ObjectHelper.get(activeProject,
    ["meta", "resize_active"]);

  if (resize_active !== 0) {
    return null;
  }

  return (
    <StyledAlert severity="error" borderRadius={0}>
      <div>
        <Translate i18nKey="RESIZE_ACTIVE.ALERT.TEXT1" defaultValue="Your CloudImage token has been deactivated due to missing billing details. Please"/>&nbsp;
        <RouterLink to={ROUTES.CONTACT_US()}><Translate i18nKey="CONTACT_US" defaultValue="Contact us"/></RouterLink>&nbsp;
        <Translate i18nKey="RESIZE_ACTIVE.ALERT.TEXT2" defaultValue="if you want it reactivated."/>
      </div>
    </StyledAlert>
  );
}

export default ResizeActiveAlert;
