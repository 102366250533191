import React from 'react';
import { MainLoaderWrapper } from './main-loader.styled';
import Loader from '../loader';

function MainLoader() {
  return (
    <MainLoaderWrapper>
      <Loader />
    </MainLoaderWrapper>
  )
}

export default MainLoader;