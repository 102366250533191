import createTheme from "@material-ui/core/styles/createTheme";
import {
  ACTIVE_ICON_BG_COLOR,
  STYLED_PAPER_TITLE_BG_COLOR,
  SHADOWS,
} from "./theme.constants";
import { sfx } from './sfxTheme';

const theme = createTheme({
  sfx,
  //':hover': { backgroundColor: theme.palette.action.hover },
  //  // backgroundColor: theme.sfx.palette.bg.primary

  palette: {
    primary: {
      //main: sfx.palette.secondary.blue // "#5164CD", "#1F43D3"
      main: sfx.palette.primary.stateless // "#5164CD", "#1F43D3"
    },
    secondary: {
      main: sfx.palette.secondary.blue,
    },
    background: {
      default: '#ffffff',

      // Custom variables:
      tokenBlock: ACTIVE_ICON_BG_COLOR,
      sessionExpired: ACTIVE_ICON_BG_COLOR,
      emailNotValid: ACTIVE_ICON_BG_COLOR,
      planHeaderSecondary: ACTIVE_ICON_BG_COLOR,
      styledPaperTitle: STYLED_PAPER_TITLE_BG_COLOR,
    },

    button: {
      active: sfx.palette.secondary.blue, // "#5164CD",
      hover: 'rgb(56, 70, 143)',
    },

    action: {
      // active: "rgba(81, 100, 205, 0.54)",
      hover: sfx.palette.bg.primary, // "rgba(81, 100, 205, 0.04)",
      hoverOpacity: 0.04,
      selected: sfx.palette.bg.primary, // "rgba(81, 100, 205, 0.08)",
      selectedOpacity: 0.08,
      // disabled: "rgba(81, 100, 205, 0.26)",
      // disabledBackground: "rgba(81, 100, 205, 0.12)",
      // disabledOpacity: 0.38,
      focus: "rgba(81, 100, 205, 0.12)",
      // focusOpacity: 0.12,
      // activatedOpacity: 0.12,
    },

    shadows: SHADOWS,

    statusLabel: {
      warning: "#FC5791",
      danger: "#FC5791",
      success: "#28A745",
      missing: "#DC3545",
      default: "none",
    },
  },

  typography: {
    //fontFamily: "Gotham Pro, Arial, sans-serif",
    fontFamily: "Inter, Gotham Pro, Arial, sans-serif",

    subtitle1: {
      fontSize: 30,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 24,
      fontWeight: 400,
    },
  },

  overrides: {
    MuiPaper: {
      elevation1: {
        //boxShadow: SHADOWS[4],
        boxShadow: '0px 0px 12px #CDD3F1',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 13,
      },
    },

    MuiAppBar: {
      colorDefault: {
        boxShadow: 'none',
        backgroundColor: STYLED_PAPER_TITLE_BG_COLOR,
      },
    },

    MuiLink: {
      root: {
        color: sfx.palette.secondary.blue,
      }
    },

    MuiButton: {
      root: {
        ...sfx.textStyles.btn.md,
        textTransform: 'none',
        height: 44,
        padding: '10px 24px !important',
      },
      sizeSmall: {
        ...sfx.textStyles.btn.sm,
        height: 32,
        padding: '6px 16px !important',
      },
      //sizeLarge: {}
      contained: {
        color: '#ffffff',
      },
      containedPrimary: {
        color: '#ffffff',
      },
    }
  },
});

export default theme;
