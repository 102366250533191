export const PLAN_NAME_CUSTOM_APPSUMO = "CUSTOM_APPSUMO";

export const PLANS = {
  FREE: "FREE",
  STARTUP: "STARTUP",
  PRO: "PRO",
  ENTERPRISE: "ENTERPRISE",
  ROCKET: "ROCKET",
};

export const PLANS_WITHOUT_MANDATORY_PAYMENT_DATA = [
  PLANS.FREE,
  PLANS.ENTERPRISE,
];

export const LOW_END_PLANS = [
  PLANS.FREE,
  PLANS.STARTUP,
];

export const PLAN_GROUP_ITEM_TEMPLATE = {
  CACHE_GB: "{cache_gb} GB Image Cache",
  TRAFFIC_MO_GB: "{traffic_mo_gb} GB/Mo Traffic",
  OVERUSE_GB: "{overuse_gb} € / GB overuse",
  UP_TIME_PERCENT: "{uptime_percent}% Uptime",
  MONTHLY_COMMIT: "{monthly_commit} € Monthly Price",
  NO_SUPPORT: "No Support",
};
