import axios from 'axios';
import queryString from "query-string";
import { client } from "../../services/api/client";

const CancelToken = axios.CancelToken;
let zendeskCancelTokenSource;

/**
 * @param {Contact} contact
 * @returns {Promise}
 */
export const sendContactFormData = contact =>
  client.post('/contact?type=TECH', { contact });
/**

 /**
 * @returns {Promise}
 */
export const getContactTicketsRequest = () =>
  client.get('/contact/tickets');

/**
 * @param {object} args
 * @param {string} args.zendeskBaseApiUrl
 * @param {string} args.query
 * @param {number} [args.perPage]
 * @param {number} [args.page]
 * @returns {AxiosPromise<ZendeskArticleSearchResponse>}
 */
export const zendeskSearchRequest = ({
  zendeskBaseApiUrl,
  query = '',
  perPage = 5,
  page = 1,
  ...queryParams
}) => {
  const options = {};

  if (zendeskCancelTokenSource) {
    zendeskCancelTokenSource.cancel();
  }

  zendeskCancelTokenSource = CancelToken.source();
  options.cancelToken = zendeskCancelTokenSource.token;

  const _queryParams = {
    ...queryParams,
    query,
    per_page: perPage,
    page,
  };

  return axios.get(
    `${zendeskBaseApiUrl}/help_center/articles/search?${queryString.stringify(
      _queryParams)}`,
    options,
  );
};
