import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { CoreProvider, GlobalNotificationsProvider } from '@shared-admin-kit/core';
import { ConnectedRouter } from "connected-react-router";
import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import App from "./app";
import MainLoader from "./components/main-loader";
import { core, handleForbiddenPage, history } from "./core";
import "./index.css";
import i18nInstance from "./services/i18n.client";
import { store } from "./store";
import theme from "./theme";

ReactDOM.render(
  (
    <I18nextProvider i18n={i18nInstance}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <ThemeProviderStyled theme={theme}>
              <StylesProvider injectFirst>
                <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                  <CoreProvider
                    core={core}
                    loader={
                      <MainLoader/>
                    }
                    onForbiddenPage={handleForbiddenPage}
                  >
                    <CssBaseline/>
                    <Suspense fallback={<MainLoader/>}>
                      <GlobalNotificationsProvider>
                        <App/>
                      </GlobalNotificationsProvider>
                    </Suspense>
                  </CoreProvider>
                </SnackbarProvider>
              </StylesProvider>
            </ThemeProviderStyled>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </I18nextProvider>
  ),
  document.querySelector("#root"),
);
