import {
  createContext, useState, useMemo, useCallback, useContext, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from "notistack";
import { useCompany, useSessionData } from '@shared-admin-kit/core';
import { useTranslation } from "@shared-admin-kit/translation";
import { usePermissions } from '@shared-admin-kit/permissions';
import { push } from 'connected-react-router';
import { TOURS } from './quickstart-tour.constants';
import Mask from './mask';
import CongratsModal from './congrats-modal';
import QuickstartBlock from './quickstart-block';
import { matchPathWithRoutes } from '@shared-admin-kit/utils';
import { ROUTES, NOT_AUTHENTICATED_ROUTES } from '../../routes';
import TourStepPopper from './tour-step-popper';

export const QuickstartTourContext = createContext();

export function QuickstartTourProvider({ children }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { hasPermissions } = usePermissions();
  const { activeProjectUuid } = useCompany();
  const { data: sessionData, saveSessionData } = useSessionData();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isQuickstartOpen, setIsQuickstartOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState();
  const [activeTour, setActiveTour] = useState(null);
  const [stepAnchorEl, setStepAnchorEl] = useState(null);
  const [isCongratsModalVisible, setIsCongratsModalVisible] = useState(false);
  const [tmpStepContent, setTmpStepContent] = useState(null);
  const [quickstartAnimation, setQuickstartAnimation] = useState(false);
  const activeStep = steps?.[currentStep];

  const { tours, allFinished, finishedProgress, toursLeftCount } = useMemo(() => {
    const newTours = TOURS
      .filter(tour => {
        if (Array.isArray(tour.permsIds) && tour.permsIds.length > 0) {
          return hasPermissions(tour.permsIds)
        }

        return true
      })
      .map(tour => ({
        ...tour,
        finished: tour.finishedByDefault ? true : Boolean(sessionData?.[tour.sessionDataPropName]),
        steps: (tour?.steps || [])
          .filter(step => {
            if (Array.isArray(step.permsIds) && step.permsIds.length > 0) {
              return hasPermissions(step.permsIds)
            }

            return true
          })
          .map(step => ({
            content: '',
            showTooltip: true,
            showDots: true,
            showPreviousBtn: true,
            showNextAndFinishBtn: true,
            ...step,
          }))
      }));

    const finishedToursCount = newTours.filter(tour => tour.finished).length

    return {
      tours: newTours,
      allFinished: finishedToursCount >= newTours.length,
      toursLeftCount: newTours.length - finishedToursCount,
      finishedProgress: finishedToursCount * 100 / newTours.length,
    }
  }, [hasPermissions, sessionData]);

  const updateView = useCallback((nextStepIndex = currentStep, step = null, forceUpdate = false) => {
    const _nextStep = step || steps?.[nextStepIndex];

    if (!_nextStep || (!isTourOpen && !forceUpdate)) {
      return;
    }

    if (_nextStep.action) {
      _nextStep.action({ stepIndex: nextStepIndex, dispatch });
    }

    const _updateView = (targetElement) => {
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: 'smooth' });
        setStepAnchorEl(targetElement)
        setCurrentStep(nextStepIndex);
      }, 0); // small trick to execute code synchronously
    };

    if (_nextStep.selector) {
      let targetElement = document.querySelector(_nextStep.selector);

      if (targetElement) {
        _updateView(targetElement);

        // HACK! Fix bug: first step Popper arrow use bad arrow position
        if (nextStepIndex === 0) {
          setTimeout(() => {
            if (_nextStep.action) {
              _nextStep.action({ stepIndex: nextStepIndex, dispatch });
            }
          }, 0);
        }
      }
      else {
        const observer = new MutationObserver(() => {
          targetElement = document.querySelector(_nextStep.selector);

          if (!targetElement) {
            return;
          }

          if (document.contains(targetElement)) {
            _updateView(targetElement);

            observer.disconnect();
          }
        });

        observer.observe(document, {
          attributes: true,
          childList: true,
          characterData: true,
          subtree: true,
        });
      }
    }

    // setCurrentStep(nextStepIndex);
  }, [steps, currentStep, dispatch, isTourOpen]);

  const handleSaveSessionData = useCallback((changes = {}) => {
    if (changes) {
      saveSessionData({ ...sessionData, ...(changes || {}) })
    }
  }, [saveSessionData, sessionData]);

  const closeTour = useCallback(() => {
    setIsTourOpen(false);
    setActiveTour(null);
  }, []);

  const finishTour = useCallback((tour = activeTour, route) => {
    if (tour) {
      closeTour();
      handleSaveSessionData({ [tour.sessionDataPropName]: true });
      if (route) {
        dispatch(push(route));
      }
      setIsQuickstartOpen(true);

      if (!tour.finished && toursLeftCount === 1) {
        setIsCongratsModalVisible(true)
      }
    }
  }, [handleSaveSessionData, dispatch, activeTour, closeTour, toursLeftCount]);

  // const finishTourById = useCallback(tourId => {
  //   const tour = tourId ? getTourById(tourId) : activeTour;
  //   if (tour) {
  //     finishTour(tour)
  //   }
  // }, [finishTour, getTourById, activeTour]);

  const goToStep = useCallback((stepIndex, step = null, forceUpdate = false) => {
    setTmpStepContent(null);
    if (isTourOpen || forceUpdate) updateView(stepIndex, step, forceUpdate);
  }, [updateView, isTourOpen]);

  const nextStep = useCallback(() => {
    if (steps) {
      if (steps.length > currentStep + 1) {
        goToStep(currentStep + 1)
      } else {
        finishTour()
      }
    }
  }, [steps, finishTour, currentStep, goToStep]);

  const previousStep = useCallback(() => {
    if (steps && currentStep > 0) {
      goToStep(currentStep - 1)
    }
  }, [steps, currentStep, goToStep]);

  const getTourById = useCallback(tourId => tours.find(({ id }) => id === tourId), [tours]);

  const openTour = useCallback(tourId => {
    const tour = getTourById(tourId);
    const nextSteps = tour?.steps;

    if (!(nextSteps || []).length) {
      return;
    }

    setActiveTour(tour);
    setIsQuickstartOpen(false);
    setSteps(nextSteps);
    setCurrentStep(0); // reset to step 0 after change tour
    setIsTourOpen(true);
    goToStep(0, nextSteps?.[0], true);
  }, [getTourById, goToStep]);

  // const closeAndFinishTour = useCallback((tourId) => {
  //   closeTour(tourId);
  //   finishTourById(tourId);
  // }, [closeTour, finishTourById]);

  // const resetTour = useCallback(tourId => {
  //   const tour = getTourById(tourId);
  //   if (tour) {
  //     handleSaveSessionData({ [tour.sessionDataPropName]: false })
  //   }
  // }, [handleSaveSessionData, getTourById]);

  const resetAllTours = useCallback(() => {
    const changes = {};
    tours.forEach(tour => {
      changes[tour.sessionDataPropName] = tour.finishedByDefault ? true : false;
    });
    handleSaveSessionData(changes);
    enqueueSnackbar(
      t("QUICKSTART.RESTARTED", `Quickstart restarted!`),
      { variant: 'success' },
    );
  }, [tours, handleSaveSessionData, enqueueSnackbar, t]);

  // useEffect(() => {
  //   resetAllTours()
  // }, []);

  useEffect(() => {
    const onResize = () => updateView();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [updateView]);

  return (
    <QuickstartTourContext.Provider
      value={{
        allFinished,
        nextStep,
        resetAllTours,
        updateView,
        setTmpStepContent,
        openQuickstart: (options) => {
          const opts = { animation: false, delay: 0, ...(options || {}) };
          setTimeout(() => {
            setIsQuickstartOpen(true);
            setQuickstartAnimation(true);
            setTimeout(() => setQuickstartAnimation(false), 2000);
          }, opts.delay);
        }
      }}
    >
      {children}

      {activeProjectUuid && (
        <>
          <TourStepPopper
            isOpen={isTourOpen}
            activeStep={activeStep}
            anchorEl={stepAnchorEl}
            tmpStepContent={tmpStepContent}
            closeTour={closeTour}
            steps={steps}
            previousStep={previousStep}
            nextStep={nextStep}
            finishTour={finishTour}
            currentStep={currentStep}
          />

          {isTourOpen && activeStep?.showMask !== false && !activeStep?.isModal ? <Mask anchorEl={stepAnchorEl} onClose={() => closeTour()} /> : null}

          {!allFinished && !isTourOpen && !matchPathWithRoutes([...Object.values(NOT_AUTHENTICATED_ROUTES), ROUTES.ONBOARDING]) && (
            <QuickstartBlock
              isQuickstartOpen={isQuickstartOpen}
              setIsQuickstartOpen={setIsQuickstartOpen}
              finishedProgress={finishedProgress}
              tours={tours}
              openTour={openTour}
              animation={quickstartAnimation}
            />
          )}

          <CongratsModal open={isCongratsModalVisible} onClose={() => setIsCongratsModalVisible(false)} />
        </>
      )}
    </QuickstartTourContext.Provider>
  );
}

export function useQuickstartTour() {
  return useContext(QuickstartTourContext);
}
