import {
  PROJECT_FETCH_DATA,
  PROJECT_SET_ACTIVE_TAB_SLUG,
  PROJECT_SET_DATA,
  PROJECT_SET_IS_LOADING,
  PROJECT_SET_IS_SAVING_DATA,
} from "./project.actions";

export const initialState = {
  data: null,
  isLoading: false,
  isSavingProjectData: false,
  activeTabSlug: null, // keep the default value null or undefined (doing a check in some components)
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_SET_DATA:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        isSavingProjectData: false,
      };
    case PROJECT_FETCH_DATA:
      return {
        ...state,
        data: initialState.data,
      };
    case PROJECT_SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case PROJECT_SET_IS_SAVING_DATA:
      return {
        ...state,
        isSavingProjectData: action.isSavingProjectData,
      };
    case PROJECT_SET_ACTIVE_TAB_SLUG:
      return {
        ...state,
        activeTabSlug: action.activeTabSlug,
      };
    default:
      return state;
  }
};
