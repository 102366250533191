import { createRole, validateRoles } from '@shared-admin-kit/permissions';
// eslint-disable-next-line no-unused-vars
import { UserRole } from '@shared-admin-kit/types/lib/permissions';
import { LEVEL_COMPANY, LEVEL_PROJECT } from '@shared-admin-kit/types/lib/auth';


const USER_ROLES = {
  READ: 'read',
  WRITE: 'write',
  ACCOUNTANT: 'accountant',
  MANAGER: 'manager',
  ADMIN: 'admin',
  OWNER: 'owner',
};


const READ_ROLES = Object.values(USER_ROLES);
const WRITE_ROLES = [USER_ROLES.WRITE, USER_ROLES.ACCOUNTANT, USER_ROLES.MANAGER, USER_ROLES.ADMIN, USER_ROLES.OWNER];
const ADMIN_ROLES = [USER_ROLES.MANAGER, USER_ROLES.ADMIN, USER_ROLES.OWNER];
const ORDERED_ROLES = Object.values(USER_ROLES);
const ROLE_LEVELS = {
  COMPANY: LEVEL_COMPANY,
  PROJECT: LEVEL_PROJECT
};

const PERM_ID = {
  // View:
  VIEW_ANALYZE: 'VIEW_ANALYZE',
  VIEW_RUM_ANALYTICS: 'VIEW_RUM_ANALYTICS',
  VIEW_ACCOUNT_BILLING: 'VIEW_ACCOUNT_BILLING',
  VIEW_COMPANY_SETTINGS: 'VIEW_COMPANY_SETTINGS',
  VIEW_PROJECT_SETTINGS: 'VIEW_PROJECT_SETTINGS',
  VIEW_PROJECT_STORAGE: 'VIEW_PROJECT_STORAGE',
  VIEW_PROJECT_INVALIDATION: 'VIEW_PROJECT_INVALIDATION',
  VIEW_USER_ACCESS: 'VIEW_USER_ACCESS',
  VIEW_COUPONS: 'VIEW_COUPONS',
  VIEW_PLANS: 'VIEW_PLANS',
  VIEW_ACCOUNT_PARTNER_ACCOUNTS: 'VIEW_ACCOUNT_PARTNER_ACCOUNTS',
  // Crud:
  CRUD_BILLING: 'CRUD_BILLING',
  CRUD_COMPANY: 'CRUD_COMPANY',
  CRUD_PAYMENT_CARD: 'CRUD_PAYMENT_CARD',
};

const readRole = createRole({
  id: USER_ROLES.READ,
  perms: [ PERM_ID.VIEW_ANALYZE, PERM_ID.VIEW_RUM_ANALYTICS ],
});

const writeRole = createRole({
  id: USER_ROLES.WRITE,
  perms: [
    ...readRole.perms,
    PERM_ID.VIEW_PROJECT_SETTINGS,
    PERM_ID.VIEW_PROJECT_STORAGE,
    PERM_ID.VIEW_PROJECT_INVALIDATION,
  ],
});

const accountantRole = createRole({
  id: USER_ROLES.ACCOUNTANT,
  perms: [
    ...readRole.perms,

    PERM_ID.VIEW_ACCOUNT_BILLING,
    PERM_ID.VIEW_ACCOUNT_PARTNER_ACCOUNTS,
    PERM_ID.VIEW_PLANS,

    PERM_ID.CRUD_BILLING,
    PERM_ID.CRUD_COMPANY,
    PERM_ID.CRUD_PAYMENT_CARD,
  ],
});

const managerRole = createRole({
  id: USER_ROLES.MANAGER,
  perms: [
    ...writeRole.perms,
    PERM_ID.VIEW_COUPONS,
    // PERM_ID.VIEW_PLANS,
    PERM_ID.VIEW_COMPANY_SETTINGS,
    PERM_ID.VIEW_USER_ACCESS,
    PERM_ID.VIEW_ACCOUNT_PARTNER_ACCOUNTS,

    PERM_ID.CRUD_COMPANY,
    PERM_ID.CRUD_PAYMENT_CARD,
  ],
});

const adminRole = createRole({
  id: USER_ROLES.ADMIN,
  perms: [
    /**
     * Copy permissions of manager role then:
     * - Comment out VIEW_PLANS and VIEW_COMPANY_SETTINGS
     */
    ...writeRole.perms,
    PERM_ID.VIEW_COUPONS,
    // PERM_ID.VIEW_PLANS,
    // PERM_ID.VIEW_COMPANY_SETTINGS,
    PERM_ID.VIEW_USER_ACCESS,
    PERM_ID.VIEW_ACCOUNT_PARTNER_ACCOUNTS,

    PERM_ID.CRUD_COMPANY,
    PERM_ID.CRUD_PAYMENT_CARD,

    PERM_ID.VIEW_ACCOUNT_BILLING,
    PERM_ID.CRUD_BILLING,
  ],
});

const ownerRole = createRole({
  id: USER_ROLES.OWNER,
  perms: Object.keys(PERM_ID).map((permKey) => PERM_ID[permKey]),
});

/**
 * @description roles order is important, higher index means more access
 * @type{UserRole[]}
 */
const ROLES = [
  readRole,
  writeRole,
  accountantRole,
  managerRole,
  adminRole,
  ownerRole,
];
validateRoles({
  PERM_ID,
  ROLES,
  ROLE_ID: USER_ROLES,
});

const DEFAULT_USER_ROLES = USER_ROLES.READ;


export {
  USER_ROLES,
  PERM_ID,
  ROLES,
  DEFAULT_USER_ROLES,
  READ_ROLES,
  WRITE_ROLES,
  ADMIN_ROLES,
  ORDERED_ROLES,
  ROLE_LEVELS,
};
