class BooleanHelper {
  /**
   * @param {any} value
   * @param {boolean} condition
   */
  static preserveBooleanBase(value, condition) {
    if (typeof value === "number") {
      return condition ? 1 : 0;
    }

    if (typeof value === "boolean") {
      return condition ? true : false;
    }

    return condition;
  };

  /**
   * @param {any} value
   * @returns {boolean}
   */
  static parseBoolean(value) {
    if (typeof value == "string") {
      return value === "true";
    }

    return Boolean(value);
  };
}

export {
  BooleanHelper,
};
