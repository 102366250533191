import { useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth, useCompany } from "@shared-admin-kit/core";
import {
  SIDEBAR_LIST_GROUP_IDS, SIDEBAR_LIST_GROUP_OPTIONS, SIDEBAR_LIST_GROUP_ITEM_IDS, SIDEBAR_LIST_GROUP_ITEM_OPTIONS,
  FREQUENLY_USED_GROUP_ITEM_IDS
} from "../components/layout/components/sidebar/components/sidebar-list/sidebar-list.constants";
import { ADMIN_ROLES, PERM_ID, READ_ROLES, ROLE_LEVELS, WRITE_ROLES, USER_ROLES } from "../permissions.constants";
import { ROUTES } from "../routes";
import { selectActiveGroupIds } from "../selectors/sidebar.selectors";
import { CookieHelper } from "../utils";
import { activateGroupAction, deactivateGroupAction, setActiveGroupIdsAction } from "../actions/sidebar.actions";
import { DEFAULT_ACTIVE_GROUP_IDS } from "../reducers/sidebar.reducer";
import { findActiveGroupIdByPath } from "../components/layout/components/sidebar/components/sidebar-list/sidebar-list.utils";
import { usePermissions } from "@shared-admin-kit/permissions";
import { useQuickstartTour } from "../providers/quickstart-tour/quickstart-tour.provider";

function useSidebarMenu() {
  const dispatch = useDispatch();
  const { hasPermissions } = usePermissions();
  const { nextStep, resetAllTours, allFinished } = useQuickstartTour();
  const activeGroupIds = useSelector(selectActiveGroupIds);
  const { role, roleLevel } = useAuth();
  const { projects } = useCompany();

  const handleToggle = useCallback((groupId) => {
    dispatch(
      (activeGroupIds || []).includes(groupId) ? deactivateGroupAction(groupId) : activateGroupAction(groupId)
    )
  }, [activeGroupIds, dispatch])

  /**
   * Helper object for accordion menu.
   * Each group has id which help to control activeGroup state and group routes array for initial group activation.
   * For example if user go to /plans page directly we will know initial route and we can find it in ACCOUNT group,
   * so in accordion menu initially we will show opened ACCOUNT group instead of first one.
   * IMPORTANT! Be sure that group's routes are always actual.
   * @type {SidebarListGroups}
   */
  const GROUPS_BASE = useMemo(() => {
    const isMultiTenantCompany = (projects || []).length > 1; // Company had multiple tokens
    const canRead = READ_ROLES.includes(role);
    const canWrite = WRITE_ROLES.includes(role)
    const isCompanyAdmin = ADMIN_ROLES.includes(role) && roleLevel === ROLE_LEVELS.COMPANY;
    const canViewAccountSettings = [USER_ROLES.ACCOUNTANT, USER_ROLES.MANAGER, USER_ROLES.OWNER, USER_ROLES.ADMIN].includes(role) && roleLevel === ROLE_LEVELS.COMPANY;
    const isAdmin = role === USER_ROLES.ADMIN;

    const generateGroup = (id, items = []) => {
      const filteredItems = items.filter(Boolean);

      return filteredItems.length === 0 ? null : {
        ...SIDEBAR_LIST_GROUP_OPTIONS[id],
        items: filteredItems,
        active: false, // activeGroupIds.includes(id),
        onActivate: () => {} // () => handleToggle(id)
      }
    }

    const generateGroupItem = (id) => {
      const itemProps = SIDEBAR_LIST_GROUP_ITEM_OPTIONS[id];

      return {
        ...itemProps,
        route: typeof itemProps.route === 'function' ? itemProps.route({ ROUTES }) : itemProps.route,
        componentProps: {
          ...(itemProps.componentProps || {}),
          ...(typeof itemProps?.componentProps?.onClick === 'function' ? {
            onClick: () => itemProps.componentProps.onClick({ nextStep, resetAllTours })
          } : {}),
        }
      }
    }

    return [
      canRead && generateGroup(
        SIDEBAR_LIST_GROUP_IDS.STATS_LOG,
        [
          process.env.REACT_APP_SHOW_STATS_OVERVIEW === 'true' && isCompanyAdmin && isMultiTenantCompany
          && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_OVERVIEW),

          generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD),

          ...(process.env.REACT_APP_NEW_DASHBOARDS === 'true' ? [
            generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_DAILY),
            generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_DELIVERY),
            generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_PROMINENTS),
            generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_ADVANCED_ANALYTICS),
            generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.RUM),
          ] : []),

          generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.LOGS),

          CookieHelper.getCookie('BETA') === '1' && hasPermissions([PERM_ID.VIEW_ANALYZE])
          && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.ANALYZE),
        ]
      ),

      canWrite && generateGroup(
        SIDEBAR_LIST_GROUP_IDS.MANAGE,
        [
          hasPermissions([PERM_ID.VIEW_PROJECT_SETTINGS]) && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.IMAGE_SETTINGS),
          hasPermissions([PERM_ID.VIEW_PROJECT_STORAGE]) && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.STORAGE),
          hasPermissions([PERM_ID.VIEW_PROJECT_INVALIDATION]) && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.INVALIDATION),
          CookieHelper.getCookie('BETA') === '1' && hasPermissions([PERM_ID.VIEW_RUM_ANALYTICS]) && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.RUM_ANALYTICS),
          isCompanyAdmin && hasPermissions([PERM_ID.VIEW_USER_ACCESS]) && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.USER_ACCESS),
        ]
      ),

      canViewAccountSettings && generateGroup(
        SIDEBAR_LIST_GROUP_IDS.ACCOUNT,
        [
          hasPermissions([PERM_ID.VIEW_PLANS]) && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.PLANS),
          hasPermissions([PERM_ID.VIEW_ACCOUNT_BILLING]) && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.BILLING),
          // improvement: Add AFFILIATION to PERM_ID?
          !isAdmin && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.AFFILIATION),
          hasPermissions([PERM_ID.VIEW_COMPANY_SETTINGS]) && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.SECURITY),
        ]
      ),

      generateGroup(
        SIDEBAR_LIST_GROUP_IDS.HELP_SUPPORT,
        [
          generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.DOCUMENTATION),
          generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.CONTACT),
          allFinished && generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.QUICKSTART),
          generateGroupItem(SIDEBAR_LIST_GROUP_ITEM_IDS.ONBOARDING),
        ]
      ),
    ].filter(Boolean)
  }, [projects, role, roleLevel, hasPermissions, allFinished, nextStep, resetAllTours]); // , activeGroupIds, handleToggle

  const GROUPS = useMemo(() => (GROUPS_BASE || []).map((group) => ({
    ...group,
    active: activeGroupIds.includes(group.id),
    onActivate: () => handleToggle(group.id)
  })), [GROUPS_BASE, activeGroupIds, handleToggle]);

  const activeGroupId = useMemo(() => findActiveGroupIdByPath(GROUPS_BASE), [GROUPS_BASE]);

  const frequentlyUsedGroupItems = useMemo(() => {
    const allItems = GROUPS.reduce((accum, group) => ([ ...accum, ...(group?.items || []) ]), [])
    const frequentlyUsedItems = []

    FREQUENLY_USED_GROUP_ITEM_IDS.forEach((itemId) => {
      const index = allItems.findIndex(item => item?.id === itemId)

      if (index > -1) {
        frequentlyUsedItems.push(
          allItems.splice(index, 1)[0]
        )
      }
    })

    return [...frequentlyUsedItems, ...allItems].slice(0, 4)
  }, [GROUPS])

  useEffect(() => {
    dispatch(setActiveGroupIdsAction([
      activeGroupId,
      ...(GROUPS_BASE.length > 0 && GROUPS_BASE.length < 3 ? GROUPS_BASE.map((group) => group.id) : []),
      ...DEFAULT_ACTIVE_GROUP_IDS
    ].filter(Boolean)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGroupId, dispatch, role, roleLevel]);

  return {
    role, roleLevel, GROUPS, frequentlyUsedGroupItems
  }
}

export { useSidebarMenu }
