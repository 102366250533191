import { BillingModule } from "@shared-admin-kit/billing";
import SharedAdminKit from "@shared-admin-kit/core";
import { InvalidationModule } from "@shared-admin-kit/invalidation";
import { InvoicesModule } from "@shared-admin-kit/invoices";
import { TeamsModule } from "@shared-admin-kit/teams";
import { TranslationModule } from "@shared-admin-kit/translation";
import { PermissionsModule } from '@shared-admin-kit/permissions';
import { createBrowserHistory } from "history";
import { isQAMode, isTestCardMode } from "./global.constants";
import { AUTHENTICATED_ROUTES, NOT_AUTHENTICATED_ROUTES, ROUTES } from "./routes";
import { client } from "./services/api/client";
import { clientInvalidation } from "./services/api/client-georeplicated";
import i18nInstance from "./services/i18n.client";
import Forbidden from "./routes/forbidden";

const {
  REACT_APP_I18N_IT_BASE_URL,
  REACT_APP_I18N_GRID_UUID,
  REACT_APP_AUTH_PUBLIC_URL,
  REACT_APP_DEBUG_LANGUAGE,
  // REACT_APP_DEBUG_PERMISSIONS,
  REACT_APP_DEV_LOGIN_ENABLED,
} = process.env;

const history = createBrowserHistory();

const core = SharedAdminKit({
  client,
  history,
  authRoutes: Object.keys(AUTHENTICATED_ROUTES)
    .map((routeKey) => AUTHENTICATED_ROUTES[routeKey]),
  publicRoutes: Object.keys(NOT_AUTHENTICATED_ROUTES)
    .map((routeKey) => NOT_AUTHENTICATED_ROUTES[routeKey]),
  devLogin: { enabled: REACT_APP_DEV_LOGIN_ENABLED === 'true' },
  loginUrl: REACT_APP_AUTH_PUBLIC_URL,
  sessionChecker: {
    enabled: true,
    redirectSessionExpired: ROUTES.SESSION_EXPIRED,
    excludeRoutes: Object.keys(NOT_AUTHENTICATED_ROUTES)
      .map(routeKey => NOT_AUTHENTICATED_ROUTES[routeKey]),
  },
})
  .use(TranslationModule, {
    i18nInstance,
    i18nBaseUrl: REACT_APP_I18N_IT_BASE_URL,
    i18nGridUuid: REACT_APP_I18N_GRID_UUID,
    debug: REACT_APP_DEBUG_LANGUAGE === 'true',
  })
  .use(TeamsModule)
  .use(BillingModule, { testCardMode: isQAMode || isTestCardMode })
  .use(InvoicesModule)
  .use(InvalidationModule, { clientInvalidation })
  .use(PermissionsModule);

const handleForbiddenPage = () => {
  if (['/', ROUTES.HOME].includes(window.location.pathname)) {
    window.location.href = process.env.REACT_APP_AUTH_PUBLIC_URL;
  }

  return <Forbidden />;
};

export {
  handleForbiddenPage,
  core,
  history,
};
