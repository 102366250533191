import { Box } from '@material-ui/core';
import { T } from '@shared-admin-kit/translation';
import SfxButton from '../../components/sfx-button';
import SfxTypography from '../../components/sfx-typography';
import Styled from './quickstart-tour.styles';

function TourTooltip({ activeStep, previousStep, steps, currentStep, nextStep }) {
  const lastStep = (steps || []).length > 0 && steps.length <= currentStep + 1;

  if (!activeStep) {
    return null;
  }

  return (
    <Styled.TourTooltip>
      <SfxTypography variant="text.md" color="link.stateless">
        {`${currentStep + 1} of ${steps.length}`}
      </SfxTypography>

      <Box ml={2}>
        {activeStep?.showPreviousBtn && currentStep > 0 && (
          <SfxButton
            onClick={previousStep}
            color="primary"
            size="small"
            //disabled={currentStep === 0}
          >
            <T i18nKey="BACK" defaultValue="Back" />
          </SfxButton>
        )}

        {activeStep?.showNextAndFinishBtn && (
          <SfxButton onClick={() => nextStep()} variant="contained" color="primary" size="small">
            {lastStep ? <T i18nKey="FINISH" defaultValue="Finish" /> : <T i18nKey="NEXT" defaultValue="Next" />}
          </SfxButton>
        )}
      </Box>
    </Styled.TourTooltip>
  );
}

export default TourTooltip;
