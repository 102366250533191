import { useBilling } from "@shared-admin-kit/billing";
import { useCompany } from "@shared-admin-kit/core";
import { Translate } from "@shared-admin-kit/translation";
import PropTypes from "prop-types";
import React from "react";
import {
  PLAN_NAME_CUSTOM_APPSUMO,
  PLANS,
} from "../../routes/plans/plans.constants";
import RouterLink from "../router-link";
import StyledAlert from "../styled-alert";

function NoPaymentDetailsAlert(props) {
  const { redirectPayments } = props;
  const { activeProject } = useCompany();
  const {
    billing,
    isBillingLoading,
    PAYMENT_METHOD,
    cardMethods,
    debitMethods,
    isPaymentMethodsLoading: isPaymentCardsLoading,
  } = useBilling();
  const activePlanName = activeProject?.meta?.plan_text;
  const cardPaymentMethodsCount = cardMethods.length || 0;
  const debitPaymentMethodsCount = debitMethods.length || 0;
  const billingPaymentMethod = billing?.method;
  // CARD method is by default so here we also check !billingPaymentMethod
  const requiresPaymentMethod = !billingPaymentMethod ||
    (
      billingPaymentMethod === PAYMENT_METHOD.CARD &&
      cardPaymentMethodsCount === 0
    ) ||
    (
      billingPaymentMethod === PAYMENT_METHOD.DEBIT &&
      debitPaymentMethodsCount === 0
    );

  const isPaidPlan = activePlanName &&
    [PLANS.FREE, PLANS.ENTERPRISE, PLAN_NAME_CUSTOM_APPSUMO].indexOf(
      activePlanName) === -1;

  const isVisiblePaymentDetailsWarning = !isPaymentCardsLoading &&
    !isBillingLoading &&
    requiresPaymentMethod &&
    isPaidPlan;

  if (!isVisiblePaymentDetailsWarning) {
    return null;
  }

  return (
    <StyledAlert severity="warning" borderRadius={0}>
      <div>
        <Translate i18nKey="YOU_HAVE_NOT_ENTERED_ANY_PAYMENT_DETAILS" defaultValue="You have not entered any payment details, your plan will not be active until you fix this. Please"/>&nbsp;
        <RouterLink to={redirectPayments}><Translate i18nKey="CLICK_HERE" defaultValue="click here"/></RouterLink>&nbsp;
        <Translate i18nKey="YOU_HAVE_NOT_ENTERED_ANY_PAYMENT_DETAILS.ENDING" defaultValue="to view your payment details"/>.
      </div>
    </StyledAlert>
  );
}

NoPaymentDetailsAlert.propTypes = {
  redirectPayments: PropTypes.string,
};

export default NoPaymentDetailsAlert;
