import { selectActiveGroupIds } from "../selectors/sidebar.selectors";

export const NAMESPACE = "sidebar";
export const SIDEBAR_SET_ACTIVE_GROUP_IDS = `${NAMESPACE}/SIDEBAR_SET_ACTIVE_GROUP_IDS`;

/**
 * @param {string[]} activeGroupIds
 * @returns {{type: string, activeGroupIds: string[]}}
 */
export const setActiveGroupIdsAction = (activeGroupIds) => ({
  type: SIDEBAR_SET_ACTIVE_GROUP_IDS,
  activeGroupIds,
});

/**
 * @param {string} groupId
 * @param {boolean} [deactivateOthers]
 */
export const activateGroupAction = (
  groupId, deactivateOthers = false) => (dispatch, getState) => {
  const currentState = getState();
  const activeGroupIds = deactivateOthers
    ? []
    : selectActiveGroupIds(currentState);
  const groupIds = new Set(activeGroupIds); // the Set structure removes duplicates
  groupIds.add(groupId);

  return dispatch(setActiveGroupIdsAction(Array.from(groupIds)));
};

/**
 * @param {string} groupId
 */
export const deactivateGroupAction = (groupId) => (dispatch, getState) => {
  const currentState = getState();
  const groupIds = selectActiveGroupIds(currentState)
    .filter(id => id !== groupId);

  return dispatch(setActiveGroupIdsAction(groupIds));
};
