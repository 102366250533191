export const TAB_SLUGS = {
  SUPPORT: "support",
  SUPPORT_HISTORY: "support-history",
  KNOWLEDGE_BASE: "knowledge-base",
};

export const TAB_NAMES = {
  SUPPORT: "Support",
  SUPPORT_HISTORY: "Support History",
  KNOWLEDGE_BASE: "Knowledge Base",
};

const createTab = (tabKey) => ({
  slug: TAB_SLUGS[tabKey],
  name: TAB_NAMES[tabKey],
});

export const TABS =
  Object
    .keys(TAB_SLUGS)
    .map((key) => createTab(key));
