import styled from 'styled-components';
import { Typography as TypographyMui } from '@material-ui/core';
import objectPath from 'object-path';

const VARIANTS = [
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button',
  'overline', 'srOnly', 'inherit',
];

const COLORS = [
  'initial', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error',
];

const Typography = styled(({ variant, color, ...rest}) =>
  <TypographyMui
    {...rest}
    variant={variant && VARIANTS.includes(variant) ? variant : undefined}
    color={color && COLORS.includes(color) ? color : undefined}
  />
)`
  // Custom SFX variants (from Figma) mixin
  ${props => objectPath.get(props.theme.sfx.textStyles, props.variant) ?? ''}

  // Custom SFX colors (from Figma) mixin
  ${props => {
    const color = objectPath.get(props.theme.sfx.palette, props.color)
    return props.color && color ? `color: ${color};` : '';
  }}
`;

const Styled = {
  Typography,
};

export default Styled;
