import Box from "@material-ui/core/Box";
import { useAuth } from "@shared-admin-kit/core";
import { Translate, useTranslation } from "@shared-admin-kit/translation";
import preval from 'preval.macro';
import React from "react";
import Loader from "../../../loader";
import { LayoutSidebarContainer } from "../default";
import SidebarList from "./components/sidebar-list";
import Token from "./components/token";
import { CopyRightsTypography } from "./sidebar.styled";

const { REACT_APP_SHOW_VERSION_DATETIME } = process.env;

function Sidebar() {
  const { t } = useTranslation();
  const { isUserLoading } = useAuth();

  function renderContent() {
    if (isUserLoading) {
      return <Loader container/>;
    }

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Token/>

        <SidebarList/>

        <CopyRightsTypography>
          <Box
            {...(
              REACT_APP_SHOW_VERSION_DATETIME === 'true'
                ? { title: preval`module.exports = new Date().toLocaleString("fr", {timeZone: "Europe/Paris"});` }
                : {}
            )}
          >
            <Translate i18nKey="VERSION" defaultValue="Version"/>: <Translate i18nKey="APP_VERSION" defaultValue="v20.09"/>
          </Box>

          <Box
            dangerouslySetInnerHTML={{
              __html: t('COPYRIGHT_HTML',
                '© 2016-2020 <a href="https://www.scaleflex.com/" target="_blank" rel="noopener noreferrer" class="no-text-decoration">Scaleflex</a>'),
            }}
          />
        </CopyRightsTypography>
      </Box>
    );
  }

  return (
    <LayoutSidebarContainer width={240}>
      {renderContent()}
    </LayoutSidebarContainer>
  );
}

export default Sidebar;
