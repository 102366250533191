import React, { useEffect, useState } from 'react';
import { useCompany } from "@shared-admin-kit/core";
import { Translate, useTranslation } from "@shared-admin-kit/translation";
import { Permissions } from "@shared-admin-kit/permissions";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { PERM_ID } from "../../../../permissions.constants";
import StyledTextField from "../../../../components/styled-text-field";
// import CreateCompanyDialog from '../create-company-dialog';
import { StyledEditIconButton } from '../../company-autocomplete.styled';
import { StyledAutocomplete } from './multiple-mode.styled';

function MultipleMode(props) {
  const {
    renderInputProps = {},
    enqueueSnackbar,
    companies,
    onEditMode,
    ...rest
  } = props;
  const { t } = useTranslation();
  const { selectCompany, activeCompany } = useCompany();
  const [companyValue, setCompanyValue] = useState(null);
  /* const [searchQuery, setSearchQuery] = useState('');
   const [searchQueryError, setSearchQueryError] = useState();
   const [isDialogOpen, setIsDialogOpen] = useState(false);*/

  useEffect(() => {
    const activeCompanyUuid = activeCompany?.company?.uuid;
    const company = activeCompanyUuid ? companies.find(
      c => c.uuid === activeCompanyUuid) : null;

    setCompanyValue(company);
  }, [activeCompany, companies]);

  function handleCompanyChange(event, newValue) {
    setCompanyValue(newValue);
    selectCompany(newValue.uuid);
  }

  function handleDialogOpen(event) {
    event.preventDefault();
    event.stopPropagation();
    /* setIsDialogOpen(true);*/

    // Tmp message
    enqueueSnackbar(t('CREATE_COMPANY.DESC',
        'You are not allowed to create additional companies. Contact us if you need to manage multiple companies in your Admin Console.'),
      { variant: 'warning', autoHideDuration: 10000 });
  }

  /* function handleDialogClose() {
     setIsDialogOpen(false);
   }*/

  /*  function validateSearchQuery(q) {
      let error;
      if (q.trim() === '') {
        error = t('COMPANY_NAME_IS_REQUIRED', 'Company name is required');
      }
      else if (companies.map(c => c.name).includes(q)) {
        error = t('COMPANY_ALREADY_EXISTS', 'Company already exists');
      }

      setSearchQueryError(error);
    }*/

  function onSearchQueryChange({ target: { value: q } }) {
    /* validateSearchQuery(q);
     setSearchQuery(q);*/
  }

  return (
    <>
      <StyledAutocomplete
        value={companyValue}
        onChange={handleCompanyChange}
        options={companies || []}
        getOptionLabel={company => company.name}
        getOptionSelected={(company, value) => company?.uuid === value?.uuid}
        id="company-autocomplete"
        disableClearable
        renderInput={params => {
          function renderEndAdornment() {
            const editIcon =
              <StyledEditIconButton onClick={onEditMode} key="edit-mode"/>;

            if (!params || !params.InputProps ||
              !params.InputProps.endAdornment) {
              return editIcon;
            }

            return React.cloneElement(
              params.InputProps.endAdornment,
              {},
              [
                editIcon,
                ...((params.InputProps.endAdornment.props || {}).children ||
                  []),
              ],
            );
          }

          return (
            <StyledTextField
              {...params}
              // label="company"
              onChange={onSearchQueryChange}
              fullWidth
              placeholder={t('COMPANY', "company")}
              InputProps={{
                ...params.InputProps,
                endAdornment: renderEndAdornment(),
              }}
              {...(renderInputProps || {})}
            />
          );
        }}
        noOptionsText={
          (
            <Box textAlign="center">
              <Typography variant="body1" color="textSecondary">
                {t('NO_COMPANIES', 'No companies')}
              </Typography>

              <Permissions requiredPermissionIds={[PERM_ID.CRUD_COMPANY]}>
                <Button size="small" variant="contained" color="primary" onPointerDown={handleDialogOpen}>
                  <Translate i18nKey="CREATE_COMPANY" defaultValue="Create company"/>
                </Button>
              </Permissions>
            </Box>
          )
        }
        {...rest}
      />

      {/* <CreateCompanyDialog
        t={t}
        open={isDialogOpen}
        onClose={handleDialogClose}
        name={searchQuery}
        nameError={searchQueryError}
      /> */}
    </>
  );
}

export default MultipleMode;
