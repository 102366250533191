import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const SidebarLabel = styled(
  ({ pt, padding, fullWidth, clickable, ...rest }) => (
    <Typography
      component="div"
      variant="button"
      color="secondary"
      {...rest}
    />
  ),
)(({
  theme,
  pt = 1,
  pr = 2,
  pb = 0,
  pl = 1,
  padding,
  fullWidth = false,
  fontSize = 14,
  clickable = false,
}) => ({
  fontSize: fontSize,
  padding: typeof padding !== 'undefined' ? padding : theme.spacing(pt, pr, pb,
    pl),
  textTransform: 'uppercase',
  ...(fullWidth ? { width: '100%' } : {}),
  ...(clickable ? {
    cursor: 'pointer',
    ':hover': { backgroundColor: theme.palette.action.hover },
  } : {}),
}));

export default SidebarLabel;
