import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import styled from 'styled-components';

export const Container = styled(
  ({ width, ...rest }) => <div {...rest} />,
)(({ theme, width }) => ({
  width: width || 310,
  margin: theme.spacing(0, 1),
}));

export const StyledEditIconButton = styled(
  ({ ...rest }) => (
    <IconButton size="small" {...rest}>
      <Edit fontSize="small"/>
    </IconButton>
  ),
)(() => ({
  display: 'none',
  
  // '.Mui-focused &': { display: 'inline-block' },
  '.MuiAutocomplete-root:hover &': { display: 'inline-block' },
  '.MuiInputBase-root:hover & ': { display: 'inline-block' },
}));
