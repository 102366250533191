import { useState, useMemo } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useCompany } from "@shared-admin-kit/core";
import { useTranslation } from "@shared-admin-kit/translation";
import CopyToClipboardButton from "../../../../../../../copy-to-clipboard-button";
import StyledTextField from "../../../../../../../styled-text-field";
import * as Styled from "./token-dropdown.styled";

function TokenDropdown() {
  const [isTokenMenuOpen, setIsTokenMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  const { activeProject, projects, selectProject } = useCompany();
  // "desc" is missing in project API but exists in projects so we need this activeProjectDetails. Remove when project API returns "desc"
  const activeProjectDetails = useMemo(() => projects.find(p => p.uuid === activeProject?.uuid), [projects, activeProject]);

  const handleProjectSelect = (e, newValue) => {
    if (!newValue?.uuid || newValue.uuid === activeProject.uuid) {
      setIsTokenMenuOpen(false);
      return;
    }

    selectProject(newValue.uuid);
  };

  const renderTokenSelector = () => {
    const _renderOption = (project) => {
      return (
        <Styled.StyledMenuItem>
          {
            !!project.desc?.trim() &&
            <>
              <Typography
                variant="body2"
                component="span"
                className="desc"
              >
                {project.desc}
              </Typography>

              <Box
                component="span"
                ml={1}
                mr={1}
              >
                -
              </Box>
            </>
          }

          <Typography
            variant="body2"
            component="span"
            className="token"
          >
            {project.name}
          </Typography>
        </Styled.StyledMenuItem>
      );
    };
    const _renderStartAdornment = () => {
      return (
        <InputAdornment
          position="start"
        >
          <SearchIcon/>
        </InputAdornment>
      );
    };
    const _renderEndAdornment = () => {
      const _onClick = () => {
        setInputValue("");
      };

      return (
        !!inputValue
          ? <InputAdornment
            position="start"
          >
            <IconButton
              size="small"
              onClick={_onClick}
            >
              <ClearIcon/>
            </IconButton>
          </InputAdornment>
          : undefined
      );
    };

    return (
      <Styled.StyledDropDownMenuPaper>
        <Autocomplete
          open={isTokenMenuOpen}
          size="small"
          style={{ width: 300 }}
          fullWidth
          getOptionLabel={(project) => project.desc ?
            `${project.desc} - ${project.name}`
            : project.name
          }
          onChange={handleProjectSelect}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => {
            return <StyledTextField
              {...params}
              variant="outlined"
              placeholder={t("SEARCH", "Search")}
              InputProps={{
                ...params.InputProps,
                startAdornment: _renderStartAdornment(),
                endAdornment: _renderEndAdornment(),
                style: {
                  paddingRight: 0,
                },
              }}
              inputProps={{
                ...(params?.inputProps || {}),
                autoComplete: 'new-password' // disable autocomplete
              }}
            />;
          }
          }
          options={projects}
          renderOption={(project) => {
            return _renderOption(project);
          }}
          getOptionSelected={(option) => option?.uuid === activeProject?.uuid}
          disablePortal={true}
        />
      </Styled.StyledDropDownMenuPaper>
    );
  };

  const _renderTokenField = () => {
    const _onClick = () => {
      setIsTokenMenuOpen(!isTokenMenuOpen);
    };

    return (
      <Grid container spacing={1}>
        <Styled.StyledTokenGridCell item xs={8}>
          {
            activeProjectDetails?.desc?.trim() &&
            <Typography
              variant="body2"
              component="span"
              className="desc"
            >
              {activeProjectDetails.desc}
            </Typography>
          }

          <Typography
            variant="body2"
            component="span"
            className="token"
          >
            {activeProject?.name || ""}
          </Typography>
        </Styled.StyledTokenGridCell>

        <Styled.StyledGridCell item xs={2}>
          {
            projects.length > 1 &&
            <IconButton
              size="small"
              onClick={_onClick}
            >
              {isTokenMenuOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
            </IconButton>
          }
        </Styled.StyledGridCell>

        <Styled.StyledGridCell item xs={2}>
          <Styled.StyledCopyTokenBox flexShrink={0}>
            <CopyToClipboardButton text={activeProject.name}/>
          </Styled.StyledCopyTokenBox>
        </Styled.StyledGridCell>
      </Grid>
    );
  };

  return (
    <Styled.StyledDropDownBox>
      {_renderTokenField()}

      {isTokenMenuOpen && renderTokenSelector()}
    </Styled.StyledDropDownBox>
  );
}

export default TokenDropdown;
