import * as projectSelectors from "../project.selectors";

export const selectAllAliasesKeys = (state) => {
  return [
    ...projectSelectors.selectProjectAliasesKeys(state),
    ...selectStorageAliases(state),
  ];
};

export const selectStorageAliases = (state) => {
  const _storage_providers = (projectSelectors.selectProjectData(state) ||
    {}).storage_providers;

  if (typeof _storage_providers !== "object") {
    throw  new Error("Missing property storage_providers");
  }

  const _aliases = [];

  for (const storageProviderKey of Object.keys(_storage_providers)) {
    if (!Array.isArray(_storage_providers[storageProviderKey])) {continue;}

    _aliases.push(
      ..._storage_providers[storageProviderKey]
        .map(sp => sp.alias)
        .filter(a => typeof a === "string"),
    );
  }

  return _aliases;
};
