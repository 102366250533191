import { PENDING_PLAN_STATUS } from '@shared-admin-kit/types/lib/plans';


function showProcessPendingPlanMsg(status = null, pendingPlanName, showMessage, t) {
  switch (status) {
    case PENDING_PLAN_STATUS.BILLING_REDIRECT:
      showMessage(
        t('NO_REQUIRED_BILLING_DATA_TO_SELECT_PLAN', `No required billing data to select plan "${pendingPlanName}"!`),
      );
      break;

    case PENDING_PLAN_STATUS.NO_CREDIT_CARD_PAYMENT_REDIRECT:
      showMessage(t('PLEASE_ENTER_YOUR_CREDIT_CARD', 'Please enter your credit card to finalize plan selection.'));
      break;

    case PENDING_PLAN_STATUS.NO_DEBIT_CARD_PAYMENT_REDIRECT:
      showMessage(t('PLEASE_ENTER_YOUR_CREDIT_CARD', 'Please enter your debit card to finalize plan selection.'));
      break;

    case PENDING_PLAN_STATUS.PLAN_SAVED_SUCCESSFULLY:
      showMessage(`${t('BILLING.PENDING_PLAN_SELECTION_1', 'Your plan')} ${pendingPlanName} ${
        t('BILLING.PENDING_PLAN_SELECTION_2', 'has been selected')}`);
      break;

    default:
  }
}

export default showProcessPendingPlanMsg;
export { showProcessPendingPlanMsg };
