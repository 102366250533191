import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from "../../../../../dropdown";

export const UserDropdownMenuItem = styled(MenuItem)`
  letter-spacing: 0;
  font-size: inherit;
`;

export const StyledDropdown = styled(Dropdown)({
  color: 'white',
});
