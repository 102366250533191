import React from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export const StyledList = styled(List)({
  flexGrow: 1,
  overflowY: 'auto',
  paddingTop: 0,
});

export const StyledListItem = styled(
  React.forwardRef((props, ref) => <ListItem button {...props} ref={ref}/>),
)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: theme.spacing(3),
  borderLeft: '2px solid transparent',

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },

  '&.active': {
    backgroundColor: theme.palette.action.selected,
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
  },

  '.MuiTypography-body1': {
    fontSize: 14,
  },

  '.MuiListItemIcon-root': {
    minWidth: 20,
    marginRight: 4,
  },

  '.MuiListItemText-root': {
    height: 20,
  },

  '.MuiSvgIcon-root': {
    width: 16,
    height: 16,
    marginTop: -2,
  },
}));
