import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loader({ container, containerProps = {}, ...rest }) {
  if (!container) {
    return <CircularProgress size={22} color="inherit" {...rest} />
  }

  return (
    <Container component={Box} maxWidth="sm" textAlign="center" {...(containerProps || {})}>
      <CircularProgress {...rest} />
    </Container>
  )
}
