import React from 'react';
import { Link } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';

function RouterLink(props) {
  return (
    <MuiLink
      component={Link}
      to="/"
      {...props}
    />
  )
}

export default RouterLink;