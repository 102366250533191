import { selectUserEmail as selectCurrentUserEmail } from "@shared-admin-kit/core/lib/modules/auth/auth.selectors";
import { refreshCurrentCompanyAction } from "@shared-admin-kit/core/lib/modules/company/company.actions";
import { selectActivePlanName } from "@shared-admin-kit/core/lib/modules/company/company.selectors";
import {
  selectActiveCompany,
  selectActiveProject,
  selectActiveProjectUuid,
} from "@shared-admin-kit/core/lib/modules/company/company.selectors";
import { ProjectDataHelper, ProjectSettingsMapper } from "../../utils";
import { sendContactFormData } from "../contact-us/contact-us.service";
import { selectProjectData } from "./project.selectors";
import {
  getProjectDataRequest,
  saveProjectDataRequest,
  saveProjectDescriptionRequest,
} from "./project.service";
import { SETTINGS } from "./settings/settings.config";

export const NAMESPACE = "project";
export const PROJECT_FETCH_DATA = `${NAMESPACE}/PROJECT_FETCH_DATA`;
export const PROJECT_SET_DATA = `${NAMESPACE}/PROJECT_SET_DATA`;
export const PROJECT_SET_IS_LOADING = `${NAMESPACE}/PROJECT_SET_IS_LOADING`;
export const PROJECT_SET_IS_SAVING_DATA = `${NAMESPACE}/PROJECT_SET_IS_SAVING_DATA`;
export const PROJECT_SET_ACTIVE_TAB_SLUG = `${NAMESPACE}/PROJECT_SET_ACTIVE_TAB_SLUG`;

export const setActiveTabSlugAction = (activeTabSlug) => ({
  type: PROJECT_SET_ACTIVE_TAB_SLUG,
  activeTabSlug,
});

const setIsLoadingAction = (isLoading) => ({
  type: PROJECT_SET_IS_LOADING,
  isLoading,
});

export const projectSetDataAction = (data) => ({
  type: PROJECT_SET_DATA,
  data,
});

export const setIsSavingProjectDataAction = (isSavingProjectData) => ({
  type: PROJECT_SET_IS_SAVING_DATA,
  isSavingProjectData,
});

const projectFetchDataAction = () => ({
  type: PROJECT_FETCH_DATA,
});

export const fetchProjectDataAction = (project_uuid) => (
  dispatch, getState) => {
  dispatch(setIsLoadingAction(true));
  dispatch(projectFetchDataAction());

  const state = getState();
  const _project_uuid = project_uuid || selectActiveProjectUuid(state);

  return getProjectDataRequest(_project_uuid)
    .then(({ data: { data } } = {}) => {
      const transformedData = new ProjectDataHelper(data)
        .transformForFrontend();  // optimize data for frontend

      return dispatch(projectSetDataAction(transformedData));
    })
    .catch(err => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch(setIsLoadingAction(false));
    });
};

export const createZendeskTicketAction = (dataForApi) => (
  dispatch, getState) => {
  const state = getState();
  /**@type {string[]}*/
  const cnames = ProjectSettingsMapper.get(dataForApi, SETTINGS.CNAMES);
  if (!cnames.length) {return; }

  const activePlanName = selectActivePlanName(state);

  /**@type {TRANSFORMED_CNAME[]}*/
  const transformedCNAMES = ProjectSettingsMapper.get(selectProjectData(state),
    SETTINGS.CNAMES);

  if (ProjectDataHelper.isUsingCNAMESDefaults(transformedCNAMES) ||
    transformedCNAMES.every(c => c.isNew === false)) {return;}

  const {
    company: {
      name: companyName,
      uuid: companyUuid,
    },
  } = selectActiveCompany(
    state);
  const email = selectCurrentUserEmail(state);
  const { name: activeProjectName } = selectActiveProject(state);

  const _createText = () => {
    let _text = 'Requesting CNAME/s:\n'
      +
      cnames
        .map((cname, cnameIndex) => `CNAME_${cnameIndex + 1}: ${cname}\n`)
        .join("");

    _text += `\n plan: ${activePlanName}`;
    return _text;
  };

  /**@type {Contact}*/
  const contact = {
    title: `[CNAME REQUEST] - ${companyName} - ${companyUuid} - token: ${activeProjectName}`,
    email: email,
    text: _createText(),
  };

  return sendContactFormData(contact)
    .catch(err => {
      return Promise.reject(err);
    });
};

export const saveProjectDataAction = (project_uuid, data) => (
  dispatch, getState) => {
  dispatch(setIsLoadingAction(true));
  dispatch(setIsSavingProjectDataAction(true));

  const state = getState();
  const _project_uuid = project_uuid || selectActiveProjectUuid(state);
  const _data = data || selectProjectData(state);

  const dataForApi = new ProjectDataHelper(_data)
    .transformForAPI(); // optimize data for api

  /*  if (!isQAMode) {
      dispatch(createZendeskTicketAction(dataForApi));
    }*/

  return saveProjectDataRequest(_project_uuid, dataForApi)
    .then(({ data: { new_project_data } = {} }) => {
      const transformedData = new ProjectDataHelper(new_project_data)
        .transformForFrontend(); // optimize data for frontend
      return dispatch(projectSetDataAction(transformedData));
    })
    .catch(err => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch(setIsLoadingAction(false));
      dispatch(setIsSavingProjectDataAction(false));
    });
};

/**
 * @param {String} project_uuid
 * @param {String} name
 * @param {String} desc
 * @returns {Promise}
 */
export const saveProjectDescriptionAction = (project_uuid, name, desc) => (
  dispatch, getState) => {
  dispatch(setIsLoadingAction(true));
  dispatch(setIsSavingProjectDataAction(true));

  const state = getState();
  const _project_uuid = project_uuid || selectActiveProjectUuid(state);

  return saveProjectDescriptionRequest(_project_uuid, name, desc)
    .then(({ data = {} }) => {
      dispatch(refreshCurrentCompanyAction());
      return Promise.resolve(data);
    })
    .catch(err => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch(setIsLoadingAction(false));
    });
};
