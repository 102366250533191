import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  font-size: 18px !important;
`;

function Dropdown({ id, children, menuItems, component: Component, withoutArrow, forceClose, onOpen, ...rest }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (forceClose) {
      handleClose();
    }
  }, [forceClose]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    if (onOpen) { onOpen(); }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <Component
        {...rest}
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {children}
        {!withoutArrow && (<StyledKeyboardArrowDownIcon />)}
      </Component>

      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {React.Children.map(menuItems, (child, index) =>
          React.cloneElement(
            child,
            {
              key: index,
              ...child.props,
              onClick: event => {
                handleClose();

                if (typeof child.props.onClick === 'function') {
                  child.props.onClick(event);
                }
              },
            })
        )}
      </Menu>
    </Fragment>
  )
}

Dropdown.defaultProps = {
  withoutArrow: false,
  forceClose: false,
  component: Button,
}

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  withoutArrow: PropTypes.bool,
  forceClose: PropTypes.bool,
  component: PropTypes.elementType,
  onOpen: PropTypes.func,
}

export default Dropdown;