import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import { StyledIcon } from "./copy-to-clipboard-button.styled";

function CopyToClipboardButton({ text, timer, scale }) {
  const [copied, setCopied] = useState(false);

  return (
    <>
      {/*<link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
        id="font-awesome"
        crossOrigin="anonymous"
      />*/}

      <span
        //style={{
        //  transform: `scale(${scale})`,
        //}}
      >
      {
        copied
          ? <IconButton aria-label="copy" size="small">
            <StyledIcon />
          </IconButton>
          : <CopyToClipboard
            text={text || ''}
            onCopy={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), timer);
            }}
          >
            <IconButton aria-label="copy" size="small">
              <StyledIcon />
            </IconButton>
          </CopyToClipboard>
      }
      </span>
    </>
  );
}

CopyToClipboardButton.defaultProps = {
  timer: 2000,
  scale: 1,
};

CopyToClipboardButton.propTypes = {
  text: PropTypes.string.isRequired,
  timer: PropTypes.number,
  scale: PropTypes.number,
};

export default CopyToClipboardButton;