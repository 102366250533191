import cloneDeep from "lodash.clonedeep";
import queryString from "query-string";
import { v4 as uuid } from 'uuid';
import {
  INTERNAL_SETTINGS_CONFIG,
  SETTINGS,
  VIRTUAL_SETTINGS,
} from "../routes/project/settings/settings.config";
import { NumberHelper } from "./number-helper";
import { ProjectSettingsMapper } from "./project-settings-mapper";

export class ProjectDataHelper {
  static subDomainRegex = /^([a-z0-9-]+\.){2,}[a-z]+$/;

  constructor(data) {
    this.data = cloneDeep(data);
  }

  /**
   * @typedef TRANSFORMED_CNAME
   * @type  {Object} CNAME
   * @property {string} uuid
   * @property {string} value
   * @property {boolean} isNew
   */

  /**
   * @returns {{value: string, key: string}}
   */
  static createAliasHeader(key = "", value = "") {
    return { key, value };
  }

  /**
   * @param {String} domain
   * @returns {string}
   */
  static createAllowedDomain(domain = "") {
    return domain || "";
  }

  /**
   * @param {String} cname
   * @param {boolean} isNew
   * @returns {TRANSFORMED_CNAME}
   */
  static createCNAME(cname = "", isNew = true) {
    return {
      uuid: uuid(),
      value: cname || "",
      isNew,
    };
  }

  /**
   * @param {string} key
   * @param {string} value
   * @returns {RequestHeader}
   */
  static createRequestHeader(key = "", value = "") {
    return { key, value };
  };

  /**
   * @param {TRANSFORMED_CNAME[] || string[]} cnames
   * @returns {boolean}
   */
  static isUsingCNAMESDefaults(cnames) {
    return cnames.length === 1 && typeof cnames[0] !==
      "undefined" && (cnames[0].value === "" || cnames[0] === "");
  };

  /**
   * @param {ProjectSettingsAlias[]} aliases
   * @returns {boolean}
   */
  static isUsingAliasesDefaults(aliases) {
    return aliases.length === 1 && !aliases[0].key.trim() &&
      !aliases[0].val.trim() && !aliases[0].hint.trim();
  };

  /**
   * @param {String} name
   * @param {String} desc
   * @param {String} params
   * @returns {ProjectSettingsPreset}
   */
  static createPreset(name = "", desc = "", params = "") {
    return { name, desc, params };
  }

  /**
   * @param {String} match
   * @param {String} params
   * @param {String} comment
   * @returns {ProjectSettingsRule}
   */
  static createRule(match = "", params = "", comment = "") {
    return { match, comment, params };
  }

  /**
   * @param initialHeader
   * @returns {ProjectDataHelper}
   */
  convertAliasesHeadersToArray(initialHeader = ProjectDataHelper.createAliasHeader()) {
    const aliases = ProjectSettingsMapper.get(this.data,
      SETTINGS.ALIASES);

    for (const alias of aliases) {
      if (!alias.headers || Array.isArray(alias.headers)) {continue;}

      const _headers = Object
        .keys(alias.headers)
        .map(
          (key) => ProjectDataHelper.createAliasHeader(key,
            alias.headers[key]));

      if (initialHeader && _headers.length === 0) {
        _headers.push(initialHeader);
      }

      alias.headers = _headers;
    }

    return this;
  };

  /**
   * @returns {ProjectDataHelper}
   */
  convertAliasesHeadersToObject() {
    const aliases = ProjectSettingsMapper.get(this.data,
      SETTINGS.ALIASES);

    for (const alias of aliases) {
      if (!alias.headers || !Array.isArray(alias.headers)) { continue;}

      let _headers = {};

      for (const header of alias.headers) {
        _headers = { ..._headers, [header.key]: header.value };
      }
      alias.headers = _headers;
    }

    return this;
  };

  /**
   * @param initialPreset
   * @returns {ProjectDataHelper}
   */
  transformPresetsForFrontend(initialPreset = ProjectDataHelper.createPreset()) {
    /**@type {ProjectSettingsPreset[]}*/
    let presets = ProjectSettingsMapper.get(this.data, SETTINGS.PRESETS);

    ProjectSettingsMapper.set(this.data, SETTINGS.PRESETS,
      presets && presets.length > 0
        ? presets.map(preset => ProjectDataHelper.createPreset(
          preset.name || preset.match,
          preset.desc,
          preset.params,
        ))
        : []);

    presets = ProjectSettingsMapper.get(this.data, SETTINGS.PRESETS);

    if (initialPreset && presets.length === 0) {
      presets.push(initialPreset);
    }

    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  transformPresetsForAPI() {
    /**@type {ProjectSettingsPreset[]}*/
    const presets = ProjectSettingsMapper.get(this.data, SETTINGS.PRESETS)
      .map((preset) => {
        preset.name = preset.name.trim();
        return preset;
      });

    ProjectSettingsMapper.set(this.data, SETTINGS.PRESETS, presets);

    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  transformRulesForAPI() {
    /**@type {ProjectSettingsRule[]}*/
    const rules = ProjectSettingsMapper.get(this.data, SETTINGS.RULES)
      .map((rule) => {
        rule.match = rule.match.trim();
        return rule;
      });

    ProjectSettingsMapper.set(this.data, SETTINGS.RULES, rules);

    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  filterAllowedDomainsForAPI() {
    ProjectSettingsMapper.set(this.data, SETTINGS.ALLOWED_DOMAINS,
      ProjectSettingsMapper.get(
        this.data,
        SETTINGS.ALLOWED_DOMAINS,
      ).filter(domain => !!domain),
    );

    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  transformAllowedDomainsForAPI() {
    ProjectSettingsMapper.set(this.data, SETTINGS.ALLOWED_DOMAINS,
      ProjectSettingsMapper.get(
        this.data,
        SETTINGS.ALLOWED_DOMAINS,
      ).map(domain => domain.trim()),
    );

    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  filterCNAMESForAPI() {
    /**@type {string[]} */
    let cnames = ProjectSettingsMapper.get(this.data, SETTINGS.CNAMES);

    if (!(cnames.length === 1 && cnames[0] === "")) {
      cnames = cnames.filter(
        (cname) => {
          return !!cname;

          // return ProjectDataHelper.subDomainRegex.test(cname); // checking if it's subDomain
        });
    }

    ProjectSettingsMapper.set(this.data, SETTINGS.CNAMES, cnames);
    return this;
  }

  /**
   * @param {String} key
   * @param {String} val
   * @param {String} hint
   * @returns {{val: string, hint: string, key: string}}
   */
  static createAlias(key = "", val = "", hint = "") {
    return { key, val, hint };
  };

  /**
   * @param {Object} initialAlias
   * @returns {ProjectDataHelper}
   */
  transformAliasesForFrontend(initialAlias = ProjectDataHelper.createAlias()) {
    let aliases = ProjectSettingsMapper.get(this.data, SETTINGS.ALIASES);

    if (!Array.isArray(aliases)) {
      ProjectSettingsMapper.set(this.data, SETTINGS.ALIASES, []);
    }

    aliases = ProjectSettingsMapper.get(this.data, SETTINGS.ALIASES);

    if (initialAlias && aliases.length === 0) {
      ProjectSettingsMapper.get(this.data, SETTINGS.ALIASES).push(initialAlias);
    }

    return this;
  }

  /**
   * @param initialRule
   * @returns {ProjectDataHelper}
   */
  transformRulesForFrontend(initialRule = ProjectDataHelper.createRule()) {
    /**@type {ProjectSettingsRule[]}*/
    let rules = ProjectSettingsMapper.get(this.data, SETTINGS.RULES);

    ProjectSettingsMapper.set(this.data, SETTINGS.RULES,
      rules && rules.length > 0
        ? (
          rules
            .filter(rule => rule?.match || rule?.name)
            .map(rule => ProjectDataHelper.createRule(
              rule.match || rule.name,
              rule.params,
              rule.comment,
            ))
        )
        : []);

    rules = ProjectSettingsMapper.get(this.data, SETTINGS.RULES);

    if (initialRule && rules.length === 0) {
      rules.push(initialRule);
    }

    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  filterAliasesForAPI() {
    ProjectSettingsMapper.set(this.data, SETTINGS.ALIASES,
      ProjectSettingsMapper.get(this.data, SETTINGS.ALIASES)
        .filter(({ key, val }) => key && val));
    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  filterPresetsForAPI() {
    ProjectSettingsMapper.set(this.data, SETTINGS.PRESETS,
      ProjectSettingsMapper.get(this.data, SETTINGS.PRESETS)
        .filter(({ match, name }) => {
          return !!name || !!match;
        }));

    return this;
  }

  /**
   * @param  initialDomain
   * @returns {ProjectDataHelper}
   */
  transformAllowedDomainsForFrontend(initialDomain = ProjectDataHelper.createAllowedDomain()) {
    let allowed_domains = ProjectSettingsMapper.get(this.data,
      SETTINGS.ALLOWED_DOMAINS);

    if (!Array.isArray(allowed_domains) || allowed_domains.length === 0) {
      ProjectSettingsMapper.set(this.data, SETTINGS.ALLOWED_DOMAINS, []);
    }

    allowed_domains = ProjectSettingsMapper.get(this.data,
      SETTINGS.ALLOWED_DOMAINS);

    if (initialDomain !== undefined && initialDomain !== null &&
      allowed_domains.length === 0) {
      allowed_domains.push(initialDomain);
    }

    return this;
  }

  /**
   * @param {TRANSFORMED_CNAME || string} cname
   * @param {boolean} isNew
   * @returns {TRANSFORMED_CNAME}
   * @private
   */
  _transformCNAMEForFrontend(cname, isNew) {
    if (typeof cname === "object") {
      if (typeof cname.value === "object") {
        cname.value = cname.value.value;//fix for broken setting
        this._transformCNAMEForFrontend(cname, isNew);
      }

      return cname;
    }

    return ProjectDataHelper.createCNAME(cname, isNew);
  }

  /**
   * @param {TRANSFORMED_CNAME || string} cname
   * @returns {string}
   */
  _transformCNAMEForAPI(cname) {
    return this._transformCNAMEForFrontend(cname, false).value;
  }

  /**
   * @param  initialCNAME
   * @returns {ProjectDataHelper}
   */
  transformCNAMESForFrontend(initialCNAME = ProjectDataHelper.createCNAME()) {
    let cnames = ProjectSettingsMapper.get(this.data, SETTINGS.CNAMES);

    if (!Array.isArray(cnames)) {
      cnames = [];
    }
    else {
      if (ProjectDataHelper.isUsingCNAMESDefaults(cnames)) {
        cnames[0] = this._transformCNAMEForFrontend(cnames[0], true);
      }
      else {
        cnames = cnames.map(c => this._transformCNAMEForFrontend(c, false));
      }
    }
    ProjectSettingsMapper.set(this.data, SETTINGS.CNAMES, cnames);

    if (initialCNAME !== undefined && initialCNAME !== null &&
      cnames.length === 0) {
      cnames.push(initialCNAME);
    }

    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  transformCNAMESForAPI() {
    let cnames = ProjectSettingsMapper.get(this.data, SETTINGS.CNAMES)
      .map(c => this._transformCNAMEForAPI(c));

    ProjectSettingsMapper.set(this.data, SETTINGS.CNAMES, cnames);

    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  transformRequestHeadersForFrontend() {
    /**@type {Object<string,string>}*/
    const _request_headers = ProjectSettingsMapper.get(this.data,
      SETTINGS.ORIGIN_BEHAVIOUR.REQUEST_HEADERS, {});
    const _transformedRequestHeaders = Object
      .keys(_request_headers)
      .map(headerKey => ProjectDataHelper.createRequestHeader(
        headerKey,
        _request_headers[headerKey],
      ));

    ProjectSettingsMapper.set(
      this.data,
      SETTINGS.ORIGIN_BEHAVIOUR.REQUEST_HEADERS,
      _transformedRequestHeaders,
    );

    return this;
  }

  /**
   * @returns {ProjectDataHelper}
   */
  transformRequestHeadersForAPI() {
    /**@type {RequestHeader[]}*/
    const _request_headers = ProjectSettingsMapper.get(this.data,
      SETTINGS.ORIGIN_BEHAVIOUR.REQUEST_HEADERS);
    const _transformedRequestHeaders = {};
    for (const requestHeader of _request_headers) {
      _transformedRequestHeaders[requestHeader.key] = requestHeader.value;
    }

    ProjectSettingsMapper.set(
      this.data,
      SETTINGS.ORIGIN_BEHAVIOUR.REQUEST_HEADERS,
      _transformedRequestHeaders,
    );

    return this;
  }

  /**
   * @param {Object} value
   * @returns {String ||any}
   */
  static parseToQueryString(value) {
    return typeof value === "object" ? queryString.stringify(value) : value;
  };

  _transformPresetParams(stringifyParams = false) {
    const presets = ProjectSettingsMapper.get(this.data, SETTINGS.PRESETS)
      .map((preset) => {
        preset.params = stringifyParams
          ? ProjectDataHelper.parseToQueryString(preset.params)
          : queryString.parse(preset.params);
        return preset;
      });

    ProjectSettingsMapper.set(this.data, SETTINGS.PRESETS, presets);

    return this;
  };

  transformPresetParamsForFrontend() {
    this._transformPresetParams(true);

    return this;
  }

  transformPresetParamsForAPI() {
    this._transformPresetParams(false);

    return this;
  }

  /**
   * @description sets default values based on predefined JSON Schema (using it to ensure initial object structure)
   * @returns {ProjectDataHelper}
   */
  ensureJSONSchema() {
    const predefinedSettingsIds = Object
      .keys(INTERNAL_SETTINGS_CONFIG)
      .filter(settingId => !VIRTUAL_SETTINGS.includes(settingId));

    for (const settingId of predefinedSettingsIds) {
      ProjectSettingsMapper.set(
        this.data,
        settingId,
        ProjectSettingsMapper.get(this.data, settingId),
      );
    }

    return this;
  }

  /**
   * @description ensures that origin_process.jpg_icc.if_big_go_srgb.trigger_size is of correct type
   * @returns {ProjectDataHelper}
   */
  ensureType_origin_process__jpg_icc__if_big_go_srgb__trigger_size() {
    const _value = NumberHelper.parseInteger(
      ProjectSettingsMapper.get(this.data,
        SETTINGS.ORIGIN_PROCESS.JPG_ICC.IF_BIG_GO_SRGB.TRIGGER_SIZE),
    );

    ProjectSettingsMapper.set(
      this.data,
      SETTINGS.ORIGIN_PROCESS.JPG_ICC.IF_BIG_GO_SRGB.TRIGGER_SIZE,
      _value,
    );

    return this;
  }

  /**
   * @description ensures that cache__output_s_max_age is of correct type
   * @returns {ProjectDataHelper}
   */
  ensureType_cache__output_s_max_age() {
    const _value = NumberHelper.parseInteger(
      ProjectSettingsMapper.get(this.data,
        SETTINGS.CACHE.OUTPUT_S_MAX_AGE),
    );

    ProjectSettingsMapper.set(
      this.data,
      SETTINGS.CACHE.OUTPUT_S_MAX_AGE,
      _value,
    );

    return this;
  }

  /**
   * @description ensures that cache__output_max_age is of correct type
   * @returns {ProjectDataHelper}
   */
  ensureType_cache__output_max_age() {
    const _value = NumberHelper.parseInteger(
      ProjectSettingsMapper.get(this.data,
        SETTINGS.CACHE.OUTPUT_MAX_AGE),
    );

    ProjectSettingsMapper.set(
      this.data,
      SETTINGS.CACHE.OUTPUT_MAX_AGE,
      _value,
    );

    return this;
  }

  /**
   * @description ensures that jpeg__quality is of correct type
   * @returns {ProjectDataHelper}
   */
  ensureType_jpeg__quality() {
    const _value = NumberHelper.parseInteger(
      ProjectSettingsMapper.get(this.data,
        SETTINGS.JPEG.QUALITY),
    );

    ProjectSettingsMapper.set(
      this.data,
      SETTINGS.JPEG.QUALITY,
      _value,
    );

    return this;
  }

  /**
   * @description ensures that png__quality is of correct type
   * @returns {ProjectDataHelper}
   */
  ensureType_png__quality() {
    const _value = NumberHelper.parseInteger(
      ProjectSettingsMapper.get(this.data,
        SETTINGS.PNG.QUALITY),
    );

    ProjectSettingsMapper.set(
      this.data,
      SETTINGS.PNG.QUALITY,
      _value,
    );

    return this;
  }

  /**
   * @description ensures that refresh__master_images__period is of correct type
   * @returns {ProjectDataHelper}
   */
  ensureType_refresh__master_images__period() {
    const _value = NumberHelper.parseInteger(
      ProjectSettingsMapper.get(this.data,
        SETTINGS.REFRESH_MASTER_IMAGES__PERIOD),
      INTERNAL_SETTINGS_CONFIG[SETTINGS.REFRESH_MASTER_IMAGES__PERIOD].defaultValue,
    );

    ProjectSettingsMapper.set(
      this.data,
      SETTINGS.REFRESH_MASTER_IMAGES__PERIOD,
      _value,
    );

    return this;
  }

  /**
   * @description ensures correct field types
   * @returns {ProjectDataHelper}
   */
  ensureTypes() {
    return this.ensureType_cache__output_max_age()
      .ensureType_cache__output_s_max_age()
      .ensureType_jpeg__quality()
      .ensureType_origin_process__jpg_icc__if_big_go_srgb__trigger_size()
      .ensureType_png__quality()
      .ensureType_refresh__master_images__period();
  }

  /**
   * @returns {ProjectDataHelper}
   */
  cleanJunkProps() {
    if (this.data.data) {
      this.data.data = null;
    }

    if (this.data.isTransformedForAPI) {
      this.data.isTransformedForAPI = null;
    }

    if (this.data.projectDataJSONSchema) {
      this.data.projectDataJSONSchema = null;
    }

    if (SETTINGS.ALIASES !==
      ProjectSettingsMapper.generateSettingConfig(SETTINGS.ALIASES)
        .paths
        .join(".")) {
      delete this.data[SETTINGS.ALIASES];
    }

    return this;
  }

  transformForFrontend() {
    return this
      .cleanJunkProps()
      .ensureJSONSchema()
      .transformAliasesForFrontend()
      .convertAliasesHeadersToArray()
      .transformAllowedDomainsForFrontend()
      .transformRulesForFrontend()
      .transformPresetsForFrontend()
      .transformPresetParamsForFrontend()
      .transformCNAMESForFrontend()
      .transformRequestHeadersForFrontend()
      .ensureTypes()
      .data;
  }

  transformForAPI() {
    return this
      .ensureJSONSchema()
      .filterAliasesForAPI()
      .convertAliasesHeadersToObject()
      .filterAllowedDomainsForAPI()
      .transformAllowedDomainsForAPI()
      .transformRulesForAPI()
      .filterPresetsForAPI()
      .transformPresetsForAPI()
      .transformPresetParamsForAPI()
      .transformCNAMESForAPI()
      .transformRequestHeadersForAPI()
      .filterCNAMESForAPI()
      .ensureTypes()
      .data;
  }
}
