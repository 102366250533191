import { client } from "../../services/api/client";

const {
  REACT_APP_BASE_URL,
} = process.env;

/**
 * @param {string} token - Project slug
 * @returns {Promise<{data: {status: string, token: string, provider: string, stats_available: (0|1), hint: string}}>}
 */
export const getProviderRequest = token =>
  client.get(`/project/${token}/provider`);

export const getProjectDataRequest = (project_uuid) =>
  client.get(`${REACT_APP_BASE_URL}/project/${project_uuid}/data`);

export const saveProjectDataRequest = (project_uuid, data) =>
  client
    .put(`${REACT_APP_BASE_URL}/project/${project_uuid}/data`, { data });

/**
 * @param {String} project_uuid
 * @param {String} name
 * @param {String} desc
 * @returns {Promise}
 */
export const saveProjectDescriptionRequest = (project_uuid, name, desc) =>
  client
    .put(`${REACT_APP_BASE_URL}/project/${project_uuid}`,
      { project: { name, desc } });
