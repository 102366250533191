import React from 'react';
import styled from 'styled-components';
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

export const DEFAULT_LAYOUT_MAX_WIDTH = 1100;

export const LayoutHeaderToolbar = styled(Toolbar)
  .attrs({ maxWidth: false, component: Container })({
    justifyContent: 'space-between',
  });

export const LayoutHeaderContainer = styled(
  ({ background, ...rest }) => <AppBar position="relative" color="secondary" {...rest} />,
)
(({ background }) => ({
  flexShrink: 0,
  boxShadow: '0px 0px 5px 1px rgba(146, 146, 146, 0.3) !important',
  zIndex: 9,
  ...(background ? { background } : {}),
}));

export const LayoutBodyContainer = styled(
  ({ background, ...rest }) => <Box {...rest} />,
)(({ background }) => ({
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  zIndex: 1,
  background,
}));

export const LayoutContentContainer = styled(Box)`
  position: relative;
  flex-grow: 1;
  width: 100%;
  ${({ $scrollable = true }) => $scrollable && `
    overflow-y: auto; // for browsers that doesn't support "overlay" (mostly for FF)
    overflow-y: overlay; // for browsers that support "overlay" (like Chrome)
  `}
`;

export const LayoutContent = styled(
  ({ fullWidth, withoutPadding, maxWidth, minWidth, children, ...rest }) =>
    <Container children={children} {...rest} />)
(({
  theme,
  fullWidth,
  withoutPadding,
  maxWidth = DEFAULT_LAYOUT_MAX_WIDTH,
  minWidth = '1000px',
}) => ({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  maxWidth: fullWidth ? '100% !important' : maxWidth,
  minWidth,
  ...(withoutPadding ? { padding: '0px !important' } : {}),
}));

export const Logo = styled(Box)((props) => ({
  width: props.$width || 185,
  height: props.$height || 60,
  backgroundImage: props.$backgroundImage || `url(${props.$imageUrl || ''})`,
  backgroundSize: props.$backgroundSize || 'contain',
  backgroundPosition: props.$backgroundPosition || 'center',
  backgroundRepeat: props.$backgroundRepeat || 'no-repeat',
}));

export const SidebarList = React.forwardRef((props, ref) => (
  <List
    component="div"
    disablePadding
    {...props}
    ref={ref}
  />
));

export const SidebarListItemIcon = styled(ListItemIcon)({
  display: 'flex',
  justifyContent: 'center',
});

export const SidebarListItemText = styled(ListItemText)({
  '> *': {
    display: 'block',
    maxWidth: '100%',
    fontSize: 10,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    textAlign: 'center',
    whiteSpace: 'normal',
  },
});

export const SidebarListItem = styled(
  React.forwardRef((props, ref) => <ListItem {...props} ref={ref}/>))`
  color: ${props => props.theme.palette.secondary.main};
  display: flex;
  flex-direction: column;

  svg {
    color: ${props => props.theme.palette.secondary.main};
  }

  &:hover {
    background: lightgrey;
  }

  &.active {
    background: ${props => props.theme.palette.secondary.main};
    color: #FFF;

    & svg {
      color: #FFF;
    }
  }
`;
