import DeliveryIcon from "@material-ui/icons/Language";
import LogsIcon from "@material-ui/icons/ListAlt";
import ProminantIcon from "@material-ui/icons/Stars";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import DashboardHourlyIcon from "@material-ui/icons/AccessTimeOutlined";
import AnalyzeIcon from '@material-ui/icons/Timeline';
import SpeedIcon from '@material-ui/icons/Speed';
import OverviewIcon from '@material-ui/icons/Equalizer';
import UserAccessIcon from "@material-ui/icons/GroupAdd";
import InvalidationIcon from "@material-ui/icons/RefreshOutlined";
import ImageSettingsIcon from "@material-ui/icons/SettingsOutlined";
import RumAnalyticsIcon from '@material-ui/icons/Tv';
import StorageIcon from "@material-ui/icons/StorageOutlined";
import BillingIcon from "@material-ui/icons/AssignmentOutlined";
import PlanIcon from "@material-ui/icons/LibraryAddCheckOutlined";
import DollarIcon from '@material-ui/icons/AttachMoney';
import SecurityIcon from "@material-ui/icons/SecurityOutlined";
import HelpIcon from "@material-ui/icons/HelpOutlined";
import DocumentationIcon from "@material-ui/icons/MenuBookOutlined";
import GettingStartedIcon from "@material-ui/icons/SettingsPower";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import { Link } from "@material-ui/core";
import { ROLE_LEVELS, USER_ROLES } from "../../../../../../permissions.constants";
import { TAB_SLUGS as CONTACT_US_TAB_SLUGS } from '../../../../../../routes/contact-us/contact-us.constants';

const SIDEBAR_LIST_GROUP_IDS = {
  MANAGE: "manage",
  STATS_LOG: "stats-Log",
  ACCOUNT: "account",
  HELP_SUPPORT: "help-support",
};

const SIDEBAR_LIST_GROUP_OPTIONS = {
  [SIDEBAR_LIST_GROUP_IDS.MANAGE]: {
    id: SIDEBAR_LIST_GROUP_IDS.MANAGE,
    title: 'Manage',
    titleI18nKey: 'MANAGE',
    componentProps: {
      titleProps: { mt: 1 }
    }
  },
  [SIDEBAR_LIST_GROUP_IDS.STATS_LOG]: {
    id: SIDEBAR_LIST_GROUP_IDS.STATS_LOG,
    title: 'Stats & Log',
    titleI18nKey: 'STATS_LOG',
  },
  [SIDEBAR_LIST_GROUP_IDS.ACCOUNT]: {
    id: SIDEBAR_LIST_GROUP_IDS.ACCOUNT,
    title: 'Account',
    titleI18nKey: 'ACCOUNT',
  },
  [SIDEBAR_LIST_GROUP_IDS.HELP_SUPPORT]: {
    id: SIDEBAR_LIST_GROUP_IDS.HELP_SUPPORT,
    title: 'Help & Support',
    titleI18nKey: 'HELP_SUPPORT',
    componentProps: { id: 'sidebar-help-support-section' } // For quickstart tour
  },
};

const SIDEBAR_LIST_GROUP_ITEM_IDS = {
  // stats
  DASHBOARD_OVERVIEW: "DASHBOARD_OVERVIEW",
  DASHBOARD: "DASHBOARD",
  DASHBOARD_DAILY: "DASHBOARD_DAILY",
  DASHBOARD_DELIVERY: "DASHBOARD_DELIVERY",
  DASHBOARD_PROMINENTS: "DASHBOARD_PROMINENTS",
  DASHBOARD_ADVANCED_ANALYTICS: "DASHBOARD_ADVANCED_ANALYTICS",
  RUM: "RUM",
  LOGS: "LOGS",
  ANALYZE: "ANALYZE",
  // manage
  IMAGE_SETTINGS: 'IMAGE_SETTINGS',
  STORAGE: 'STORAGE',
  INVALIDATION: 'INVALIDATION',
  RUM_ANALYTICS: 'RUM_ANALYTICS',
  USER_ACCESS: 'USER_ACCESS',
  // account
  PLANS: 'PLANS',
  BILLING: 'BILLING',
  AFFILIATION: 'AFFILIATION',
  SECURITY: 'SECURITY',
  // help-support
  DOCUMENTATION: 'DOCUMENTATION',
  CONTACT: 'CONTACT',
  ONBOARDING: 'ONBOARDING',
  QUICKSTART: 'QUICKSTART',
};

const SIDEBAR_LIST_GROUP_ITEM_OPTIONS = {
  // stats
  [SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_OVERVIEW]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_OVERVIEW,
    title: 'Overview',
    titleI18nKey: 'DASHBOARD_OVERVIEW',
    icon: OverviewIcon,
    route: ({ ROUTES }) => ROUTES.STATS_OVERVIEW,
  },

  [SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD,
    title: 'Dashboard',
    titleI18nKey: 'DASHBOARD',
    icon: DashboardIcon,
    route: ({ ROUTES }) => ROUTES.STATS_DASHBOARD,
  },

  [SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_DAILY]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_DAILY,
    title: 'Daily stats',
    titleI18nKey: 'DASHBOARD_DAILY',
    icon: DashboardHourlyIcon,
    route: ({ ROUTES }) => ROUTES.STATS_DASHBOARD_DAILY,
  },

  [SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_DELIVERY]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_DELIVERY,
    title: 'Delivery',
    titleI18nKey: 'DASHBOARD_DELIVERY',
    icon: DeliveryIcon,
    route: ({ ROUTES }) => ROUTES.STATS_DASHBOARD_DELIVERY,
  },

  [SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_PROMINENTS]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_PROMINENTS,
    title: 'Prominents',
    titleI18nKey: 'DASHBOARD_PROMINENTS',
    icon: ProminantIcon,
    route: ({ ROUTES }) => ROUTES.STATS_DASHBOARD_PROMINENTS,
  },

  [SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_ADVANCED_ANALYTICS]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.DASHBOARD_ADVANCED_ANALYTICS,
    title: 'Advanced analytics',
    titleI18nKey: 'DASHBOARD_ADVANCED_ANALYTICS.MENU_ITEM.LABEL',
    icon: FindReplaceIcon,
    route: ({ ROUTES }) => ROUTES.STATS_DASHBOARD_ADVANCED_ANALYTICS,
  },

  [SIDEBAR_LIST_GROUP_ITEM_IDS.RUM]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.RUM,
    title: 'RUM',
    titleI18nKey: 'RUM',
    icon: SpeedIcon,
    route: ({ ROUTES }) => ROUTES.STATS_DASHBOARD_RUM,
  },

  [SIDEBAR_LIST_GROUP_ITEM_IDS.LOGS]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.LOGS,
    title: 'Logs',
    titleI18nKey: 'LOGS',
    icon: LogsIcon,
    route: ({ ROUTES }) => ROUTES.LOGS,
  },

  [SIDEBAR_LIST_GROUP_ITEM_IDS.ANALYZE]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.ANALYZE,
    title: 'Analyze',
    titleI18nKey: 'ANALYZE',
    icon: AnalyzeIcon,
    route: ({ ROUTES }) => ROUTES.ANALYZE,
  },

  // manage
  [SIDEBAR_LIST_GROUP_ITEM_IDS.IMAGE_SETTINGS]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.IMAGE_SETTINGS,
    title: 'Image Settings',
    titleI18nKey: 'IMAGE_SETTINGS',
    icon: ImageSettingsIcon,
    route: ({ ROUTES }) => ({ path: ROUTES.PROJECT_SETTINGS(""), exact: false }),
    componentProps: {
      className: 'image-settings-sidebar-item', // For quickstart tour
      onClick: ({ nextStep }) => nextStep(), // For quickstart tour
    }
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.STORAGE]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.STORAGE,
    title: 'Storage',
    titleI18nKey: 'STORAGE',
    icon: StorageIcon,
    route: ({ ROUTES }) => ROUTES.PROJECT_STORAGE,
    componentProps: {
      id: 'storage-sidebar-item' // For quickstart tour
    }
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.INVALIDATION]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.INVALIDATION,
    title: 'Invalidation',
    titleI18nKey: 'INVALIDATION',
    icon: InvalidationIcon,
    route: ({ ROUTES }) => ROUTES.PROJECT_INVALIDATION,
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.RUM_ANALYTICS]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.RUM_ANALYTICS,
    title: 'RUM Analytics',
    titleI18nKey: 'RUM_ANALYTICS',
    icon: RumAnalyticsIcon,
    route: ({ ROUTES }) => ROUTES.RUM_ANALYTICS,
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.USER_ACCESS]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.USER_ACCESS,
    title: 'User access',
    titleI18nKey: 'USER_ACCESS',
    icon: UserAccessIcon,
    route: ({ ROUTES }) => ROUTES.USER_ACCESS,
  },

  // account
  [SIDEBAR_LIST_GROUP_ITEM_IDS.PLANS]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.PLANS,
    title: 'Plans',
    titleI18nKey: 'PLANS',
    icon: PlanIcon,
    route: ({ ROUTES }) => ROUTES.PLANS,
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.BILLING]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.BILLING,
    title: 'Billing',
    titleI18nKey: 'BILLING',
    icon: BillingIcon,
    route: ({ ROUTES }) => ({
      pathForRedirect: ROUTES.ACCOUNT_BILLING(""),
      path: ROUTES.ACCOUNT_BILLING(),
      exact: false
    }),
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.AFFILIATION]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.AFFILIATION,
    title: 'Affiliation',
    titleI18nKey: 'AFFILIATION',
    icon: DollarIcon,
    route: ({ ROUTES }) => ROUTES.ACCOUNT_AFFILIATION,
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.SECURITY]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.SECURITY,
    title: 'Security',
    titleI18nKey: 'SECURITY',
    icon: SecurityIcon,
    route: ({ ROUTES }) => ({
      pathForRedirect: `${ROUTES.COMPANY_SETTINGS}#saml`,
      path: ROUTES.COMPANY_SETTINGS, // Used in findActiveGroupIdByPath to matchPath
      exact: false
    }),
  },
  // help-support
  [SIDEBAR_LIST_GROUP_ITEM_IDS.DOCUMENTATION]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.DOCUMENTATION,
    title: 'Documentation',
    titleI18nKey: 'DOCUMENTATION',
    icon: DocumentationIcon,
    componentProps: {
      component: Link,
      href: 'https://docs.cloudimage.io',
      target: '_blank',
      rel: 'noopener noreferrer',
      color: 'inherit',
      id: 'documentation-sidebar-item', // For quickstart tour
      exact: undefined
    }
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.CONTACT]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.CONTACT,
    title: 'Contact / Support',
    titleI18nKey: 'CONTACT',
    icon: HelpIcon,
    route: ({ ROUTES }) => ROUTES.CONTACT_US(CONTACT_US_TAB_SLUGS.KNOWLEDGE_BASE),
    componentProps: {
      id: 'contact-sidebar-item'
    }
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.ONBOARDING]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.ONBOARDING,
    title: 'Restart Onboarding',
    titleI18nKey: 'RESTART_ONBOARDING',
    icon: GettingStartedIcon,
    route: ({ ROUTES }) => ROUTES.ONBOARDING,
  },
  [SIDEBAR_LIST_GROUP_ITEM_IDS.QUICKSTART]: {
    id: SIDEBAR_LIST_GROUP_ITEM_IDS.QUICKSTART,
    title: 'Quickstart',
    titleI18nKey: 'QUICKSTART',
    icon: PlayCircleOutlineIcon,
    componentProps: {
      component: 'div',
      onClick: ({ resetAllTours }) => resetAllTours()
    }
  },
};

const FREQUENLY_USED_GROUP_ITEM_IDS = [
  SIDEBAR_LIST_GROUP_ITEM_IDS.IMAGE_SETTINGS,
  SIDEBAR_LIST_GROUP_ITEM_IDS.BILLING,
  SIDEBAR_LIST_GROUP_ITEM_IDS.RUM,
  SIDEBAR_LIST_GROUP_ITEM_IDS.PLANS,
  SIDEBAR_LIST_GROUP_ITEM_IDS.USER_ACCESS,
  SIDEBAR_LIST_GROUP_ITEM_IDS.INVALIDATION,
  SIDEBAR_LIST_GROUP_ITEM_IDS.LOGS,
  SIDEBAR_LIST_GROUP_ITEM_IDS.STATS_OVERVIEW,
  SIDEBAR_LIST_GROUP_ITEM_IDS.STATS_DASHBOARD,
  SIDEBAR_LIST_GROUP_ITEM_IDS.STATS_DASHBOARD_DAILY,
];

export {
  USER_ROLES as ROLES,
  ROLE_LEVELS,
  SIDEBAR_LIST_GROUP_IDS,
  SIDEBAR_LIST_GROUP_OPTIONS,
  SIDEBAR_LIST_GROUP_ITEM_IDS,
  SIDEBAR_LIST_GROUP_ITEM_OPTIONS,
  FREQUENLY_USED_GROUP_ITEM_IDS,
};
