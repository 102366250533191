import { createContext } from "react";

export const ImageSettingsContext = createContext();

export const WHITELIST_IMG_BEHAVIOUR_OPTIONS = {
  W_HTTP_406: "W_HTTP_406",
  W_DEFAULT_IMG: "W_DEFAULT_IMG",
};

export const MISSING_IMG_BEHAVIOUR_OPTIONS = {
  http_200_with_default_image: "http_200_with_default_image",
  http_404_with_default_image: "http_404_with_default_image",
  http_404_with_empty_image: "http_404_with_empty_image",

  default_200: "default_200",
  returns_200_1x1_gif: "returns_200_1x1_gif",
  returns_404: "returns_404",
  returns_empty_404: "returns_empty_404",
};

export const WEBP_ENABLED = {
  ALLOW: "ALLOW",
  NOT_ALLOW: "NOT_ALLOW",
};

export const WEBP_PNG_ENABLED = {
  ALLOW: "ALLOW",
  NOT_ALLOW: "NOT_ALLOW",
};

export const AVIF_ENABLED = {
  ALLOW: "ALLOW",
  NOT_ALLOW: "NOT_ALLOW",
};

export const WHITELIST_ULTRAFAST = {
  ON: "on",
  OFF: "off",
};

/**
 * @param {PROJECT_SETTING_CONFIG} config
 * @returns {PROJECT_SETTING_CONFIG}
 */
export const defineSettingConfig = (config) => ({
  paths: config.paths,
  defaultValue: config.defaultValue,
  root: config.root,
});

/**
 * @param {string} settingId -example: SETTINGS.CACHE.OUTPUT_MAX_AGE
 * @returns {string[]}
 */
const parseSettingPaths = (settingId) => {
  return settingId.split(".");
};

/**
 * keys without actual values stored in redux store are called virtual settings
 * @description -each key must be the path where we're storing the value.
 * example key: "cache.output_max_age"
 */
export const SETTINGS = {
  ALL: "ALL", //virtual setting (no actual value stored in redux store)
  CACHE: {
    OUTPUT_MAX_AGE: "cache.output_max_age",
    OUTPUT_S_MAX_AGE: "cache.output_s_max_age",
  },
  REFRESH_MASTER_IMAGES__METHOD: "refresh_master_images.method",
  REFRESH_MASTER_IMAGES__ENABLED: "refresh_master_images.enabled",
  REFRESH_MASTER_IMAGES__PERIOD: "refresh_master_images.period",
  JPEG: {
    QUALITY: "jpeg.quality",
    INTERLACE: "jpeg.interlace",
  },
  PNG: {
    PNG_LOSSY: "png.png_lossy",
    QUALITY: "png.quality",
  },
  GIF: {
    LOSSY_ANIMATED: "gif.lossy_animated",
  },
  ORIGIN_PROCESS: {
    JPG_ICC: {
      KEEP: "origin_process.jpg_icc.keep",
      IF_BIG_GO_SRGB: {
        ENABLED: "origin_process.jpg_icc.if_big_go_srgb.enabled",
        TRIGGER_SIZE: "origin_process.jpg_icc.if_big_go_srgb.trigger_size",
      },
    },
    KEEP_EXIF: "origin_process.keep_exif",
  },
  ORIGIN_PREFIX: {
    METHOD: "origin_prefix.method",
    URL: "origin_prefix.url",
  },
  ORIGIN_BEHAVIOUR: {
    REQUEST_HEADERS: "origin_behavior.request_headers",
  },
  WEBP: {
    CONVERT: "webp.convert",
    ENABLED: "webp.enabled",
    CONVERT_ANIMATED: "webp.convert_animated",
  },
  WEBP_PNG: {
    ENABLED: "webp_png.enabled",
  },
  AVIF: {
    ENABLED: "avif.enabled",
  },
  SVG: {
    DO_NOT_RESIZE: "svg.do_not_resize",
    COMPRESS: "svg.compress",
  },
  PROXY_EVERYTHING_THAT_IS_NOT_CLOUDIMAGE_PROCESSABLE_EXTENSION: "proxy_everything_that_is_not_cloudimage_processable_extension",
  CHOICES: {
    MISSING_IMG_BEHAVIOUR: "choices.missing_img_behavior",
    WHITELIST_IMG_BEHAVIOUR: "choices.whitelist_img_behavior",
    WHITELIST_ULTRAFAST: "choices.whitelist_ultrafast",
  },
  WATERMARK: "watermark",
  DEFAULT_IMAGE: "default_image",
  RULES: "rules",
  ADVANCED: {
    PROXY_CONNSTRING: "advanced.proxy_connstring",
  },
  PRESETS: "presets",
  ALLOWED_DOMAINS: "allowed_domains",
  CNAMES: "cnames",
  CNAMES_TLS_VALIDATION: "cnames_tls_validation",
  ALIASES: "aliases",
  STORAGE_PROVIDERS: "storage_providers",
  SIGNATURE: "signature",
  SEALING: "sealing",
};

/**
 * settings that don't have property representation
 * @type {(string)[]}
 */
export const VIRTUAL_SETTINGS = [
  SETTINGS.ALL,
  SETTINGS.WATERMARK,
  SETTINGS.DEFAULT_IMAGE,
];

export const INTERNAL_SETTINGS_CONFIG = {
  [SETTINGS.ALL]: defineSettingConfig({
    root: [],
  }),

  [SETTINGS.CACHE.OUTPUT_MAX_AGE]: defineSettingConfig(
    {
      paths: [
        "cache", "output_max_age",
      ],
      defaultValue: 86400,
    }),
  [SETTINGS.CACHE.OUTPUT_S_MAX_AGE]: defineSettingConfig(
    {
      paths: [
        "cache", "output_s_max_age",
      ],
      defaultValue: 31536000,
    }),

  [SETTINGS.REFRESH_MASTER_IMAGES__METHOD]: defineSettingConfig(
    {
      paths: [
        "refresh_master_images", "method",
      ],
      defaultValue: "HEAD_SOURCE",
    }),
  [SETTINGS.REFRESH_MASTER_IMAGES__ENABLED]: defineSettingConfig(
    {
      paths: [
        "refresh_master_images", "enabled",
      ],
      defaultValue: false,
    }),

  [SETTINGS.REFRESH_MASTER_IMAGES__PERIOD]: defineSettingConfig(
    {
      paths: [
        "refresh_master_images", "period",
      ],
      defaultValue: 2592000,
    }),

  [SETTINGS.JPEG.QUALITY]: defineSettingConfig({
    paths: [
      "jpeg", "quality",
    ],
    defaultValue: 85,
  }),
  [SETTINGS.JPEG.INTERLACE]: defineSettingConfig({
    paths: [
      "jpeg", "interlace",
    ],
    defaultValue: false,
  }),

  [SETTINGS.PNG.PNG_LOSSY]: defineSettingConfig({
    paths: [
      "png", "png_lossy",
    ],
    defaultValue: false,
  }),
  [SETTINGS.PNG.QUALITY]: defineSettingConfig({
    paths: [
      "png", "quality",
    ],
    defaultValue: 50,
  }),
  [SETTINGS.ORIGIN_PROCESS.KEEP_EXIF]: defineSettingConfig(
    {
      paths: [
        "origin_process", "keep_exif",
      ],
      defaultValue: false,
    }),
  [SETTINGS.ORIGIN_PROCESS.JPG_ICC.KEEP]: defineSettingConfig(
    {
      paths: [
        "origin_process", "jpg_icc", "keep",
      ],
      defaultValue: false,
    }),
  [SETTINGS.ORIGIN_PROCESS.JPG_ICC.IF_BIG_GO_SRGB.ENABLED]: defineSettingConfig(
    {
      paths: [
        "origin_process", "jpg_icc", 'if_big_go_srgb', "enabled",
      ],
      defaultValue: false,
    }),
  [SETTINGS.ORIGIN_PROCESS.JPG_ICC.IF_BIG_GO_SRGB.TRIGGER_SIZE]: defineSettingConfig(
    {
      paths: [
        "origin_process", "jpg_icc", 'if_big_go_srgb', "trigger_size",
      ],
      defaultValue: 0,
    }),

  [SETTINGS.ORIGIN_PREFIX.METHOD]: defineSettingConfig(
    {
      paths: [
        "origin_prefix", "method",
      ],
      defaultValue: "EVERYTIME",
    }),
  [SETTINGS.ORIGIN_PREFIX.URL]: defineSettingConfig({
    paths: [
      "origin_prefix", "url",
    ],
    defaultValue: "",
  }),

  [SETTINGS.ORIGIN_BEHAVIOUR.REQUEST_HEADERS]: defineSettingConfig({
    paths: parseSettingPaths(SETTINGS.ORIGIN_BEHAVIOUR.REQUEST_HEADERS),
    defaultValue: {},
  }),

  [SETTINGS.WEBP.CONVERT]: defineSettingConfig({
    paths: [
      "webp", "convert",
    ],
    defaultValue: true,
  }),

  [SETTINGS.WEBP.ENABLED]: defineSettingConfig({
    paths: [
      "webp", "enabled",
    ],
    defaultValue: "",
  }),

  [SETTINGS.WEBP_PNG.ENABLED]: defineSettingConfig({
    paths: [
      "webp_png", "enabled",
    ],
    defaultValue: "",
  }),

  [SETTINGS.WEBP.CONVERT_ANIMATED]: defineSettingConfig({
    paths: [
      "webp", "convert_animated",
    ],
    defaultValue: false,
  }),

  [SETTINGS.AVIF.ENABLED]: defineSettingConfig({
    paths: [
      "avif", "enabled",
    ],
    defaultValue: AVIF_ENABLED.NOT_ALLOW,
  }),

  [SETTINGS.SVG.DO_NOT_RESIZE]: defineSettingConfig({
    paths: [
      "svg", "do_not_resize",
    ],
    defaultValue: 1,
  }),

  [SETTINGS.SVG.COMPRESS]: defineSettingConfig({
    paths: [
      "svg", "compress",
    ],
    defaultValue: false,
  }),

  [SETTINGS.GIF.LOSSY_ANIMATED]: defineSettingConfig({
    paths: [
      "gif", "lossy_animated",
    ],
    defaultValue: false,
  }),

  [SETTINGS.PROXY_EVERYTHING_THAT_IS_NOT_CLOUDIMAGE_PROCESSABLE_EXTENSION]: defineSettingConfig(
    {
      paths: ["proxy_everything_that_is_not_cloudimage_processable_extension"],
      defaultValue: 0,
    }),

  [SETTINGS.CHOICES.MISSING_IMG_BEHAVIOUR]: defineSettingConfig(
    {
      paths: [
        "choices", "missing_img_behavior",
      ],
      defaultValue: MISSING_IMG_BEHAVIOUR_OPTIONS.returns_404,
    }),
  [SETTINGS.CHOICES.WHITELIST_IMG_BEHAVIOUR]: defineSettingConfig(
    {
      paths: [
        "choices", "whitelist_img_behavior",
      ],
      defaultValue: WHITELIST_IMG_BEHAVIOUR_OPTIONS.W_HTTP_406,
    }),

  [SETTINGS.CHOICES.WHITELIST_ULTRAFAST]: defineSettingConfig(
    {
      paths: [
        "choices", "whitelist_ultrafast",
      ],
      defaultValue: WHITELIST_ULTRAFAST.OFF,
    }),

  [SETTINGS.WATERMARK]: defineSettingConfig({
    paths: ["watermark"],
  }),

  [SETTINGS.DEFAULT_IMAGE]: defineSettingConfig({
    paths: ["default_image"],
  }),

  [SETTINGS.RULES]: defineSettingConfig({
    paths: ["rules"],
    defaultValue: [],
  }),

  [SETTINGS.ADVANCED.PROXY_CONNSTRING]: defineSettingConfig(
    {
      paths: [
        "advanced", "proxy_connstring",
      ],
      defaultValue: "",
    }),

  [SETTINGS.SEALING]: defineSettingConfig({
    paths: ['sealing'],
    defaultValue: {},
  }),

  [SETTINGS.SIGNATURE]: defineSettingConfig({
    paths: ['signature'],
    defaultValue: {},
  }),

  [SETTINGS.PRESETS]: defineSettingConfig({
    paths: ["presets"],
    defaultValue: [],
  }),

  [SETTINGS.ALLOWED_DOMAINS]: defineSettingConfig(
    {
      paths: ["allowed_domains"],
      defaultValue: [],
    }),

  [SETTINGS.CNAMES]: defineSettingConfig(
    {
      paths: ["cnames"],
      defaultValue: [],
    }),

  [SETTINGS.CNAMES_TLS_VALIDATION]: defineSettingConfig(
    {
      paths: ["cnames_tls_validation"],
      defaultValue: {},
    }),

  [SETTINGS.ALIASES]: defineSettingConfig(
    {
      paths: ["aliases"],
      defaultValue: [],
    }),

  [SETTINGS.STORAGE_PROVIDERS]: defineSettingConfig(
    {
      paths: ["storage_providers"],
      defaultValue: {
        aws_s3: null,
        own: null,
        azure_blob: null,
        google_cloud: null,
      },
    }),
};

export const SETTINGS_BY_SECTION = {
  WATERMARK: [SETTINGS.WATERMARK],
  RULES: [SETTINGS.RULES],
  SECURITY: [SETTINGS.ADVANCED.PROXY_CONNSTRING, SETTINGS.SEALING, SETTINGS.SIGNATURE],
  PRESETS: [SETTINGS.PRESETS],
  WHITELISTED_DOMAINS: [
    SETTINGS.ALLOWED_DOMAINS,
    SETTINGS.CHOICES.WHITELIST_IMG_BEHAVIOUR,
    SETTINGS.CHOICES.WHITELIST_ULTRAFAST,
  ],
  ORIGIN_PREFIX: [
    SETTINGS.ORIGIN_PREFIX.METHOD,
    SETTINGS.ORIGIN_PREFIX.URL,
  ],
  ORIGIN_BEHAVIOUR: [
    SETTINGS.ORIGIN_BEHAVIOUR.REQUEST_HEADERS,
  ],
  MISSING_IMG_BEHAVIOUR: [
    SETTINGS.CHOICES.MISSING_IMG_BEHAVIOUR,
  ],
  DEFAULT_IMAGE: [
    SETTINGS.DEFAULT_IMAGE,
  ],
  CNAMES: [
    SETTINGS.CNAMES,
    SETTINGS.CNAMES_TLS_VALIDATION,
  ],
  ALIASES: [
    SETTINGS.ALIASES,
  ],
  COMPRESSION: [
    SETTINGS.PROXY_EVERYTHING_THAT_IS_NOT_CLOUDIMAGE_PROCESSABLE_EXTENSION,
    SETTINGS.SVG.DO_NOT_RESIZE,
    SETTINGS.SVG.COMPRESS,
    SETTINGS.PNG.QUALITY,
    SETTINGS.PNG.PNG_LOSSY,
    SETTINGS.JPEG.QUALITY,
    SETTINGS.JPEG.INTERLACE,
    SETTINGS.WEBP.CONVERT,
    SETTINGS.WEBP.ENABLED,
    SETTINGS.WEBP_PNG.ENABLED,
    SETTINGS.WEBP.CONVERT_ANIMATED,
    SETTINGS.AVIF.ENABLED,
    SETTINGS.GIF.LOSSY_ANIMATED,
    SETTINGS.ORIGIN_PROCESS.KEEP_EXIF,
    SETTINGS.ORIGIN_PROCESS.JPG_ICC.KEEP,
    SETTINGS.ORIGIN_PROCESS.JPG_ICC.IF_BIG_GO_SRGB.TRIGGER_SIZE,
    SETTINGS.ORIGIN_PROCESS.JPG_ICC.IF_BIG_GO_SRGB.ENABLED,
  ],
  CACHING: [
    SETTINGS.CACHE.OUTPUT_MAX_AGE,
    SETTINGS.CACHE.OUTPUT_S_MAX_AGE,
    SETTINGS.REFRESH_MASTER_IMAGES__ENABLED,
    SETTINGS.REFRESH_MASTER_IMAGES__PERIOD,
    SETTINGS.REFRESH_MASTER_IMAGES__METHOD,
  ],
};
