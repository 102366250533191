import produce from "immer";
import _get from "lodash.get";
import _set from "lodash.set";

class ObjectHelper {
  /**
   * @param {object} object
   * @param {string[] | string} path
   * @param {any} defaultValue
   * @returns {*} requested value
   */
  static get(object, path, defaultValue) {
    return _get(object, path, defaultValue);
  }

  /**
   * @param {object} object
   * @param {string[] | string} path
   * @param {any} value
   * @returns {object} modified root object
   */
  static set(object, path, value) {
    _set(object, path, value);
    return object;
  }

  /**
   * @description creates new object and preserves unchanged references
   * @description modifier(draftState) { draftState.color = "red"; } - make changes on this object
   * @param {!T} baseState
   * @param {!function(draftState:!T)} modifier
   * @return {T}
   * @template T
   */
  static modify(baseState, modifier) {
    return produce(baseState, modifier);
  }
}

export {
  ObjectHelper,
};
