import {
  IconButton, Paper, MenuItem as MenuItemMui, Dialog,
  ListItemIcon as ListItemIconMui, Popper as PopperMui,
} from '@material-ui/core';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../assets/cross-icon.svg';


const QuickstartBlockContainer = styled.div({
  position: 'absolute',
  zIndex: 1,
  bottom: 24,
  right: 32,
  left: 'auto',
  transform: 'none',
});

const QuickstartBlockPaper = styled(Paper)(({ theme }) => ({
  padding: '16px 0',
  transition: 'box-shadow 1s',
  '&.animation': {
    //boxShadow: theme.shadows[10]
    //boxShadow: '0px 11px 15px -7px #fde7d7, 0px 24px 38px 3px rgb(251 192 149), 0px 9px 46px 8px rgb(246 142 56)'
    boxShadow: '0px 6px 6px -3px rgb(251 192 149), 0px 10px 14px 1px rgb(251 192 149), 0px 4px 18px 3px rgb(251 192 149)'
  }
}));

const TourTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const CloseButton = styled(IconButton).attrs({
  size: 'small',
  children: <CrossIcon />
})({
  position: 'absolute',
  top: '12px',
  right: '12px',
});

const MenuItem = styled(MenuItemMui)`
  ${props => props.theme.sfx.textStyles.text.md}
  padding: 8px 24px;
  color: ${props => props.theme.palette.text.primary};

  .MuiTypography-colorTextSecondary {
    color: ${props => props.theme.palette.text.secondary};
  }
`;

const ListItemIcon = styled(ListItemIconMui)`
  min-width: 0;
  margin-right: 12px;
`;

const StepPaper = styled(Paper)`
  max-width: 500px;
  min-width: 300px;
  padding: 24px;
  box-shadow: 0px 0px 3px #cdd3f1;
`;

const PopperArrow = styled.span({
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
});

const Popper = styled(PopperMui)(({ theme }) => ({
  zIndex: 1,
  margin: 16,
  [`&[x-placement*="bottom"] ${PopperArrow}`]: {
    top: 0,
    left: 0,
    marginTop: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
    },
  },
  [`&[x-placement*="top"] ${PopperArrow}`]: {
    bottom: 0,
    left: 0,
    marginBottom: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      borderWidth: '1em 1em 0 1em',
      borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
    },
  },
  [`&[x-placement*="right"] ${PopperArrow}`]: {
    left: 0,
    marginLeft: '-0.9em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 1em 1em 0',
      borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
    },
  },
  [`&[x-placement*="left"] ${PopperArrow}`]: {
    right: 0,
    marginRight: '-0.9em',
    height: '3em',
    width: '1em',
    '&::before': {
      borderWidth: '1em 0 1em 1em',
      borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
}));

const CongratsModalDialog = styled(Dialog)`
  .MuiDialogTitle-root {
    padding: 24px 24px 16px;
  }
  .MuiDialogContent-root {
    padding: 8px 24px 16px;
  }
  .MuiDialogActions-root {
    padding: 8px 24px 24px;
  }

  .MuiDialogTitle-root,
  .MuiDialogTitle-root .MuiTypography-root {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: ${props => props.theme.sfx.palette.text.primary};
  }

  .MuiDialogContent-root,
  .MuiDialogContent-root .MuiTypography-root {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.sfx.palette.text.primary};
  }
`;

const PADDING_X = 100;
const PADDING_Y = 50;
export const ModalBody = styled.div({
  width: `calc(100vw - ${PADDING_X * 2}px)`,
  height: `calc(100vh - ${PADDING_Y * 2}px)`,
  background: 'rgb(0, 0, 0)',
  outline: 'none',
});

const Styled = {
  QuickstartBlockContainer,
  QuickstartBlockPaper,
  TourTooltip,
  CloseButton,
  MenuItem,
  ListItemIcon,
  StepPaper,
  PopperArrow,
  Popper,
  CongratsModalDialog,
  ModalBody,
};

export default Styled;
