import { SETTINGS_BY_SECTION } from "./settings.config";

export const TAB_SLUGS = {
  BASIC: "",
  DOMAINS: "domains",
  COMPRESSION: "compression",
  CACHING: "caching",
  PRESETS: "presets",
  RULES: "rules",
  WATERMARK: "watermark",
  SECURITY: "security",
};

export const TAB_NAMES = {
  BASIC: "Basic",
  DOMAINS: "Domains",
  COMPRESSION: "Compression",
  CACHING: "Caching",
  PRESETS: "Presets",
  RULES: "Rules",
  WATERMARK: "Watermark",
  SECURITY: "Security",
};

/**
 * @typedef PROJECT_SETTINGS_TAB
 * @type  {Object}
 * @property {string} slug
 * @property {string} name
 * @property {string[]} settings
 * @property {string[]} validatedSettings
 */

/***
 * @param slug
 * @param name
 * @param settings
 * @param validatedSettings
 * @return {PROJECT_SETTINGS_TAB}
 */
const createTab = (slug, name, settings = [], validatedSettings = []) => {
  if (typeof slug !== 'string') {
    throw new Error('invalid slug');
  }

  if (typeof name !== 'string') {
    throw new Error('invalid name');
  }

  if (!Array.isArray(settings)) {
    throw new Error('settings is not array');
  }

  if (!Array.isArray(validatedSettings)) {
    throw new Error('validatedSettings is not array');
  }

  return { slug, name, settings, validatedSettings };
};

// using validatedSettings prop, we specify which settings are validated and on which tab are they. It's important to do this to enable tab redirection on validation error
export const TABS = [
  createTab(
    TAB_SLUGS.BASIC,
    TAB_NAMES.BASIC,
    [
      ...SETTINGS_BY_SECTION.DEFAULT_IMAGE,
      ...SETTINGS_BY_SECTION.MISSING_IMG_BEHAVIOUR,
    ],
  ),
  createTab(
    TAB_SLUGS.DOMAINS,
    TAB_NAMES.DOMAINS,
    [
      ...SETTINGS_BY_SECTION.CNAMES,
      ...SETTINGS_BY_SECTION.ALIASES,
      ...SETTINGS_BY_SECTION.WHITELISTED_DOMAINS,
      ...SETTINGS_BY_SECTION.ORIGIN_PREFIX,
      ...SETTINGS_BY_SECTION.ORIGIN_BEHAVIOUR,
    ],
    [
      "['cnames']",
    ]),
  createTab(
    TAB_SLUGS.COMPRESSION,
    TAB_NAMES.COMPRESSION,
    SETTINGS_BY_SECTION.COMPRESSION,
    [
      "['jpeg']['quality']",
      "['png']['quality']",
      "['origin_process']['jpg_icc']['if_big_go_srgb']['trigger_size']",
    ]),
  createTab(
    TAB_SLUGS.CACHING,
    TAB_NAMES.CACHING,
    SETTINGS_BY_SECTION.CACHING,
    [
      "['cache']['output_max_age']",
      "['cache']['output_s_max_age']",
    ]),
  createTab(
    TAB_SLUGS.PRESETS,
    TAB_NAMES.PRESETS,
    SETTINGS_BY_SECTION.PRESETS,
  ),
  createTab(
    TAB_SLUGS.RULES,
    TAB_NAMES.RULES,
    SETTINGS_BY_SECTION.RULES,
  ),
  createTab(
    TAB_SLUGS.WATERMARK,
    TAB_NAMES.WATERMARK,
    SETTINGS_BY_SECTION.WATERMARK,
  ),
  createTab(
    TAB_SLUGS.SECURITY,
    TAB_NAMES.SECURITY,
    SETTINGS_BY_SECTION.SECURITY,
  ),
];

export const validationErrorRegex = /(\['\w+'])/g;
