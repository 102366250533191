import Alert from '@material-ui/lab/Alert';
import { Translate } from "@shared-admin-kit/translation";
import React from 'react';
import styled from 'styled-components';

const DesktopOnlyAlert = styled(
  props => (
    <Alert icon={false} severity="warning" {...props}>
      <Translate i18nKey="NOT_MOBILE_OPTIMIZED" defaultValue="Not optimized for mobile, please browse on a Desktop"/>
    </Alert>
  ),
)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '100vw',
  height: '100vh',
  border: 'none',
  fontSize: 20,
});

const QAModeLabel = styled.span`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1000;
  background: ${props => props.theme.sfx.palette.extra.pink};
  padding: 1px 8px;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 0px 12px 0px #ffffff;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
`;

const Styled = {
  DesktopOnlyAlert,
  QAModeLabel,
};

export default Styled;
