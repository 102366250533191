import React, { useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useCompany, useAuth } from "@shared-admin-kit/core";
import { useTranslation } from "@shared-admin-kit/translation";
import Loader from "../../components/loader";
import MultipleMode from './components/multiple-mode';
import SingleMode from './components/single-mode';
import { Container } from './company-autocomplete.styled';

function CompanyAutocomplete({
  renderInputProps = {},
  enableSingleMode = true,
  width,
  ...rest
}) {
  const { companies: fullCompaniesList } = useAuth();
  const { activeCompany, isCompanyLoading } = useCompany();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isEditMode, setEditMode] = useState(false);
  const companies = useMemo(() =>
    (fullCompaniesList || []).map(company => ({ ...company, name: company.name || t('EMPTY_NAME', '-- empty name --') })),
    [fullCompaniesList, t]
  );

  if (isCompanyLoading) {
    return <Loader/>;
  }

  if (!activeCompany?.company) {
    return null;
  }

  return (
    <Container width={width}>
      {isEditMode || (enableSingleMode && companies.length < 2)
        ? (
          <SingleMode
            enqueueSnackbar={enqueueSnackbar}
            renderInputProps={renderInputProps}
            isEditMode={isEditMode}
            changeEditMode={setEditMode}
          />
        )

        : (
          <MultipleMode
            {...rest}
            enqueueSnackbar={enqueueSnackbar}
            renderInputProps={renderInputProps}
            companies={companies}
            onEditMode={() => setEditMode(true)}
          />
        )
      }
    </Container>
  );
}

export default CompanyAutocomplete;
