import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { core } from "../core";
import sidebarReducer from "./sidebar.reducer";
import affiliationReducer from "./account-affiliation.reducer";
import projectReducer from '../routes/project/project.reducer';
import couponsReducer from './coupons.reducer';

export default function createReducer(history) {
  return combineReducers({
    ...(core.getAllReducers() || {}),
    router: connectRouter(history),
    sidebar: sidebarReducer,
    project: projectReducer,
    coupons: couponsReducer,
    affiliation: affiliationReducer,
  });
};
