import { useState, useEffect, useMemo } from 'react';
import ReacturMask from '@reactour/mask';
import { useRect } from '@reactour/utils';

function getScrollParent(node) {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
}

function Mask({ anchorEl, onClose }) {
  const [updater, setUpdater] = useState([])
  const ref = useMemo(() => ({ current: anchorEl }), [anchorEl]);
  const sizes = useRect(ref, updater)

  useEffect(() => {
    const scrollParent = getScrollParent(anchorEl) // window
    const handleScroll = () => {
      setUpdater([]);
    }

    if (anchorEl && scrollParent) scrollParent.addEventListener('scroll', handleScroll);

    return () => {
      if (anchorEl && scrollParent) scrollParent.removeEventListener('scroll', handleScroll);
    }
  }, [anchorEl])

  return (
    <ReacturMask styles={{ maskWrapper: (styles) => ({ ...styles, zIndex: 10 }) }} sizes={sizes} onClick={() => onClose?.()} padding={0} />
  );
}

export default Mask;
