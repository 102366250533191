import { useAuth, useCompany } from "@shared-admin-kit/core";
import { Translate } from "@shared-admin-kit/translation";
import { isRoute } from "@shared-admin-kit/utils";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { ROUTES } from '../../../../../../routes';
import RedirectLink from "../../../../../redirect-link";
import { UserDropdownMenuItem, StyledDropdown } from './user-dropdown.styled';

export default function UserDropdown(props) {
  const { logout } = useAuth();
  const { activeCompanyUuid } = useCompany();
  const dispatch = useDispatch();

  const handleLogout = () => {
    const redirectLogin = process.env.REACT_APP_AUTH_PUBLIC_URL;

    logout();

    if (redirectLogin && isRoute(redirectLogin)) {
      dispatch(push(redirectLogin));
    } else {
      window.location.href = redirectLogin || '/';
    }
  }

  return (
    <StyledDropdown
      id="user-dropdown"
      menuItems={[
        // hasPermissions(PERMS.VIEW_ACCOUNT_BILLING) && (
        //   <RedirectLink component={UserDropdownMenuItem} to={ROUTES.ACCOUNT_BILLING}><Translate i18nKey="BILLING" defaultValue="Billing" /></RedirectLink>
        // ),
        // hasPermissions(PERMS.ADMIN_VIEW_ACCOUNT_PAYMENT_CARDS) && (
        //   <RedirectLink component={UserDropdownMenuItem} to={ROUTES.ACCOUNT_PAYMENT_CARDS}><Translate i18nKey="CARDS" defaultValue="Cards" /></RedirectLink>
        // ),
        // hasPermissions(PERMS.VIEW_COMPANY_SETTINGS) && (
        //   <RedirectLink component={UserDropdownMenuItem} to={ROUTES.COMPANY_SETTINGS}><Translate i18nKey="COMPANY_SETTINGS" defaultValue="Company Settings" /></RedirectLink>
        // ),
        // <RedirectLink component={UserDropdownMenuItem} to={ROUTES.CONTACT_US("")}><Translate i18nKey="CONTACT" defaultValue="Contact" /></RedirectLink>,
        <RedirectLink component={UserDropdownMenuItem} to={ROUTES.USER_SETTINGS}><Translate i18nKey="SETTINGS" defaultValue="Settings"/></RedirectLink>,
        activeCompanyUuid && <RedirectLink component={UserDropdownMenuItem} to={ROUTES.COMPANY_SETTINGS}><Translate i18nKey="COMPANY_SETTINGS" defaultValue="Company Settings"/></RedirectLink>,
        <RedirectLink component={UserDropdownMenuItem} to={ROUTES.TERMS_OF_USE}><Translate i18nKey="TERMS_OF_USE" defaultValue="Terms of Use"/></RedirectLink>,
        <UserDropdownMenuItem onClick={handleLogout}><Translate i18nKey="LOGOUT" defaultValue="Logout"/></UserDropdownMenuItem>,
      ].filter(Boolean)}
    >
      {props.children}
    </StyledDropdown>
  );
}
