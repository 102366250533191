import { applyMiddleware, createStore as _createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { core } from "./core";
import { history } from "./core";
import createReducer from './reducers';

const { REACT_APP_TEST_MODE } = process.env;

const composeEnhancers = composeWithDevTools({
  trace: REACT_APP_TEST_MODE === "true",
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

function createStore(coreReducers = {}, history) {
  return _createStore(
    createReducer(history, coreReducers),
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
      ),
    ),
  );
}

const store = createStore(core.getAllReducers(), history);

export { store };
