export const NAMESPACE = 'affiliation';
export const SET_IS_LOADING = `${NAMESPACE}/SET_IS_LOADING`;
export const SET_ERROR = `${NAMESPACE}/SET_ERROR`;
export const AFFILIATION_LINK_CREATED = `${NAMESPACE}/AFFILIATION_LINK_CREATED`;
export const AFFILIATION_STATS_FETCHED = `${NAMESPACE}/AFFILIATION_STATS_FETCHED`;
export const AFFILIATE_PAYMENT_EMAIL_SET = `${NAMESPACE}/AFFILIATE_PAYMENT_EMAIL_SET`;

const initialState = {
  affiliationStats: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AFFILIATION_STATS_FETCHED:
      return { ...state, affiliationStats: action.data };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}