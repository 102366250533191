import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { T } from "@shared-admin-kit/translation";
import { ListItemIcon, ListItemText } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import { ROUTES } from "../../../../../../routes";
// import {
//   activateGroupAction, deactivateGroupAction, setActiveGroupIdsAction,
// } from "../../../../../../actions/sidebar.actions";
// import SidebarListAccountGroup from "./components/sidebar-list-account-group";
// import { DEFAULT_ACTIVE_GROUP_IDS } from "../../../../../../reducers/sidebar.reducer";
// import SidebarListHelpSupportGroup
//   from "./components/sidebar-list-help-support-group";
// import SidebarListManageGroup from "./components/sidebar-list-manage-group";
// import SidebarListStatsLogGroup
//   from "./components/sidebar-list-stats-log-group";
import { StyledList, StyledListItem } from "./sidebar-list.styled";
// import { findActiveGroupIdByPath } from "./sidebar-list.utils";
import { useSidebarMenu } from "../../../../../../hooks";
import SidebarListGroup from "./components/sidebar-list-group";

function SidebarList() {
  const {
    GROUPS
    // role, isMultiTenantCompany, , canRead, canWrite, isCompanyAdmin
  } = useSidebarMenu();
  // const groupProps = { canRead, canWrite, isCompanyAdmin, role, isMultiTenantCompany };

  const renderListGroupItem = ({ icon: Icon, title, titleI18nKey, route, componentProps }) => (
    <StyledListItem
      component={NavLink}
      exact={route?.exact ?? true}
      {...componentProps}
      key={title}
      to={route?.pathForRedirect || route?.path || route}
    >
      <ListItemIcon><Icon /></ListItemIcon>
      <ListItemText><T i18nKey={titleI18nKey} defaultValue={title} /></ListItemText>
    </StyledListItem>
  )

  const renderListGroup = ({ id, active, onActivate, items, title, titleI18nKey, componentProps }) => (
    <SidebarListGroup
      {...componentProps}
      key={id}
      active={active}
      onActivate={onActivate}
      title={<T i18nKey={titleI18nKey} defaultValue={title} />}
    >
      {items.map(renderListGroupItem)}
    </SidebarListGroup>
  )

  return (
    <StyledList>
      <StyledListItem component={NavLink} to={ROUTES.HOME} exact style={{ paddingLeft: 24 }}>
        <ListItemIcon><HomeIcon/></ListItemIcon>
        <ListItemText><T i18nKey="HOME" defaultValue="Home" /></ListItemText>
      </StyledListItem>

      {GROUPS.map(renderListGroup)}

      {/* {canRead && (
        <SidebarListStatsLogGroup
          active={activeGroupIds.includes(GROUPS.STATS_LOG.id)}
          onActivate={handleToggle(GROUPS.STATS_LOG.id)}
          {...groupProps}
        />
      )}

      {canWrite && (
        <SidebarListManageGroup
          active={activeGroupIds.includes(GROUPS.MANAGE.id)}
          onActivate={handleToggle(GROUPS.MANAGE.id)}
          {...groupProps}
        />
      )}

      {isCompanyAdmin && (
        <SidebarListAccountGroup
          active={activeGroupIds.includes(GROUPS.ACCOUNT.id)}
          onActivate={handleToggle(GROUPS.ACCOUNT.id)}
          {...groupProps}
        />
      )}

      <SidebarListHelpSupportGroup
        active={activeGroupIds.includes(GROUPS.HELP_SUPPORT.id)}
        onActivate={handleToggle(GROUPS.HELP_SUPPORT.id)}
        {...groupProps}
      /> */}
    </StyledList>
  );
}

export default SidebarList;
