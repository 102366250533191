import Box from '@material-ui/core/Box';
import { useCompany } from "@shared-admin-kit/core";
import { T, Translate } from "@shared-admin-kit/translation";
import React from 'react';
import Loader from "../../../../../loader";
import SfxTypography from '../../../../../sfx-typography';
import SidebarLabel from '../../../../../sidebar-label';
import TokenDropdown from "./components/token-dropdown";
import { Container } from './token.styled';

function Token() {
  const { activeProject, isCompanyLoading, isProjectLoading } = useCompany();

  if (isCompanyLoading || isProjectLoading) {
    return (
      <Container>
        <Loader
          container
          size={18}
        />
      </Container>
    );
  }

  return (
    <Container>
      <Box id="token-container">
        <Box
          display="flex"
          pl={3}
          pr={2}
          alignItems="center"
        >
          <SidebarLabel pt={0} pl={0} align="left">
            <Translate i18nKey="TOKEN" defaultValue="Token"/>
          </SidebarLabel>
        </Box>

        <Box display="flex" pl={3} pr={2} alignItems="flex-end">
          {activeProject
            ? <TokenDropdown/>
            : (
              <SfxTypography variant="text.md" color="extra.red">
                <T i18nKey="TOKEN_IS_MISSING" defaultValue="Token is missing!" />
              </SfxTypography>
            )}
        </Box>
      </Box>
    </Container>
  );
}

export default Token;
