import { SIDEBAR_LIST_GROUP_IDS } from "../components/layout/components/sidebar/components/sidebar-list/sidebar-list.constants";
import { SIDEBAR_SET_ACTIVE_GROUP_IDS } from "../actions/sidebar.actions";

export const DEFAULT_ACTIVE_GROUP_IDS = [SIDEBAR_LIST_GROUP_IDS.MANAGE, SIDEBAR_LIST_GROUP_IDS.STATS_LOG];

export const initialState = {
  activeGroupIds: [...DEFAULT_ACTIVE_GROUP_IDS],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIDEBAR_SET_ACTIVE_GROUP_IDS:
      const payload = action.activeGroupIds.filter(Boolean)

      if (JSON.stringify(state.activeGroupIds.sort()) === JSON.stringify(payload.sort())) {
        return state;
      }

      return {
        ...state,
        activeGroupIds: payload,
      };
    default:
      return state;
  }
};
