import { Route as RouterRoute, Redirect } from 'react-router-dom';
import { RouteWrapper, useAuth } from '@shared-admin-kit/core';
import { usePermissions } from '@shared-admin-kit/permissions';
import { LayoutContent } from "../default";
import Forbidden from "../../../../routes/forbidden";
import Loader from '../../../loader';


function Route({
  auth, component: Component, extraProps, render, containerMaxWidth, layoutContentStyle, withLayoutWrapper, perms, noPermsRedirect, ...rest
}) {
  const { isUserAuthentication, isUserLoading } = useAuth();
  const { hasPermissions } = usePermissions();

  const renderComponent = (matchProps) => {
    const props = { ...matchProps, ...(extraProps || {}) };

    if (isUserAuthentication || isUserLoading) {
      return <Loader container />
    }

    if (perms && !hasPermissions(perms)) {
      if (noPermsRedirect) {
        return <Redirect to={noPermsRedirect} />;
      }
      return <Forbidden />
    }

    if (typeof render === 'function') {
      return render(props);
    }

    if (Component) {
      return <Component {...props} />;
    }

    return null;
  };

  const renderContent = (matchProps) => {
    const content = renderComponent(matchProps)

    if (withLayoutWrapper) {
      return (
        <LayoutContent
          maxWidth={containerMaxWidth}
          {...(layoutContentStyle ? { style: layoutContentStyle } : {})}
        >
          {content}
        </LayoutContent>
      )
    }

    return content;
  }

  return (
    <RouterRoute
      {...rest}
      render={(matchProps) => (
        <RouteWrapper>
          {renderContent(matchProps)}
        </RouteWrapper>
      )}
    />
  );
}

function WrappedRoute(props) {
  return <Route {...props} withLayoutWrapper />
}

// Old implementation
// function WrappedRoute({
//   containerMaxWidth,
//   layoutContentStyle,
//   ...rest
// }) {
//   return (
//     <RouterRoute
//       {...rest}
//       render={content => (
//         <LayoutContent
//           maxWidth={containerMaxWidth} {...(layoutContentStyle
//           ? { style: layoutContentStyle }
//           : {})}>
//           <Suspense
//             fallback={<Loader
//               containerProps={{
//                 style: {
//                   position: 'absolute',
//                   top: '50%',
//                   left: 0,
//                   right: 0,
//                   marginLeft: 'auto',
//                   marginRight: 'auto',
//                   width: '100px',
//                 },
//               }}
//               container
//             />}
//           >
//             {content}
//           </Suspense>
//         </LayoutContent>
//       )}
//     />
//   );
// }

export default WrappedRoute;

export {
  Route,
  WrappedRoute,
}
