import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from './styled-alert.styled';

/**
 * @param {Object} props
 * @param {boolean} props.closable
 * @param {string} props.name - Used to save in localStorage. Required for closable=TRUE or you can use own handler.
 */
function StyledAlert(props) {
  const {closable, name, onClose, ...rest} = props;
  const [open, setOpen] = useState(name ? (localStorage.getItem(name) || '1') === '1' : true);

  function handleClose() {
    setOpen(false);

    if (name) {
      localStorage.setItem(name, 0);
    }

    if (typeof onClose === 'function') {
      onClose();
    }
  }

  if (!closable) {
    return <Alert {...rest} />;
  }

  return (
    <Collapse in={open}>
      <Alert
        {...rest}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      />
    </Collapse>
  );
}

StyledAlert.defaultProps = {
  name: '',
  closable: false,
  onClose: () => {},
};

StyledAlert.propTypes = {
  name: PropTypes.string,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StyledAlert;
