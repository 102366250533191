import React from 'react';
import TextField from "@material-ui/core/TextField";
import styled from 'styled-components';

export const StyledTextField = styled(
  ({ color, maxWidth, align, ...rest }) => <TextField {...rest} />
)(({ color, maxWidth, align }) => ({
  ...(color ? {
    '.MuiInputBase-root:not(.Mui-disabled)': { color },
    '.MuiIconButton-root:not(.Mui-disabled)': { color },
    '.MuiSvgIcon-root:not(.MuiSvgIcon-colorDisabled)': { color },
    '.MuiInput-underline:before': { borderColor: color },
    '.MuiInput-underline:after': { borderColor: color },
    '.MuiInput-underline:hover:not(.Mui-disabled):before': { borderColor: color },
  } : {}),

  ...(maxWidth ? { maxWidth } : {}),

  ...(align ? {
    '.MuiInputBase-input': { textAlign: align },
  } : {}),
}));

export default StyledTextField;