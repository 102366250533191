class CookieHelper {
  
  /**
   * @param name
   * @return {string|undefined}
   */
  static getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\\.$?*|{}()[\]/+^])/g, '\\$1') +
      "=([^;]*)",
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  };
}

export {
  CookieHelper,
};