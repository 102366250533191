 import Styled from './sfx-typography.styles';


function SfxTypography(props) {
  return (
    <Styled.Typography {...props} />
  );
}

export default SfxTypography;
