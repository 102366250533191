export default class FunctionHelper {
  /**
   * @param {string} timeoutId
   * @returns {string}
   * @private
   */
  static _createTimeoutName = (timeoutId) => `timeout_${timeoutId}`;

  /**
   * @param {string} timeoutId
   * @param {Function} func
   * @param {number} [delay]
   */
  static debounceExecution(timeoutId, func, delay = 500) {
    const timeoutName = this._createTimeoutName(timeoutId);

    clearTimeout(window[timeoutName]);
    window[timeoutName] = setTimeout(func, delay);
  }

  /**
   * @param {string} timeoutId
   * @param {Function} func
   * @param {number} limit
   */
  static throttleExecution(timeoutId, func, limit) {
    const timeoutName = this._createTimeoutName(timeoutId);

    if (!window[timeoutName]) {
      func();
      window[timeoutName] = setTimeout(function () {
        window[timeoutName] = undefined;
      }, limit);
    }
  }
}
