import { useCompany } from "@shared-admin-kit/core";
import React, { Suspense } from "react";
import {
  LayoutBodyContainer,
  LayoutContentContainer,
} from "./components/default";
import Loader from "../loader";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Messages from "./components/messages";

export default function Layout({ children }) {
  const { isProjectLoading } = useCompany();

  function renderContent() {
    if (isProjectLoading) {
      return <Loader container containerProps={{ style: { marginTop: 16 } }}/>;
    }

    return (
      <>
        <Sidebar/>

        <LayoutContentContainer
          $scrollable={false}
          display="flex"
          flexDirection="column"
          minWidth={0}
        >
          <Messages/>{/* Block for messages fixed on top */}

          <LayoutContentContainer $scrollable>
            <Suspense fallback={<Loader container/>}>
              {children}
            </Suspense>
          </LayoutContentContainer>
        </LayoutContentContainer>
      </>
    );
  }

  return (
    <>
      <Header/>

      <LayoutBodyContainer>
        {renderContent()}
      </LayoutBodyContainer>
    </>
  );
}
